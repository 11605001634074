import { useTranslate } from "@refinedev/core";
import { Button, DatePicker, Tag } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import dayjs from "dayjs";
import i18n from "../../i18n";
import { CustomColumnType } from "../common/TellieTable";

export const usePaymentsColumns = (setFilters): CustomColumnType<any>[] => {
  const translate = useTranslate();
  const handleDateRangeFilter = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;

      setFilters?.(
        [
          {
            field: "date",
            operator: "gte",
            value: startDate.startOf("day").toISOString(),
          },
          {
            field: "date",
            operator: "lte",
            value: endDate.endOf("day").toISOString(),
          },
        ],
        "merge"
      );
    } else {
      setFilters?.(
        [
          {
            field: "date",
            operator: "gte",
            value: undefined,
          },
          {
            field: "date",
            operator: "lte",
            value: undefined,
          },
        ],
        "merge"
      );
    }
  };

  const defaultColumns: CustomColumnType<any>[] = [
    {
      title: translate("payments.amount"),
      dataIndex: "amount",
      key: "amount",
      default: true,
      render: (amount) =>
        `${Number(amount.toFixed(2)).toLocaleString(i18n.language ?? "de", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })} €`, // Format and place the currency symbol after the amount
      sorter: (a, b) => a.amount - b.amount,
    },

    {
      title: translate("payments.invoice"),
      dataIndex: "invoice",
      default: true,
      render: (value) =>
        value.invoice_number ? <Tag>{value.invoice_number}</Tag> : <></>,
    },
    {
      title: translate("payments.date"),
      dataIndex: "date",
      key: "date",
      width: "15%",
      filterDropdown: ({ confirm, clearFilters }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <DatePicker.RangePicker
            onChange={(dates) => handleDateRangeFilter(dates)}
            format="DD/MM/YYYY"
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <div style={{ marginTop: 8, textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginRight: 8 }}
            >
              {translate("buttons.filter", "Filter")}
            </Button>
            <Button
              onClick={() => {
                handleDateRangeFilter(null);
                clearFilters?.();
                confirm();
              }}
              size="small"
            >
              {translate("buttons.reset", "Reset")}
            </Button>
          </div>
        </div>
      ),

      render: (value) =>
        value
          ? dayjs(value).format(translate("page.settings.date_format"))
          : null,
      sorter: (a, b) => {
        const dateA = dayjs(a.date, "YYYY-MM-DD").toDate();
        const dateB = dayjs(b.date, "YYYY-MM-DD").toDate();
        return dateB.getTime() - dateA.getTime();
      },
      default: true,
    },
  ];
  // Default columns

  // Return merged array of default columns and custom columns.
  return [...defaultColumns];
};

import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";

import {
  CanAccess,
  useCreateMany,
  useDelete,
  useList,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@refinedev/core";

import {
  DeleteOutlined,
  DownOutlined,
  PauseCircleOutlined,
  PlayCircleFilled,
  PlayCircleOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Image,
  Input,
  InputNumber,
  MenuProps,
  Modal,
  Popconfirm,
  Radio,
  Rate,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Tag,
  TimePicker,
  UploadFile,
  theme, Flex,
} from "antd";
import { UploadProps } from "antd/lib";
import { useParams } from "react-router-dom";
import { Text } from "../../../components";
import ProductInfoFields from "../../../components/shop/product-items/ProductInfoFields";
import VariantInfoTable from "../../../components/shop/product-items/VariantInfoTable";
import { useShippingTableSelect } from "../../../components/shop/useShippingTableSelect";
import { useUser } from "../../../contexts/ContextProvider";
import { usePipelineContext } from "../../../contexts/PipelineProvider";
import useFile from "../../../hooks/useFile";
import { IField } from "../../../interfaces/custom-fields.interface";
import { IMedia } from "../../../interfaces/general.interface";
import { Category } from "../../../interfaces/shop.interface";
import { supabaseClient } from "../../../utility";
import { handleCategoriesUpdate } from "../../../utility/handleCategoriesUpdate";
import { updateCategoryPathnames } from "../../../utility/shop";
import SubscriptionProductsList from "../subscription-products/list";
import ProductIssuesList from "./product-issues/list";
import HelpDrawer from "../../../components/common/HelpDrawer";

const ProductItemShow: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeForm, setActiveForm] = useState<
    | "title"
    | "description"
    | "categories"
    | "images"
    | "netto_price"
    | "sales_tax"
    | "billing_interval"
    | "configured_variants"
    | "type"
  >();

  const [fileList, setFileList] = React.useState<UploadFile[] | undefined>([]);
  const [items, setItems] = React.useState<any>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [priceObject, setPriceObject] = useState();
  const [imagesLoading, setImagesLoading] = React.useState(true);
  const [typeChange, setTypeChange] = React.useState(false);
  const { currentTeam } = useUser();
  const [schema, setSchemaField] = useState<IField[]>([]);
  const [isVariantInfoModalOpen, setIsVariantInfoModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("product_data");
  const [openHelp, setOpenHelp] = useState<string | boolean>(false);

  const { mutateAsync: updateSingle } = useUpdate();

  const primaryColor = theme?.useToken()?.token?.colorPrimary;
  const { list } = useNavigation();
  const { mutate: deleteMutation } = useDelete();
  const { mutateAsync: updateAsync } = useUpdate();
  const translate = useTranslate();
  const params = useParams();
  const [modalField, setModalField] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const { formProps, form, saveButtonProps, query } = useForm({
    resource: "product_items",
    action: "edit",
    id: params?.id,
    redirect: false,
    meta: { select: "*, product_categories(*)" },
  });
  const { data: settingQuery } = useList({
    resource: "settings",
    filters: [
      {
        field: "account",
        value: currentTeam?.account_id,
        operator: "eq",
      },
    ],
  });
  const settingsData = settingQuery?.data[0];
  useEffect(() => {
    if (
      settingQuery &&
      settingsData?.product_custom_fields_json &&
      settingsData?.product_custom_fields_json.length > 0
    ) {
      setSchemaField(settingsData?.product_custom_fields_json);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingQuery]);
  const custom_fields = query?.data?.data?.custom_fields || {};

  const { canAccess } = usePipelineContext();
  const { selectProps, query: shippingTableQuery } = useShippingTableSelect();

  const { getFileDataURL, removeFile } = useFile();

  const { data, isError, isLoading } = query ?? {};
  const itemsCategory = useMemo(
    () => data?.data.product_categories.map((item) => item.id),
    [data?.data]
  );
  const menuItems: MenuProps["items"] = [];
  const customFieldKeys = Object.keys(custom_fields || {});
  (schema || []).forEach((fieldDef: any) => {
    const fieldName = fieldDef?.field?.name;
    if (fieldName && !customFieldKeys?.includes(fieldName)) {
      menuItems?.push({
        label: `${fieldDef?.field.label || fieldName}`,
        key: `add_custom_${fieldName}`,
      });
    }
  });
  const { data: categoryList } = useList<Category>({
    resource: "product_categories",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const categoriesWithPath = useMemo(
    () =>
      updateCategoryPathnames(categoryList?.data ?? []).filter((item) =>
        itemsCategory?.includes(item.id)
      ),
    [categoryList?.data, itemsCategory]
  );
  const { mutateAsync: createMany } = useCreateMany();

  const closeModal = () => {
    list("product_items");
  };

  const getAllImages = React.useCallback(async () => {
    try {
      setImagesLoading(true); // Set the loading state here before starting

      if (!data?.data.images || data?.data.images.length === 0) {
        setFileList([]);
        return;
      }

      // Fetch all images
      const responses = await Promise.allSettled(
        data?.data.images.map(async (image: IMedia) => {
          try {
            const fetchedImg = await getFileDataURL(image, true);
            if (!fetchedImg) {
              throw new Error("Error reading file");
            }
            if (fetchedImg && typeof fetchedImg === "object") {
              return {
                uid: image?.id ?? "",
                lastModified: fetchedImg.file.lastModified,
                lastModifiedDate: fetchedImg.file.lastModified,
                name: fetchedImg.file.name,
                size: fetchedImg.file.size,
                type: fetchedImg.blob.type,
                percent: 100,
                originFileObj: {
                  uid: image?.id ?? "",
                },
                status: "done",
                thumbUrl: fetchedImg.base64,
              };
            }
          } catch (error: any) {
            console.error(
              `Error fetching image with id ${image?.id}: ${error.message}`
            );
            return null;
          }
        })
      );

      const successfulResponses = responses
        .filter(
          (result): result is PromiseFulfilledResult<any> =>
            result.status === "fulfilled" && result.value !== null
        )
        .map((result) => result.value);

      setFileList(successfulResponses);
    } catch (error: any) {
      console.error("Error in getAllImages:", error.message);
    } finally {
      setImagesLoading(false); // Update loading state in finally to ensure it's run after completion
    }
  }, [data?.data.images, getFileDataURL]);

  useEffect(() => {
    if (data?.data.images) {
      getAllImages();
    } else {
      setFileList([]);
      setImagesLoading(false);
    }
  }, [data?.data.images, getAllImages]);

  useEffect(() => {
    // Setze den anfänglichen gross_price, wenn Daten geladen sind
    if (
      !isLoading &&
      data?.data &&
      data?.data?.netto_price !== undefined &&
      data?.data?.sales_tax !== undefined
    ) {
      const gross_price =
        data.data.netto_price * (1 + (data.data.sales_tax || 0) / 100);
      form.setFieldsValue({ gross_price });
    }
  }, [isLoading, data, form]);

  const [isSubscriptionEnabled, setIsSubscriptionEnabled] = useState(false);
  const [isIssueBasedEnabled, setIsIssueBasedEnabled] = useState(false);
  const handleMenuClick = (e: any) => {
    const { key } = e;

    if (key?.startsWith("add_custom_")) {
      const customFieldName = key?.replace("add_custom_", "");
      setModalField(customFieldName);
      setModalVisible(true);
    }
  };
  const [isChangeSubscriptionModalOpen, setIsChangeSubscriptionModalOpen] =
    useState(false);

  if (isError) {
    closeModal();
    return null;
  }
  if (isLoading) {
    return null;
  }
  const {
    id,
    title = "",
    description,
    images,
    created_at,
    type = "",
    configured_variants,
    netto_price,
    sales_tax,
    billing_suspended,
    price = netto_price * (1 + (sales_tax || 0) / 100),
    price_object,
  } = (data?.data as {
    id?: string;
    title?: string;
    description?: string;
    images?: any[];
    created_at?: string;
    billing_suspended?: any;
    type?: string;
    configured_variants?: any[];
    netto_price?: any;
    sales_tax?: any;
    price?: any;
    price_object?: any;
  }) ?? {};

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter((file) => file.status !== "error"));
  const onRemove = async (file) => {
    await removeFile([`public/shop/product_items/${file.name}`]).then(
      async () => {
        if (params.id) {
          fileList?.filter((file) => {
            return !images?.find((item) => item.id == file.uid);
          });
          const restImages = images?.filter((item) => item.id != file.uid);
          updateAsync({
            resource: "product_items",
            id: params?.id,
            values: { images: restImages ?? [] },
          });
        }
      }
    );
    const index = fileList?.indexOf(file);
    const newFileList = fileList?.slice();
    newFileList?.splice(index ?? 0, 1);

    setFileList(newFileList);
  };
  const dummyRequest = async (options: any) => {
    const { onSuccess } = options;

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = file.thumbUrl;
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleFileSave = async () => {
    const newFiles = fileList?.filter((file) => {
      return !images?.find((item) => item.id == file.uid);
    });
    const fileArr = newFiles
      ? await Promise.all(
          newFiles.map(async (item: any) => {
            const fileName = `${Date.now()}-${item.name}`;
            const { data: fileData, error } = await supabaseClient.storage
              .from("files")
              .upload(
                `teams/${currentTeam?.account_id}/shop/product_items/${params?.id}/${fileName}`,
                item.originFileObj,
                {
                  //test
                }
              );
            if (error) {
              throw error;
            } else {
              return {
                ...fileData,
                name: fileName,
              };
            }
          })
        )
      : null;

    if (fileArr && fileArr[0] != undefined) {
      return [...(images ?? []), ...(fileArr ?? [])];
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      handleCategoriesUpdate(
        values,
        categoriesWithPath,
        params,
        currentTeam,
        createMany
      );
      const images = await handleFileSave();
      const result = await formProps.onFinish?.({
        title: values?.title,
        description: values?.description,
        netto_price: values?.netto_price,
        sales_tax: values?.sales_tax,
        images: images ?? [],
        configured_variants: items,
        price_object: priceObject,
        account: currentTeam?.account_id,
        shipping_table: values?.shipping_table ? values?.shipping_table : null,
        weight: values?.weight,
        ean: values?.ean,
      });
      if (result) {
        // closeModal(); // Navigate to the product items list
      }
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };
  const props: UploadProps = {
    onChange: handleChange,
    multiple: true,
    customRequest: dummyRequest,
    onRemove: onRemove,
    listType: "picture-card",
    accept: "image/png, image/jpeg, image/jpg, image/webp",
    maxCount: 6,
    onPreview: handlePreview,
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const showChangeSubscriptionModal = () => {
    setIsChangeSubscriptionModalOpen(true);
  };

  const handleChangeSubscriptionModalCancel = () => {
    setIsChangeSubscriptionModalOpen(false);
  };

  const handleChangeSubscriptionModalOk = async () => {
    if (isSubscriptionEnabled && !isIssueBasedEnabled) {
      await updateAsync({
        resource: "product_items",
        id: params?.id,
        values: { type: "subscription" },
      });
    }

    if (isIssueBasedEnabled) {
      await updateAsync({
        resource: "product_items",
        id: params?.id,
        values: { type: "issue" },
      });
    }

    setIsChangeSubscriptionModalOpen(false);
  };

  const dropdownItems: MenuProps["items"] = [
    {
      key: "4",
      label: translate(billing_suspended ? "product_items.resume_billing" : "product_items.pause_billing"),
      icon: billing_suspended ? <PlayCircleOutlined /> : <PauseCircleOutlined />,
      onClick: async () =>
        await updateSingle({
          resource: "product_items",
          id,
          values: { billing_suspended: !billing_suspended },
        }),
    },
    { type: "divider" },
    {
      key: 'help',
      icon: <QuestionCircleOutlined />,
      label: translate('buttons.help'),
      onClick: () => setOpenHelp("products/edit")
    },
    { type: "divider" },
    {
      key: "5",
      icon: <DeleteOutlined style={{ color: "red" }} />,
      label: (
        <Popconfirm
          title={translate("product_items.buttons.delete.title")}
          description={translate("product_items.buttons.delete_single")}
          onConfirm={() => {
            if (id) {
              deleteMutation(
                {
                  id,
                  resource: "product_items",
                },
                {
                  onSuccess: () => closeModal(),
                }
              );
            }
          }}
          okText={translate("buttons.yes", "Yes")}
          cancelText={translate("buttons.no", "No")}
        >
          <span
            style={{ color: "red" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {translate("product_items.buttons.delete.title")}
          </span>
        </Popconfirm>
      ),
    },
  ];
  const renderCustomFieldFormItem = (fieldDef: any) => {
    const fieldName = fieldDef.field.name;
    const fieldLabel = fieldDef.field.label;
    const fieldRequired = fieldDef.field.required;
    const requiredMessage = fieldDef.field.required_message;
    switch (fieldDef.type) {
      case "input":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Input placeholder={fieldLabel} />
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Input.TextArea
              placeholder={fieldLabel}
              rows={fieldDef.field.rows || 4}
            />
          </Form.Item>
        );
      case "datepicker":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <DatePicker format={translate("global.date_format")} />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Select
              mode={fieldDef.field.multiple ? "multiple" : undefined}
              options={fieldDef.field.options.map((option: string) => ({
                label: option,
                value: option,
              }))}
            />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Checkbox.Group
              options={fieldDef.field.options.map((option: string) => ({
                label: option,
                value: option,
              }))}
            />
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Radio.Group
              options={fieldDef.field.options.map((option: string) => ({
                label: option,
                value: option,
              }))}
            />
          </Form.Item>
        );
      case "rating":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Rate
              tooltips={fieldDef.field.tooltips}
              allowHalf={fieldDef.field.allowHalf}
              allowClear={fieldDef.field.allowClear}
            />
          </Form.Item>
        );
      case "timepicker":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <TimePicker />
          </Form.Item>
        );
      case "currency":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <InputNumber
              precision={fieldDef.field.decimal_places}
              addonAfter={
                fieldDef.field.show_symbol
                  ? fieldDef.field.currency_symbol
                  : fieldDef.field.currency_code
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        );

      default:
        return null;
    }
  };
  const renderModalContent = () => {
    if (modalField) {
      const fieldDef = (schema || []).find(
        (field: any) => field.field?.name === modalField
      );
      if (fieldDef) {
        return (
          <Form
            onFinish={async (values) => {
              const newCustomFields = { ...custom_fields, ...values };
              await updateSingle({
                resource: "product_items",
                id: id,
                values: { custom_fields: newCustomFields },
              });
              setModalVisible(false);
              setModalField(null);
              setActiveForm(undefined);
              query?.refetch?.();
            }}
            layout="vertical"
          >
            {renderCustomFieldFormItem(fieldDef)}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {translate("buttons.save")}
              </Button>
            </Form.Item>
          </Form>
        );
      }
    }
    return null;
  };

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <Modal
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            setModalField(null);
          }}
          centered
          footer={null}
          destroyOnClose={true}
        >
          {modalField && renderModalContent()}
        </Modal>
        <Modal
          title={translate("product_items.subscriptions.title")}
          open={isChangeSubscriptionModalOpen}
          onOk={handleChangeSubscriptionModalOk}
          onCancel={handleChangeSubscriptionModalCancel}
        >
          <Space direction={"vertical"} size={"large"}>
            <Text>{translate("product_items.subscriptions.description")}</Text>

            <Space>
              <Switch
                checked={isSubscriptionEnabled}
                onChange={(checked) => setIsSubscriptionEnabled(checked)}
              />
              <Text>{translate("product_items.subscriptions.title")}</Text>
            </Space>

            <Text>
              {translate("product_items.subscriptions.issue.description")}
            </Text>

            <Space>
              <Switch
                checked={isIssueBasedEnabled}
                onChange={(checked) => setIsIssueBasedEnabled(checked)}
              />
              <Text>
                {translate("product_items.subscriptions.issue.title")}
              </Text>
            </Space>
          </Space>
        </Modal>
        {/* {id && (
          <VariantInfoModal
            visible={isVariantInfoModalOpen}
            onCancel={() => {
              setIsVariantInfoModalOpen(false);
              query?.refetch?.();
            }}
            productId={id}
            currentTeam={currentTeam}
            shippingTableProps={selectProps}
            shippingTableQuery={shippingTableQuery}
            mainProductData={data?.data}
            refetchData={() => query?.refetch?.()}
          />
        )} */}
        <Drawer
          open
          push={false}
          onClose={() => closeModal()}
          className="entityDrawer"
          title={
            <Space>
              {title}{" "}
              {billing_suspended && <Tag color="yellow">{translate("product_items.paused_billing")}</Tag>}
            </Space>
          }
          extra={
            <Dropdown menu={{ items: dropdownItems }}>
              <Button
                type={"default"}
                onClick={(e) => e.preventDefault()}
              >
                <Space>
                  {translate("product_items.options")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          }
        >
          <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
            <Row>
              <Col span={24}>
                <Tabs
                  defaultActiveKey={activeTab}
                  onChange={(key) => setActiveTab(key)}
                  items={[
                    {
                      key: "product_data",
                      label: translate(
                        "product_items.product_data",
                        "Produkt"
                      ),
                    },
                    // {
                    //   key: "media",
                    //   label: translate("product_items.media", "Medien"),
                    // },


                    // ...(type === "subscription" || type === "issue"
                    //   ? [
                          {
                            key: "subscriptions",
                            label: translate(
                              "product_items.subscriptions.title",
                              "Subscriptions"
                            ),
                          },
                      //   ]
                      // : []),


                    ...(type === "issue"
                      ? [
                          {
                            key: "issues",
                            label: translate(
                              "product_items.product_issues.title",
                              "Ausgaben"
                            ),
                          },
                        ]
                      : []),
/*                    {
                      key: "delivery",
                      label: translate(
                        "product_items.delivery",
                        "Lieferbarkeit"
                      ),
                    },*/
                  ]}
                />
                {/* <VariantInfoModal
                      visible={true}
                      onCancel={() => {
                        setIsVariantInfoModalOpen(false);
                        query?.refetch?.();
                      }}
                      productId={id}
                      currentTeam={currentTeam}
                      shippingTableProps={selectProps}
                      shippingTableQuery={shippingTableQuery}
                      mainProductData={data?.data}
                      refetchData={() => query?.refetch?.()}
                    /> */}
                {activeTab == "product_data" && (
                  <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                      ...formProps.initialValues,
                      type: type === "subscription" ? false : true,
                    }}
                  >
                    <Row align={"middle"} gutter={20}>
                      <Col
                        flex={
                          configured_variants && configured_variants.length > 0
                            ? 1
                            : undefined
                        }
                        span={
                          configured_variants && configured_variants.length > 0
                            ? undefined
                            : 24
                        }
                      >
                        <ProductInfoFields
                          form={form}
                          onPricesChange={(value: any) => setPriceObject(value)}
                          initialPrices={price_object}
                          pricePerItem={netto_price}
                          shippingTableProps={selectProps}
                          shippingTableLoading={shippingTableQuery.isLoading}
                          intialCategories={data?.data?.product_categories}
                          disabled={
                            configured_variants &&
                            configured_variants.length > 0
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col>
                        <Button
                          {...saveButtonProps}
                          type="primary"
                          style={{ marginTop: 6 }}
                        >
                          {translate("buttons.save")}
                        </Button>
                      </Col>
                    </Row>
                    {id &&
                      configured_variants &&
                      configured_variants.length > 0 && (
                        <VariantInfoTable
                          productId={id}
                          currentTeam={currentTeam}
                          shippingTableProps={selectProps}
                          shippingTableQuery={shippingTableQuery}
                          mainProductData={data?.data}
                          refetchData={() => query?.refetch?.()}
                        />
                      )}
                  </Form>
                )}
                {/* {activeTab == "media" && (
                  <Form.Item
                    // name={"images"}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    label={translate("product_items.fields.upload_image")}
                  >
                    {imagesLoading ? (
                      <Spin />
                    ) : (
                      <Upload {...props} fileList={fileList}>
                        {fileList && fileList.length < 6 && (
                          <PlusCircleFilled
                            style={{
                              fontSize: "25px",
                              color: primaryColor,
                            }}
                          />
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                )} */}

                {activeTab == "subscriptions" && (
                  <>
                    {(type === "subscription" || type === "issue") ? (
                      <SubscriptionProductsList />
                    ) : (
                      <Flex gap={16} vertical>
                        <Text>
                          {translate(
                            "product_items.subscriptions.intro"
                          )}
                        </Text>
                        <Space>
                          <Button
                            type="primary"
                            onClick={() => {
                              showChangeSubscriptionModal();
                            }}
                          >
                            {translate(
                              "product_items.buttons.change_to_subscription"
                            )}
                          </Button>

                          <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={() => setOpenHelp("products/subscriptions")}
                          >
                            {translate("buttons.help")}
                          </Button>
                        </Space>
                      </Flex>
                    )}
                  </>
                )}


                {activeTab == "issues" && <ProductIssuesList />}
                {/* {canAccess?.personal_modules?.shop?.variants &&
                  activeTab == "issues" && (
                    <Form
                      {...formProps}
                      layout="vertical"
                      onFinish={handleSubmit}
                      initialValues={{
                        ...formProps.initialValues,
                        type: type === "subscription" ? false : true,
                      }}
                    >
                      <Form.Item
                        name={"configured_variants"}
                        label={translate(
                          "product_items.fields.configured_variants"
                        )}
                      >
                        <VariantTree
                          currentTeam={currentTeam}
                          setItems={(val) => setItems(val)}
                          initialSelectedItems={configured_variants}
                        />
                      </Form.Item>
                      <Button {...saveButtonProps} type="primary">
                        {translate("buttons.save")}
                      </Button>
                    </Form>
                  )} */}
              </Col>
              {/* <Col span={18}>
                {custom_fields && schema && (
                  <CustomFormBuilderPage
                    mode="pureView"
                    fields={schema}
                    data={custom_fields}
                    productId={id}
                    setFields={() => null}
                    setActiveForm={(e) => setActiveForm(e)}
                    activeForm={activeForm}
                  />
                )}
              </Col> */}
              {/* custom fields */}
              {/* <Col>
                <Dropdown
                  menu={{ items: menuItems, onClick: handleMenuClick }}
                  trigger={["click"]}
                >
                  <Button
                    onClick={(e) => e.preventDefault()}
                    style={{
                      fontSize: "0.80rem",
                      fontWeight: 500,
                      paddingTop: 0,
                    }}
                    type="link"
                  >
                    <Space>
                      <PlusOutlined />
                      {translate("contacts.add_property")}
                    </Space>
                  </Button>
                </Dropdown>
              </Col> */}
            </Row>
          </Space>
          {children}
        </Drawer>
        <div>
          {previewImage && (
            <Image
              wrapperStyle={{ display: "none" }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}
        </div>
      </>
      <HelpDrawer
        open={openHelp}
        onClose={() => setOpenHelp(false)}
        module={openHelp}
      />
    </CanAccess>
  );
};
export default ProductItemShow;

import { useState } from "react";
import { supabaseClient } from "../../utility";
import { Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "@refinedev/core";

export default function AuthMFA() {
  const translate = useTranslate();
  const [verifyCode, setVerifyCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const onSubmitClicked = () => {
    setError("");
    (async () => {
      const factors = await supabaseClient.auth.mfa.listFactors();
      if (factors.error) {
        throw factors.error;
      }

      const totpFactor = factors.data.totp[0];

      if (!totpFactor) {
        throw new Error("No TOTP factors found!");
      }
      const factorId = totpFactor.id;

      const challenge = await supabaseClient.auth.mfa.challenge({ factorId });
      if (challenge.error) {
        setError(challenge.error.message);
        throw challenge.error;
      }

      const challengeId = challenge.data.id;

      const verify = await supabaseClient.auth.mfa.verify({
        factorId,
        challengeId,
        code: verifyCode,
      });
      if (verify.error) {
        setError(verify.error.message);
        throw verify.error;
      }
      navigate("/");
    })();
  };
  const onLogoutClicked = async () => {
    await supabaseClient.auth.signOut();
    navigate("/login");
  };

  return (
    <div
      style={{
        padding: 24,
        minWidth: 500,
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#fff",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
        borderRadius: 8,
      }}
    >
      {error && <div className="error">{error}</div>}

      <h2>{translate("account.2fa.title")}</h2>
      <p>{translate("account.2fa.enter_digit_login")}</p>

      <Input
        placeholder="123-456"
        style={{ width: 120, marginRight: 8 }}
        onChange={(e) => setVerifyCode(e.target.value.trim())}
      />
      <Button type="primary" onClick={onSubmitClicked} disabled={!verifyCode}>
        {translate("actions.verify")}
      </Button>
      <Button style={{ marginLeft: 8 }} onClick={onLogoutClicked}>
        {translate("actions.cancel_logout")}
      </Button>
    </div>
  );
}

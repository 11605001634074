export function getContactFormattedName(contact: any): string {
  if (!contact) return "";

  if (contact.type === "person") {
    const { salutation, firstname, lastname, email } = contact;

    if (!firstname && !lastname) {
      return email;
    }

    let name = "";

    if (salutation) {
      name += salutation;
    }

    if (firstname) {
      name += (name ? " " : "") + firstname;
    }

    if (lastname) {
      name += (name ? " " : "") + lastname;
    }

    return name;
  }

  if (contact.type === "company") {
    return contact.company_name || contact.email;
  }

  return contact.email || "";
}

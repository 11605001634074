import { useTranslate } from "@refinedev/core";
import { Row, Space, Tag, Typography } from "antd";

const ContactAddress = ({ contactAddress }) => {
  const translate = useTranslate();

  if (!contactAddress) return null;

  const renderAddressDetails = () => {
    switch (contactAddress?.type) {
      case "PO_Box_Address":
        return <Row>{contactAddress?.street}</Row>;

      case "Packing_Station":
        return (
          <>
            {contactAddress?.address_supplement && (
              <Row>
                {translate("contacts.post_number")}:{" "}
                {contactAddress?.address_supplement}
              </Row>
            )}
            <Row>
              {translate("contacts.packstation")}: {contactAddress?.street}
            </Row>
          </>
        );

      default:
        return (
          <>
            {contactAddress?.address_supplement && (
              <Row>{contactAddress?.address_supplement}</Row>
            )}
            <Row>{contactAddress?.street}</Row>
          </>
        );
    }
  };

  return (
    <Space size="middle" align="start">
      <Typography.Text>
        {renderAddressDetails()}
        <Row>
          {contactAddress?.zip} {contactAddress?.city}
        </Row>
        {contactAddress?.country &&
          contactAddress?.country.toUpperCase() !== "DE" && (
            <Row>
              {translate(`countries.${contactAddress?.country?.toUpperCase()}`)}
            </Row>
          )}
      </Typography.Text>
      {contactAddress?.label && <Tag>{contactAddress?.label}</Tag>}
    </Space>
  );
};

export default ContactAddress;

import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";
import PdfViewer from "../common/PdfViewer";
import DocumentResendButton from "./DocumentResendButton";

const { Text } = Typography;

interface FileDisplayProps {
  invoice: any;
  label?: string;
  /**
   * fileType: "invoice" to display the original invoice,
   * "dunning" to display the dunning PDF (with "_mahnung" suffix).
   */
  fileType?: "invoice" | "dunning";
}

const FileDisplay: React.FC<FileDisplayProps> = ({
  invoice,
  label,
  fileType = "invoice",
}) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const { currentTeam } = useUser();

  useEffect(() => {
    const fetchFileUrl = async () => {
      const suffix = fileType === "dunning" ? "_mahnung" : "";
      const fileName = `${invoice.invoice_number}${suffix}.pdf`;
      const filePath = `teams/${currentTeam?.account_id}/invoices/${fileName}`;

      // Check if the file exists
      const { data: fileExists, error: listError } =
        await supabaseClient.storage
          .from("files")
          .list(`teams/${currentTeam?.account_id}/invoices`, {
            limit: 1,
            search: fileName,
          });

      if (listError || !fileExists || fileExists.length === 0) {
        setFileUrl(null);
        return;
      }

      // Generate a downloadable URL
      const { data, error: downloadError } = await supabaseClient.storage
        .from("files")
        .download(filePath + "?bust=" + Date.now());

      if (downloadError) {
        console.error("Error creating download URL:", downloadError);
        return;
      }

      const url = window.URL.createObjectURL(data);
      setFileUrl(url);
    };

    fetchFileUrl();
  }, [currentTeam?.account_id, invoice?.invoice_number, fileType]);

  async function downloadFile() {
    if (!fileUrl) {
      console.error("No file URL available for download.");
      return;
    }
    const suffix = fileType === "dunning" ? "_mahnung" : "";
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = `${invoice.invoice_number}${suffix}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  if (!fileUrl) {
    return null;
  }

  return (
    <Flex vertical gap={16}>
      {label && <Text strong>{label}:</Text>}
      <Space style={{ width: "100%", justifyContent: "center" }}>
        {invoice?.invoice_number && fileUrl && (
          <DocumentResendButton document={invoice} type={fileType} />
        )}
        <Button onClick={downloadFile}>
          <Space>
            <DownloadOutlined />
            <FilePdfOutlined />
            <Typography.Text>
              {`${invoice.invoice_number}${
                fileType === "dunning" ? "_mahnung" : ""
              }.pdf`}
            </Typography.Text>
          </Space>
        </Button>
      </Space>
      <div style={{ maxWidth: "450px" }}>
        <PdfViewer fileURL={fileUrl} />
      </div>
    </Flex>
  );
};

export default FileDisplay;

import { ThemeConfig } from "antd";

export const themeProvider: ThemeConfig = {
  token: {
    fontSize: 15,
    colorPrimary: '#4074a4',
    colorTextSecondary: '#787878',
    colorTextPlaceholder: '#8898A9',
    controlHeightSM: 30,
    controlHeight: 36,
    controlHeightLG: 40,
    colorLink: '#3366cc',
    colorLinkHover: '#113366',
    colorLinkActive: '#3366cc',
  },
  components: {
    Layout: {
      //bodyBg: "#f9fafc",
      //bodyBg: "#fafafd",
      bodyBg: "rgb(247, 247, 249)",
      headerBg: "#ffffff",
      siderBg: "#212b36",
      headerPadding: "0 24px",
    },
    Card: {
      borderRadiusLG: 4,
      fontSize: 15,
    },
    List: {
      metaMarginBottom: 40,
      titleMarginBottom: 40,
    },
    Drawer: {
      //colorBgElevated: "#f5f5f5",
    },
    Menu: {
      itemSelectedBg: "#E8EEF4",
      itemSelectedColor: "#000",
      itemColor: "#333",
      itemHoverColor: "#000",
      itemHoverBg: "#E8EEF4",
      subMenuItemBg: "rgba(0, 0, 0, 0)",
      borderRadiusLG: 4,
      // popupBg: "#096DD9",
      popupBg: "#fff",
      itemActiveBg: "#DCE3E9",
      itemDisabledColor: "#98A8B9",
      darkItemHoverBg: "#323c47",
    },
    Radio: {
      buttonSolidCheckedBg: "#1890FF",
    },
    Table: {
      //colorBgContainer: "#fafafb",
      headerBg: "#f3f3f7",
      borderColor: "#e6e6e9",
      headerBorderRadius: 0,
      //headerColor: "#939597"
      headerColor: "#454456",
    },
    Button: {
      fontWeight: 500,
      defaultBg: "#eeeeee",
      defaultColor: "#454545",
      defaultHoverBg: "#e6e6e6",
      defaultHoverColor: "#000000",
      lineWidth: 0,
      // controlHeight: 40,
      // controlHeightSM: 32,
      // controlHeightLG: 48,
    },
    Tabs: {
      colorFillAlter: "#fff",
      itemSelectedColor: "#4074a4",
      itemColor: "#898989",
    },
    Form: {
      // controlHeightSM: 32,
      // controlHeight: 40,
      // controlHeightLG: 48,
    },
    Input: {
      colorBgContainerDisabled: "#F3F8FD",
      colorTextDisabled: "#98A8B9",
      // controlHeightSM: 32,
      // controlHeight: 40,
      // controlHeightLG: 48,
    },
    Segmented: {
      // controlHeightSM: 32,
      // controlHeight: 40,
      // controlHeightLG: 48,
      trackBg: "#eee",
      trackPadding: 5,
      borderRadius: 6,
    },
    Select: {
      colorTextDisabled: "#98A8B9",
      colorBgContainerDisabled: "#F3F8FD",
      // controlHeightSM: 32,
      // controlHeight: 40,
      // controlHeightLG: 48,
    },
    DatePicker: {
      // controlHeightSM: 32,
      // controlHeight: 40,
      // controlHeightLG: 48,
    },
    Modal: {
      colorBgMask: "rgba(0,0,0,0.08)",
    },
    Timeline: {
      dotBg: "transparent",
    },
  },
}
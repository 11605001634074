import { CheckCircleOutlined } from "@ant-design/icons";
import {
  useNavigation,
  useTable,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { Button, Input, Popconfirm, Table, Tabs, Typography } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import "./styles.css";

const { TabPane } = Tabs;
const { Text } = Typography;

export default function ShowSentEmail() {
  const { id } = useParams<{ id: any }>();
  const { mutateAsync: updateMail } = useUpdate();
  const { list } = useNavigation();

  const { tableQueryResult } = useTable({
    resource: "info_mails",
    filters: {
      permanent: [
        {
          field: "id",
          operator: "eq",
          value: id,
        },
      ],
    },
    meta: {
      select: "*, from(*), email_sender_addresses(*, integration_account(*))",
    },
  });
  const handleArchive = async (recordId: string) => {
    try {
      await updateMail({
        resource: "info_mails",
        id: recordId,
        values: {
          archived_at: new Date().toISOString(),
        },
      });
      list("info_mails");
    } catch (err) {
      console.log(err);
    }
  };

  const mailHasTagsQuery = useTable({
    resource: "info_mail_has_tags",
    filters: {
      permanent: [
        {
          field: "mail",
          operator: "eq",
          value: id ? parseInt(id, 10) : null,
        },
      ],
    },
    meta: {
      select: "tag",
    },
  });
  const mailTags =
    mailHasTagsQuery?.tableQueryResult?.data?.data?.map((item) => item.tag) ||
    [];

  const tagsName = useTable({
    resource: "tags",
    filters: {
      permanent: [
        {
          field: "id",
          operator: "in",
          value: mailTags.length > 0 ? mailTags : null,
        },
      ],
    },
    meta: {
      select: "*",
    },
  });
  const mailOutboxQueryResult = useTable({
    resource: "mail_outbox",
    filters: {
      permanent: [
        {
          field: "mail_outbox_id",
          operator: "eq",
          value: id ? parseInt(id, 10) : null,
        },
      ],
    },
    meta: {
      select: "*",
    },
  });

  const translate = useTranslate();
  const [searchValue, setSearchValue] = useState<string>("");

  const isLoading = tableQueryResult.isLoading;
  const error = tableQueryResult.error;
  const data = tableQueryResult.data?.data;

  const mailOutboxData = mailOutboxQueryResult?.tableQuery?.data?.data || [];
  const mailOutboxLength = mailOutboxData.length;
  const filteredData = mailOutboxData.filter((item) =>
    item.to?.toLowerCase().includes(searchValue.toLowerCase())
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const mailing = data?.[0];
  const sender = mailing?.from?.email;
  const subject = mailing?.subject;
  const content = data?.[0]?.message_html;
  const plainTextContent =
    new DOMParser().parseFromString(content || "", "text/html").body
      .textContent || "";
  const sendAt = mailing?.send_at;

  return (
    <div style={{ padding: "24px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        {/* Left side elements go together in one child div */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="times-new-roman-style">{subject}</span>
          <CheckCircleOutlined
            style={{
              color: "green",
              fontSize: "15px",
              marginRight: "6px",
              marginLeft: "15px",
            }}
          />
          <Text style={{ fontSize: "15px", color: "#8c8c8c" }}>
            {`${translate("mailings.sent")} ${new Date(
              sendAt
            ).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}, ${new Date(sendAt).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            })}`}
          </Text>
          {tableQueryResult?.data?.data[0]?.archived_at == null && (
            <div style={{ marginLeft: "50px" }}>
              <Popconfirm
                title={translate("mailings.archive_popconfirm")}
                onConfirm={() => handleArchive(id)}
                getPopupContainer={() => document.body} // <-- add this
              >
                <Button type="primary">
                  {translate("mailings.archive_mailing")}
                </Button>
              </Popconfirm>
            </div>
          )}
        </div>
      </div>

      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                paddingRight: "20px",
                borderRight: "1.5px solid #d9d9d9",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "13px",
                  color: "#000",
                }}
              >
                {translate("mailings.mailing")}
              </span>
              <span
                style={{
                  fontSize: "13px",
                  color: "#8c8c8c",
                  fontWeight: "400",
                }}
              >
                {translate("mailings.content")}
              </span>
            </div>
          }
          key="1"
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  marginRight: "8px",
                  fontWeight: 500,
                  fontSize: "13px",
                }}
              >
                {translate("mailings.from")}:
              </p>
              <Text style={{ fontSize: "13px" }}>
                {sender}{" "}
                {mailing?.email_sender_addresses?.integration_account
                  ?.account_type && (
                  <span style={{ marginLeft: "6px", color: "#8c8c8c" }}>
                    (
                    {mailing.email_sender_addresses.integration_account
                      .account_type === "smtp"
                      ? "SMTP"
                      : mailing.email_sender_addresses.integration_account
                          .account_type === "microsoft"
                      ? "Microsoft 365 Outlook"
                      : mailing.email_sender_addresses.integration_account
                          .account_type}
                    )
                  </span>
                )}
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  marginRight: "8px",
                  fontWeight: 500,
                  fontSize: "13px",
                }}
              >
                {translate("mailings.to")}:
              </p>
              <Text style={{ fontSize: "13px" }}>
                <div
                  style={{
                    marginLeft: "10px",
                    fontSize: "13px",
                    color: "#8c8c8c",
                  }}
                >
                  (
                  {tagsName?.tableQueryResult?.data?.data
                    ?.map((tag) => tag.name)
                    .join(", ")}
                  )
                </div>
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  marginRight: "8px",
                  fontWeight: 500,
                  fontSize: "13px",
                }}
              >
                {translate("mailings.subject")}:
              </p>
              <Text style={{ fontSize: "13px" }}>{subject}</Text>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  backgroundColor: "#fafafa",
                  flex: 1,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  textOverflow: "ellipsis",
                  marginTop: "20px",
                  paddingTop: "10px",
                }}
              >
                {plainTextContent}
              </div>
            </div>
          </div>
        </TabPane>

        <TabPane
          tab={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#000",
                }}
              >
                {mailOutboxLength}
              </span>
              <span
                style={{
                  fontSize: "13px",
                  color: "#8c8c8c",
                  fontWeight: "400",
                }}
              >
                {translate("mailings.recipients")}
              </span>
            </div>
          }
          key="2"
        >
          <div style={{ marginTop: "16px" }}>
            <Input.Search
              placeholder={translate("mailings.search_recipients")}
              style={{ marginBottom: "16px", width: "300px" }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Table
              dataSource={filteredData}
              pagination={{ pageSize: 10 }}
              showSorterTooltip={false}
            >
              <Table.Column
                title={translate("mailings.recipient_email")}
                dataIndex="to"
                key="email"
              />
              <Table.Column
                title={translate("mailings.delivery_status")}
                dataIndex="status"
                key="status"
                filters={[
                  { text: translate("mailings.delivered"), value: "Delivered" },
                  { text: translate("mailings.bounced"), value: "Bounced" },
                ]}
                render={(status) => {
                  if (status === "draft") {
                    return translate("mailings.draft");
                  } else if (status === "send") {
                    return translate("mailings.send");
                  } else {
                    return status;
                  }
                }}
              />
              <Table.Column
                title={translate("mailings.date_time")}
                dataIndex="sent_at"
                sorter
                key="timestamp"
                render={(value) =>
                  value ? new Date(value).toLocaleString() : ""
                }
              />
            </Table>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}

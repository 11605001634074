import { Spin } from "antd";
import React, { PropsWithChildren, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../contexts/ContextProvider";
import { usePipelineContext } from "../contexts/PipelineProvider";
import useModules from "../hooks/useModules";

export const ValidTeamChecker: React.FC<PropsWithChildren> = ({ children }) => {
  const { teamSlug } = useParams();
  const { allTeams } = useUser();
  const { fetchModuleData } = useModules();
  const { setCanAccess, canAccess } = usePipelineContext();

  const validSlugs = allTeams?.map((team: any) => team.slug);
  function getDynamicLocalStorageItem() {
    const regex = /^sb-.*-auth-token$/;
    for (let i = 0; i < localStorage.length; i++) {
      const key: any = localStorage.key(i);
      if (regex.test(key)) {
        return localStorage.getItem(key);
      }
    }
    return null;
  }
  useEffect(() => {
    if (getDynamicLocalStorageItem()) fetchModuleData(teamSlug);
    else {
      setCanAccess(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchModuleData, teamSlug]);

  if (validSlugs === undefined || canAccess == undefined) {
    return <Spin />;
  }

  // Überprüfen, ob der Slug im Array gültiger Slugs vorhanden ist
  if (!validSlugs?.includes(teamSlug ?? "")) {
    // Wenn nicht gültig, Weiterleitung zur 404-Seite
    return <div>404</div>;
  }

  // Wenn gültig, render die Kinderkomponenten
  if (!teamSlug) {
    return <Spin />;
  }

  return <>{children}</>;
};

import { useForm } from "@refinedev/antd";
import {
  CanAccess,
  useGetIdentity,
  useInvalidate,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Segmented,
  Select,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import AddressAutoselect from "../../../components/common/AddressAutoselect";
import ContactAutoSelect from "../../../components/contacts/ContactAutoSelect";
import ContactAutoSelectForConnection from "../../../components/contacts/ContactAutoSelectForConnection";
import InvoiceTable from "../../../components/shop/product-items";
import { useSubscriptionProductList } from "../../../hooks/useSubscriptionProductSelect";
type OrdersCreateProps = {
  open?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

const OrdersCreate: React.FC<OrdersCreateProps> = ({
  open = true,
  onClose,
  children,
}) => {
  const translate = useTranslate();
  const [selectedContact, setSelectedContact] = useState<
    string | number | undefined
  >("");
  const [extendedSettings, setExtendedSettings] = useState(false);

  const [selectedInvoiceAddress, setSelectedInvoiceAddress] = useState<
    string | number | undefined
  >("");
  const [searchTerm, setSearchTerm] = useState("");
  const [productItems, setProductItems] = useState<any>();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [shippingCost, setShippingCost] = useState<any>();
  const [deliveryContact, setDeliveryContact] = useState<
    string | number | undefined
  >(undefined);
  const [selectedInvoiceContactData, setSelectedInvoiceContactData] =
    useState<any>(null);

  const [differentDelivery, setDifferentDelivery] = useState(false);
  const [discountChanges, setDiscountChanges] = useState<any[]>([]);

  const { data: me } = useGetIdentity<{ id: string; [key: string]: any }>();
  const handleToggleDelivery = () => {
    setDifferentDelivery((prev) => !prev);
  };

  const [type, setType] = React.useState("simple");
  const [shippingType, setShippingType] = React.useState();

  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const { options: subscriptionProductsOptions, isLoading } =
    useSubscriptionProductList(searchTerm);
  const { form, formProps, saveButtonProps } = useForm({
    resource: "orders",
    action: "create",
    redirect: false,
    invalidates: ["resourceAll"],
  });

  const handleFinish = async ({
    startDate,
    order_date,
    billed_until,
    ...values
  }: any) => {
    setLoadingState(true);
    if (!selectedContact) return;
    const payload: any = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      order_date: dayjs(order_date).format("YYYY-MM-DD"),
      subscriptionProductId: values.subscription_product,
      invoiceContactId: parseInt(selectedContact as string),
      invoiceContactAddressId:
        typeof values.invoice_contact_address == "object"
          ? values.invoice_contact_address?.id
          : values.invoice_contact_address,
      type: values.type,
      shipping_type: shippingType,
      shipping_cost: shippingCost,
      product_items: productItems,
      deliveryContactId: values?.deliveryContactId,
      deliveryContactAddressId:
        typeof values?.delivery_contact_address == "object"
          ? values?.delivery_contact_address?.id
          : values.delivery_contact_address,
      contact_person_id: values.contact_person_id,
      billed_until:
        billed_until == null ? null : dayjs(billed_until).format("YYYY-MM-DD"),
      note: values.note,
      created_by: me?.id,
    };

    if (String(deliveryContact)?.trim()) {
      payload.deliveryContactId = deliveryContact;
      if (values.delivery_contact_address) {
        payload.deliveryContactAddressId = values.delivery_contact_address;
      }
    }

    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_SUPABASE_URL
        }/functions/v1/shop-core/order/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${import.meta.env.VITE_SUPABASE_KEY}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const error = await response.json();

        console.error("Error creating subscription:", error);
      } else {
        invalidate({
          invalidates: ["all"],
          resource: "orders",
        });
        list("orders", "replace");
      }
      setLoadingState(false);
    } catch (err) {
      console.error("Unexpected error:", err);
      setLoadingState(false);
    }
  };

  const handleDiscountCheck = async () => {
    const subscriptionProductId = form.getFieldValue("subscription_product");
    const invoiceContact = form.getFieldValue("invoice_contact");
    if (!invoiceContact || !subscriptionProductId) {
      return;
    }
    const body = {
      products: subscriptionProductId,
      contact: invoiceContact,
    };

    try {
      const response = await fetch(
        `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/shop-core/discount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${import.meta.env.VITE_SUPABASE_KEY}`,
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        console.error("Error fetching discount information");
        return;
      }
      const data = await response.json();
      setDiscountChanges(data); // Save discount changes in state
    } catch (error) {
      console.error("Error in discount check:", error);
    }
  };

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <Modal
          open={open}
          maskClosable={false}
          title={translate("orders.create.title")}
          onCancel={() => {
            if (onClose) {
              onClose();
            } else {
              list("orders", "replace"); // fallback if used as standalone page
            }
          }}
          okText={translate("buttons.save")}
          okButtonProps={{
            ...saveButtonProps,
            loading: loadingState,
          }}
          width={1000}
        >
          <Form {...formProps} layout="vertical" onFinish={handleFinish}>
            <Row gutter={[40, 10]}>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label={translate("orders.fields.type")}
                  rules={[
                    { required: true, message: "Please select the type" },
                  ]}
                  initialValue={type}
                >
                  <Segmented
                    options={[
                      {
                        label: translate("orders.type.simple"),
                        value: "simple",
                      },
                      {
                        label: translate("orders.type.subscription"),
                        value: "subscription",
                      },
                    ]}
                    onChange={(value) => setType(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={translate("subscriptions.fields.order_date")}
                  name="order_date"
                  initialValue={dayjs()}
                >
                  <DatePicker format={translate("global.date_format")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <ContactAutoSelect
                  placeholder={translate("contacts.search_contact_placeholder")}
                  onSelect={(value) => {
                    setSelectedContact(value);
                    form.setFieldsValue({ invoice_contact: value });
                    form.setFieldsValue({ contact_person_id: undefined });
                    form.setFieldsValue({ invoice_contact_address: undefined });
                    if (!value) {
                      setSelectedInvoiceAddress(undefined);
                      form.setFieldsValue({
                        invoice_contact_address: undefined,
                        contact_person_id: undefined,
                      });
                      setSelectedInvoiceContactData(undefined);
                    }
                    handleDiscountCheck();
                  }}
                  getContact={(contactObj) => {
                    setSelectedInvoiceContactData(contactObj);
                  }}
                  disableCreate={false}
                  name="invoice_contact"
                  initialValue={selectedContact}
                  label={translate("subscriptions.fields.user")}
                />
                {selectedInvoiceContactData?.type == "company" && (
                  <ContactAutoSelectForConnection
                    contactId={selectedInvoiceContactData.id}
                    key={selectedInvoiceContactData.id}
                    label={translate("contacts.contact_person.title")}
                    placeholder={translate("contacts.contact_person.select")}
                    name="contact_person_id"
                    onSelect={(value) => {
                      form.setFieldsValue({ contact_person_id: value });
                    }}
                    required={false}
                    filter={[
                      { field: "type", operator: "eq", value: "person" },
                    ]}
                    disableType="company"
                  />
                )}

                {selectedInvoiceAddress && !differentDelivery && (
                  <Button
                    size={"small"}
                    type="link"
                    onClick={handleToggleDelivery}
                  >
                    {differentDelivery
                      ? "Lieferdaten ausblenden"
                      : "Abweichende Lieferdaten"}
                  </Button>
                )}
              </Col>
              <Col span={12}>
                {selectedContact && (
                  <AddressAutoselect
                    key={selectedContact}
                    onSelect={(value) => {
                      setSelectedInvoiceAddress(value);
                      form.setFieldsValue({ invoice_contact_address: value });
                    }}
                    name={"invoice_contact_address"}
                    contactId={selectedContact as string}
                    label={translate(
                      "subscriptions.fields.invoice_contact_address"
                    )}
                    required={false}
                    defaultActiveFirstOption={true}
                  />
                )}
              </Col>
              <Col span={12}>
                <>
                  {differentDelivery && (
                    <ContactAutoSelect
                      onSelect={(value) => {
                        setDeliveryContact(value);
                        if (!value) {
                          form.setFieldsValue({
                            delivery_contact_address: undefined,
                          });
                        }
                      }}
                      name="delivery_contact"
                      label={translate("subscriptions.fields.delivery_contact")}
                      required={false}
                    />
                  )}
                </>
              </Col>

              <Col span={12}>
                {differentDelivery && (
                  <>
                    {deliveryContact && (
                      <AddressAutoselect
                        onSelect={(value) => {
                          form.setFieldsValue({
                            delivery_contact_address: value,
                          });
                        }}
                        name={"delivery_contact_address"}
                        contactId={deliveryContact as string}
                        label={translate(
                          "subscriptions.fields.delivery_contact_address"
                        )}
                        defaultActiveFirstOption={true}
                      />
                    )}
                  </>
                )}
              </Col>
              {selectedContact && (
                <>
                  <Divider />
                  <Col span={24}>
                    <Typography.Text strong>
                      {translate(`orders.type.${type}_details`)}
                    </Typography.Text>
                  </Col>
                  {type === "subscription" && (
                    <>
                      <Col span={24}>
                        {discountChanges.length > 0 && (
                          <Alert
                            message="Discount Updates"
                            description={
                              <ul>
                                {discountChanges.map((change, idx) => (
                                  <li key={idx}>
                                    {change.product_name} (Category:{" "}
                                    {change.product_category_name}) discount
                                    will be updated from {change.oldDiscount}%
                                    to {change.newDiscount}%
                                  </li>
                                ))}
                              </ul>
                            }
                            type="warning"
                            showIcon
                          />
                        )}
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={"subscription_product"}
                          label={translate(
                            "orders.fields.subscription_product"
                          )}
                          rules={[{ required: true }]}
                        >
                          <Select
                            style={{ width: "100%" }}
                            loading={isLoading}
                            options={subscriptionProductsOptions}
                            onChange={handleDiscountCheck}
                            onSearch={(value) => setSearchTerm(value)}
                            showSearch
                            filterOption={false}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {type === "simple" && (
                    <Col span={7}>
                      <Form.Item
                        label={translate("orders.fields.shipping_type")}
                        name="shippingType"
                      >
                        <Select
                          allowClear
                          style={{ minWidth: 200 }}
                          onChange={(value) => setShippingType(value)}
                          options={[
                            {
                              label: translate("orders.shipping_type.delivery"),
                              value: "delivery",
                            },
                            {
                              label: translate("orders.shipping_type.service"),
                              value: "service",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={6}>
                    <Form.Item
                      label={
                        shippingType
                          ? translate(
                              `subscriptions.fields.execution_date_${shippingType}`
                            )
                          : translate(`subscriptions.fields.execution_date`)
                      }
                      name="startDate"
                      initialValue={dayjs()}
                    >
                      <DatePicker format={translate("global.date_format")} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="note"
                      label={translate("subscriptions.fields.note")}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    {!extendedSettings ? (
                      <Button
                        type="link"
                        style={{ margin: 0, padding: 0 }}
                        onClick={() => setExtendedSettings(true)}
                      >
                        + {translate("buttons.extended_settings")}
                      </Button>
                    ) : (
                      <Form.Item
                        label={translate("subscriptions.fields.billed_until")}
                        name="billed_until"
                      >
                        <DatePicker format={translate("global.date_format")} />
                      </Form.Item>
                    )}
                  </Col>
                  {type === "simple" && (
                    <Col span={24}>
                      <InvoiceTable
                        onProductsChange={(value) => setProductItems(value)}
                        onShippingCost={(value) => setShippingCost(value)}
                        subscriptionProduct={true}
                        contactId={selectedContact}
                      />
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Form>
        </Modal>
        {children}
      </>
    </CanAccess>
  );
};

export default OrdersCreate;

import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  LayoutOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useTranslate } from "@refinedev/core";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  Popover,
  Radio,
  Row,
  Space,
  Table,
  theme,
  Tooltip,
  Typography,
} from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";

// Restrict dragging to vertical movement only
const restrictToVerticalAxis = ({ transform }: { transform: any }) =>
  transform ? { ...transform, x: 0 } : undefined;

export type CustomColumnType<T> = {
  default?: boolean; // Display by default if no saved preference
} & ColumnsType<T>[number];

type TableView = {
  id: string;
  name: string;
  columns: string[];
  sortField?: string;
  sortOrder?: "ascend" | "descend";
  isPublic?: boolean; // Flag to mark view as public/shared
  owner?: string; // Who created the view
};

export interface ITellieTableProps<T extends object = any> {
  columns: CustomColumnType<T>[];
  tableProps: TableProps<T>;
  resource: string;
  title?: React.ReactNode;
  extraHeaderButtons?: React.ReactNode;
  onExportButtonClick?: () => void;
  onColumnsChange?: (finalColumns: CustomColumnType<T>[]) => void;
}

const getColumnKey = (col: any): string =>
  (col.key ?? col.dataIndex)?.toString() || "";

const SortableItem = ({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style: React.CSSProperties = {
    transform: transform
      ? `translate(${transform.x}px, ${transform.y}px)`
      : undefined,
    transition,
    marginBottom: 4,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

export function TellieTable<T extends object = any>({
  columns,
  tableProps,
  resource,
  title,
  extraHeaderButtons,
  onExportButtonClick,
  onColumnsChange,
}: ITellieTableProps<T>) {
  const { token } = theme.useToken();
  const translate = useTranslate();
  const {
    personalAccount,
    updateTeam,
    fetchPersonalAccount,
    currentTeam,
    getTeamMember,
  } = useUser();
  const hasRoleOwner =
    getTeamMember(personalAccount.account_id) != undefined ? true : false;
  const dropdownContentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: 260,
  };

  const defaultKeys = useMemo(
    () => columns.filter((col) => col.default).map(getColumnKey),
    [columns]
  );

  const [views, setViews] = useState<TableView[]>([]);
  const [selectedColumnKeys, setSelectedColumnKeys] = useState<string[]>([]);
  const [pendingColumnKeys, setPendingColumnKeys] = useState<string[]>([]);
  const [isViewDropdownOpen, setIsViewDropdownOpen] = useState(false);
  const [isColumnDropdownOpen, setIsColumnDropdownOpen] = useState(false);
  const [activeViewId, setActiveViewId] = useState<string | null>(null);
  const [newViewName, setNewViewName] = useState("");
  const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState(false);
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | undefined>(
    undefined
  );
  const [editingViewId, setEditingViewId] = useState<string | null>(null);
  const [editingViewName, setEditingViewName] = useState("");

  const isInitialized = useRef(false);

  // -----------------------
  // Load Metadata on Mount & Merge Public Views
  // -----------------------
  useEffect(() => {
    if (
      !isInitialized.current &&
      resource &&
      personalAccount &&
      currentTeam &&
      currentTeam.account_id
    ) {
      const metadata = personalAccount.metadata || {};
      // Get team-specific views object (or empty if not set)
      const teamViews =
        (metadata.views && metadata.views[currentTeam.account_id]) || {};
      let loadedViews: TableView[] = [];

      if (teamViews[resource] && Array.isArray(teamViews[resource])) {
        loadedViews = [...teamViews[resource]];
      }

      // Merge in public views from the team account (this part remains unchanged)
      if (
        currentTeam.metadata &&
        currentTeam.metadata.public_views &&
        Array.isArray(currentTeam.metadata.public_views[resource])
      ) {
        loadedViews = [
          ...loadedViews,
          ...currentTeam.metadata.public_views[resource],
        ];
      }

      setViews(loadedViews);

      // Load active view – assuming you also nest viewsActive by team id.
      let activeId: string | null = null;
      if (
        metadata.viewsActive &&
        metadata.viewsActive[currentTeam.account_id]
      ) {
        activeId = metadata.viewsActive[currentTeam.account_id][resource];
      }
      const activeView = loadedViews.find((v) => v.id === activeId);
      if (activeView) {
        setSelectedColumnKeys(activeView.columns);
        setPendingColumnKeys(activeView.columns);
        setSortField(activeView.sortField);
        setSortOrder(activeView.sortOrder);
      } else {
        activeId = "default";
        setSelectedColumnKeys(defaultKeys);
        setPendingColumnKeys(defaultKeys);
      }
      setActiveViewId(activeId);
      isInitialized.current = true;
    }
  }, [resource, personalAccount, currentTeam, defaultKeys]);

  // -----------------------
  // Persist Views to Server & Update Team Public Metadata
  // -----------------------
  const persistViews = async (
    updatedViews: TableView[],
    newActiveId: string | null
  ) => {
    if (
      !resource ||
      !personalAccount ||
      !currentTeam ||
      !currentTeam.account_id
    )
      return;

    // Split views into private and public groups
    const privateViews = updatedViews.filter((view) => !view.isPublic);
    const publicViews = updatedViews.filter((view) => view.isPublic);

    const currentMetadata = personalAccount.metadata || {};

    // Get existing team views or an empty object
    const existingTeamViews =
      (currentMetadata.views &&
        currentMetadata.views[currentTeam.account_id]) ||
      {};
    // Update the views for the current resource
    const newTeamViews = {
      ...existingTeamViews,
      [resource]: privateViews,
    };

    // Get existing active views for the current team
    const existingActiveViews =
      (currentMetadata.viewsActive &&
        currentMetadata.viewsActive[currentTeam.account_id]) ||
      {};
    // Update the active view for the resource
    const newActiveViews = {
      ...existingActiveViews,
      [resource]: newActiveId,
    };

    // Merge back into metadata
    const newPersonalMetadata = {
      ...currentMetadata,
      views: {
        ...currentMetadata.views,
        [currentTeam.account_id]: newTeamViews,
      },
      viewsActive: {
        ...currentMetadata.viewsActive,
        [currentTeam.account_id]: newActiveViews,
      },
    };

    // Update your personal account row with the new metadata
    await updateTeam(personalAccount.account_id, {
      public_metadata: newPersonalMetadata,
    });

    // Update the team account's public metadata (unchanged)
    const currentPublicMetadata = currentTeam.metadata || {};
    const newTeamPublicMetadata = {
      ...currentPublicMetadata,
      public_views: {
        ...currentPublicMetadata.public_views,
        [resource]: publicViews,
      },
    };

    if (currentTeam.account_id)
      await updateTeam(currentTeam.account_id, {
        public_metadata: newTeamPublicMetadata,
      });
    fetchPersonalAccount();
  };

  // -----------------------
  // Handler to toggle a view's public status (only for non‑default views)
  // -----------------------
  const handleTogglePublic = async (viewId: string) => {
    if (!personalAccount) return;
    const updatedViews = views.map((view) => {
      if (view.id === viewId) {
        // Do not allow sharing of the default view
        if (view.name === "Default") return view;
        // Only allow the owner to toggle public status
        if (view.owner && view.owner !== personalAccount.account_id)
          return view;
        return { ...view, isPublic: !view.isPublic };
      }
      return view;
    });
    setViews(updatedViews);
    await persistViews(updatedViews, activeViewId);
  };

  // -----------------------
  // Handle Table Sorting Changes
  // -----------------------
  const handleTableChange: TableProps<T>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    if (!Array.isArray(sorter)) {
      const newSortField = sorter.field?.toString() || undefined;
      const newSortOrder =
        sorter.order === "ascend" || sorter.order === "descend"
          ? sorter.order
          : undefined;
      setSortField(newSortField);
      setSortOrder(newSortOrder);
      if (activeViewId) {
        const updatedViews = views.map((view) =>
          view.id === activeViewId
            ? { ...view, sortField: newSortField, sortOrder: newSortOrder }
            : view
        );
        setViews(updatedViews);
        persistViews(updatedViews, activeViewId);
      }
    }
    if (tableProps.onChange) {
      tableProps.onChange(pagination, filters, sorter, extra);
    }
  };

  // -----------------------
  // View Management Handlers
  // -----------------------
  const handleCreateView = async () => {
    if (!newViewName.trim() || !personalAccount) return;
    const newId = String(Date.now());
    const newView: TableView = {
      id: newId,
      name: newViewName,
      columns: pendingColumnKeys.length
        ? pendingColumnKeys
        : selectedColumnKeys,
      sortField,
      sortOrder,
      isPublic: false, // New views are private by default
      owner: personalAccount.account_id,
    };
    const updatedViews = [...views, newView];
    setViews(updatedViews);
    setActiveViewId(newId);
    setNewViewName("");
    setIsCreatePopoverOpen(false);
    await persistViews(updatedViews, newId);
    setIsViewDropdownOpen(false);
  };

  const handleSelectView = async (viewId: string) => {
    setActiveViewId(viewId);
    const selectedView = views.find((view) => view.id === viewId);
    if (selectedView) {
      setSelectedColumnKeys(selectedView.columns);
      setPendingColumnKeys(selectedView.columns);
      setSortField(selectedView.sortField);
      setSortOrder(selectedView.sortOrder);
    }
    setIsViewDropdownOpen(false);
    await persistViews(views, viewId);
  };

  const handleDeleteView = async (viewId: string) => {
    const updatedViews = views.filter((view) => view.id !== viewId);
    setViews(updatedViews);
    let newActiveId = activeViewId;
    if (activeViewId === viewId) {
      newActiveId = null;
      setSelectedColumnKeys(defaultKeys);
      setPendingColumnKeys(defaultKeys);
      setSortField(undefined);
      setSortOrder(undefined);
    }
    await persistViews(updatedViews, newActiveId);
  };

  const handleRenameConfirm = () => {
    if (!editingViewId) return;
    const trimmedName = editingViewName.trim();
    if (!trimmedName) {
      setEditingViewId(null);
      setEditingViewName("");
      return;
    }
    const updatedViews = views.map((view) =>
      view.id === editingViewId ? { ...view, name: trimmedName } : view
    );
    setViews(updatedViews);
    persistViews(updatedViews, activeViewId);
    setEditingViewId(null);
    setEditingViewName("");
  };

  // -----------------------
  // Column Management Handlers
  // -----------------------
  const handleToggleColumn = (colKey: string) => {
    setPendingColumnKeys((prevKeys) =>
      prevKeys.includes(colKey)
        ? prevKeys.filter((key) => key !== colKey)
        : [...prevKeys, colKey]
    );
  };

  const handleSaveColumns = async () => {
    if (pendingColumnKeys.length === 0) return;
    setSelectedColumnKeys(pendingColumnKeys);

    // Check if a default view already exists
    const defaultViewExists = views.some((view) => view.id === "default");

    // If activeViewId is falsy OR activeViewId is "default" but no default view exists, create it.
    if (!activeViewId || (activeViewId === "default" && !defaultViewExists)) {
      const isDefaultUnchanged =
        pendingColumnKeys.length === defaultKeys.length &&
        pendingColumnKeys.every((key, idx) => key === defaultKeys[idx]);

      if (isDefaultUnchanged) {
        setIsColumnDropdownOpen(false);
        return;
      }

      // Create default view with a consistent id "default"
      const updatedDefaultView: TableView = {
        id: "default",
        name: "Default",
        columns: pendingColumnKeys,
        sortField,
        sortOrder,
        isPublic: false,
        owner: personalAccount?.account_id,
      };

      const updatedViews = [
        ...views.filter((view) => view.id !== "default"),
        updatedDefaultView,
      ];

      setViews(updatedViews);
      setActiveViewId("default");
      await persistViews(updatedViews, "default");
    } else {
      // Update the existing view (or non-default view)
      const updatedViews = views.map((view) =>
        view.id === activeViewId
          ? { ...view, columns: pendingColumnKeys, sortField, sortOrder }
          : view
      );
      setViews(updatedViews);
      await persistViews(updatedViews, activeViewId);
    }
    setIsColumnDropdownOpen(false);
  };

  // -----------------------
  // Drag & Drop Handler for Column Reordering
  // -----------------------
  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setPendingColumnKeys((keys) => {
        const oldIndex = keys.indexOf(active.id);
        const newIndex = keys.indexOf(over.id);
        return arrayMove(keys, oldIndex, newIndex);
      });
    }
  };

  // -----------------------
  // Compute Final Columns for the Table
  // -----------------------
  const finalColumns = useMemo(() => {
    return selectedColumnKeys
      .map((key) => columns.find((col) => getColumnKey(col) === key))
      .filter((col): col is CustomColumnType<T> => Boolean(col));
  }, [columns, selectedColumnKeys]);

  // Notify parent component if columns change
  const prevFinalKeysRef = useRef<string>("");
  useEffect(() => {
    if (onColumnsChange) {
      const newKeys = finalColumns.map((col) => getColumnKey(col)).join(",");
      if (newKeys !== prevFinalKeysRef.current) {
        prevFinalKeysRef.current = newKeys;
        onColumnsChange(finalColumns);
      }
    }
  }, [finalColumns, onColumnsChange]);

  // -----------------------
  // Compute Available (Unselected) Columns
  // -----------------------
  const availableColumns = useMemo(() => {
    return columns.filter(
      (col) => !pendingColumnKeys.includes(getColumnKey(col))
    );
  }, [columns, pendingColumnKeys]);

  // -----------------------
  // Handle View Radio Selection
  // -----------------------
  const handleViewRadioChange = async (e: any) => {
    const value = e.target.value;
    if (value === "default") {
      // Set activeViewId to "default" instead of null
      setActiveViewId("default");
      setSelectedColumnKeys(defaultKeys);
      setPendingColumnKeys(defaultKeys);
      setSortField(undefined);
      setSortOrder(undefined);
      await persistViews(views, "default");
    } else {
      await handleSelectView(value);
    }
  };

  // -----------------------
  // Dropdown Content for Views & Columns
  // -----------------------

  // Group my views and shared views. My views include the default view and any view where you are the owner.
  const myViews = views.filter((v) => v.owner === personalAccount?.account_id);
  // Shared views are public and not owned by you.
  const sharedViews = views.filter(
    (v) => v.isPublic && v.owner !== personalAccount?.account_id
  );

  const addViewPopoverContent = (
    <Row style={{ gap: 8 }}>
      <Input
        placeholder={translate("table.name")}
        value={newViewName}
        onChange={(e) => setNewViewName(e.target.value)}
        size="small"
        style={{ flex: 1 }}
      />
      <Button
        type="default"
        size="small"
        onClick={handleCreateView}
        disabled={!newViewName.trim()}
      >
        {translate("buttons.add")}
      </Button>
    </Row>
  );

  const viewDropdownContent = (
    <div style={{ padding: "16px 14px", width: 230 }}>
      <Row justify="space-between">
        <Typography.Text strong>{translate("table.views")}</Typography.Text>
        <Popover
          content={addViewPopoverContent}
          zIndex={99999}
          trigger="click"
          destroyTooltipOnHide
          onOpenChange={(visible) => {
            if (!visible) {
              setNewViewName(""); // Optional: reset input if you want
            }
          }}
        >
          <Button
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            style={{ marginLeft: 8 }}
            onClick={() => {
              handleCreateView();
            }}
          />
        </Popover>
      </Row>
      <Radio.Group
        onChange={handleViewRadioChange}
        value={activeViewId === null ? "default" : activeViewId}
        style={{ display: "flex", flexDirection: "column", marginTop: 4 }}
      >
        {/* Always show the default view option */}
        <Radio value="default">{translate("table.default")}</Radio>
        {/* Render My Views (excluding default) */}
        {myViews
          .filter((view) => view.name !== "Default")
          .map((view) => (
            <Row
              key={view.id}
              style={{ display: "flex", marginTop: 4 }}
              justify="space-between"
            >
              <Radio value={view.id} style={{ marginTop: 4 }}>
                {editingViewId === view.id ? (
                  <Input
                    size="small"
                    value={editingViewName}
                    onChange={(e) => setEditingViewName(e.target.value)}
                    onBlur={handleRenameConfirm}
                    onPressEnter={handleRenameConfirm}
                    autoFocus
                    style={{ padding: 1 }}
                  />
                ) : (
                  <span>{view.name}</span>
                )}
              </Radio>
              {/* Allow editing/deletion only for non-default views */}
              {view.name !== "Default" && (
                <Space>
                  {hasRoleOwner && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTogglePublic(view.id);
                      }}
                    >
                      {view.isPublic ? <StarFilled /> : <StarOutlined />}
                    </span>
                  )}
                  {!editingViewId && (
                    <EditOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingViewId(view.id);
                        setEditingViewName(view.name);
                      }}
                    />
                  )}
                  <DeleteOutlined
                    style={{ color: "red" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteView(view.id);
                    }}
                  />
                </Space>
              )}
            </Row>
          ))}
        {/* Render Shared Views if any */}
        {sharedViews.length > 0 && (
          <>
            <Divider style={{ margin: "8px 0" }} />
            <Typography.Text strong>
              {translate("table.shared_views")}
            </Typography.Text>

            {sharedViews.map((view) => (
              <Row
                key={view.id}
                style={{ display: "flex", marginTop: 4 }}
                justify="space-between"
              >
                <Radio value={view.id} style={{ marginTop: 4 }}>
                  <span>{view.name}</span>
                </Radio>
              </Row>
            ))}
          </>
        )}
      </Radio.Group>
    </div>
  );

  const columnDropdownContent = (
    <div style={{ padding: "16px 14px", width: 260 }}>
      <Typography.Text strong>
        {translate("table.table_columns")}
      </Typography.Text>
      <div style={{ marginTop: 16 }}>
        <DndContext
          sensors={useSensors(
            useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
          )}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToParentElement, restrictToVerticalAxis]}
        >
          <SortableContext
            items={pendingColumnKeys}
            strategy={verticalListSortingStrategy}
          >
            {pendingColumnKeys.map((colKey) => {
              const column = columns.find(
                (col) => getColumnKey(col) === colKey
              );
              if (!column) return null;
              return (
                <SortableItem key={colKey} id={colKey}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Checkbox
                      checked
                      onChange={() => handleToggleColumn(colKey)}
                    >
                      {typeof column.title === "function"
                        ? column.title({} as any)
                        : column.title || getColumnKey(column)[0].toUpperCase() + getColumnKey(column).slice(1)}
                    </Checkbox>
                  </div>
                </SortableItem>
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ maxHeight: 200, overflowY: "auto" }}>
          {availableColumns.map((col: any) => {
            const colKey = (col.key ?? col.dataIndex)?.toString() || "";
            return (
              <div key={colKey} style={{ marginBottom: 4 }}>
                <Checkbox
                  checked={false}
                  onChange={() => handleToggleColumn(colKey)}
                >
                  {col.title || colKey}
                </Checkbox>
              </div>
            );
          })}
        </div>
      </div>
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: 8, justifyContent: "flex-end", width: "100%" }}>
        <Button type="primary" size="small" onClick={handleSaveColumns}>
          {translate("buttons.save")}
        </Button>
      </Space>
    </div>
  );

  // Determine the current view name for the dropdown button
  const activeView = views.find((view) => view.id === activeViewId);
  const currentViewName = activeView
    ? activeView.name === "Default"
      ? translate("table.default")
      : activeView.name
    : translate("table.default");

  // -----------------------
  // Render the Component
  // -----------------------
  return (
    <div style={{ width: "100%", padding: "16px 0" }}>
      <Space
        wrap
        style={{
          marginBottom: 16,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Space>
          {title && (
            <Typography.Title level={4} style={{ margin: 0, paddingRight: 16 }}>
              {title}
            </Typography.Title>
          )}
          {extraHeaderButtons}
        </Space>

        <Space wrap style={{ marginRight: "5px" }}>
          <Dropdown
            open={isViewDropdownOpen}
            onOpenChange={setIsViewDropdownOpen}
            trigger={["click"]}
            dropdownRender={() => (
              <div style={dropdownContentStyle}>{viewDropdownContent}</div>
            )}
          >
            <Button>
              {currentViewName} <DownOutlined />
            </Button>
          </Dropdown>

          <Dropdown
            open={isColumnDropdownOpen}
            onOpenChange={setIsColumnDropdownOpen}
            trigger={["click"]}
            dropdownRender={() => (
              <div style={dropdownContentStyle}>{columnDropdownContent}</div>
            )}
          >
            <Button>
              <LayoutOutlined /> <DownOutlined />
            </Button>
          </Dropdown>
          {onExportButtonClick && (
            <Tooltip title={translate("settings.invoices.fields.download")}>
              <Button
                onClick={onExportButtonClick}
                icon={<DownloadOutlined />}
                type="default"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </Tooltip>
          )}
        </Space>
      </Space>
      <Table
        {...tableProps}
        columns={finalColumns}
        onChange={handleTableChange}
        style={{ width: "100%" }}
      />
    </div>
  );
}

import { ArrowRightOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import {
  Col,
  Flex,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { BillingType, IPhase } from "../../interfaces/phase.interface";
import InvoiceTable from "./product-items";

type Props = {
  data?: any;
  handleChange: (phase: IPhase) => void;
  handlePhaseDelete: ((index: number) => void) | undefined;
  handlePhaseAdd?: () => void; // Optional: To handle adding a new phase
  isSubscription?: boolean;
  currency?: any;
  index?: number;
  isIssueBasedSubscription?: boolean;
  totalPhases?: number; // Total number of phases, useful for conditional rendering
};

export default function SinglePhase({
  data,
  handleChange,
  handlePhaseDelete,
  handlePhaseAdd,
  isSubscription,
  currency,
  index,
  isIssueBasedSubscription,
  totalPhases = 1, // Default to 1 phase
}: Props) {
  const [phase, setPhase] = useState<IPhase>(data);
  const [forever, setForever] = useState<boolean>(true);
  const [initialTrigger, setInitialTrigger] = useState<boolean>(true);
  const isDataChange = useRef(false);

  const translate = useTranslate();

  // New States for pre_contract and auto_convert_to_contract
  const [preContractPhase, setPreContractPhase] = useState<boolean>(
    phase.pre_contract || false
  );
  const [autoConvertToContract, setAutoConvertToContract] = useState<boolean>(
    phase.auto_convert_to_contract || false
  );

  // State to manage the visibility of phases after activating automatic renewal
  const [isAutomaticRenewalEnabled, setIsAutomaticRenewalEnabled] =
    useState<boolean>(phase.automatic_renewal ? true : false);

  useEffect(() => {
    isDataChange.current = true;
    setPhase(data);
    setPreContractPhase(data.pre_contract || false);
    setAutoConvertToContract(data.auto_convert_to_contract || false);
    setIsAutomaticRenewalEnabled(data.automatic_renewal ? true : false);
  }, [data]);

  useEffect(() => {
    if (isDataChange.current) {
      isDataChange.current = false;
    } else {
      if (initialTrigger) {
        setTimeout(function () {
          handleChange(phase);
          setInitialTrigger(false);
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase]);

  const setNestedProperty = (obj: any, path: string, value: any) => {
    let keys = path.split(".");
    let current = obj;

    while (keys.length > 1) {
      const [firstKey, ...restKeys] = keys;
      if (!current[firstKey]) {
        current[firstKey] = {};
      }
      current = current[firstKey];
      keys = restKeys;
    }

    current[keys[0]] = value;
  };

  const handleFieldChange = (field: string, value: any) => {
    const updatedPhase = { ...phase };

    if (field === "pre_contract") {
      updatedPhase.pre_contract = value;
      // If pre_contract is enabled, ensure it's only on the first phase
      if (index !== 0 && value) {
        // Optionally, you can enforce this constraint here or handle it in the parent component
        console.warn("pre_contract can only be set on the first phase.");
        return;
      }
    } else if (field === "auto_convert_to_contract") {
      updatedPhase.auto_convert_to_contract = value;
    } else if (field === "automatic_renewal") {
      updatedPhase.automatic_renewal = value;
      setIsAutomaticRenewalEnabled(value !== null);
    } else if (field === "start" || field === "end") {
      // Hidden fields, handled by backend
      updatedPhase[field] = value;
    } else {
      setNestedProperty(updatedPhase, field, value);
    }

    setPhase(updatedPhase);
    handleChange(updatedPhase);
  };

  useEffect(() => {
    if (preContractPhase) handleFieldChange("automatic_renewal", false);
  }, [preContractPhase]);
  useEffect(() => {
    if (totalPhases === 1 && phase.auto_convert_to_contract && handlePhaseAdd) {
      handlePhaseAdd();
    }
  }, [phase.auto_convert_to_contract, totalPhases]);

  if (!phase) return <Spin />;

  return (
    <>
      {isSubscription ? (
        <Space direction="vertical">
          {/* Automatic Renewal */}
          {index !== undefined && totalPhases === index + 1 && (
            <Form.Item
              label={translate("product_items.fields.automatic_renewal_switch")}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                size={"small"}
                checked={isAutomaticRenewalEnabled}
                onChange={(checked) => {
                  if (checked) {
                    handleFieldChange("automatic_renewal", true);
                    setIsAutomaticRenewalEnabled(true);
                  } else {
                    handleFieldChange("automatic_renewal", false);
                    setIsAutomaticRenewalEnabled(false);
                  }
                }}
              />
            </Form.Item>
          )}
        </Space>
      ) : (
        <Flex vertical gap={24}>
          {/* View for the subscription configuration */}
          <Row gutter={[10, 10]}>
            {index === 0 && (
              <Col xs={24} md={24}>
                <Flex align={"center"} gap={12}>
                  <Switch
                    checked={preContractPhase}
                    size={"small"}
                    onChange={(checked) => {
                      setPreContractPhase(checked);
                      handleFieldChange("pre_contract", checked);
                    }}
                  />
                  <Typography.Text
                    type={preContractPhase ? undefined : "secondary"}
                  >
                    {translate(
                      "subscription_products.fields.pre_contract_phase"
                    )}
                  </Typography.Text>
                </Flex>
              </Col>
            )}

            {/* Hidden Start Fields */}
            <Col xs={24} md={12} style={{ display: "none" }}>
              {/* Start Interval Count and Type */}
              {/* These fields are hidden as per requirement */}
              <Form.Item label={translate("subscriptions.list.duration")}>
                {/* Hidden Start Field */}
                <InputNumber
                  style={{ width: "100%", maxWidth: "80px" }}
                  min={1}
                  value={
                    typeof phase.start === "object" &&
                    phase.start.interval_count
                      ? phase.start.interval_count
                      : 1
                  }
                  onChange={(value) =>
                    handleFieldChange("start.interval_count", value)
                  }
                />
                <Select
                  style={{
                    width: "100%",
                    minWidth: "160px",
                    maxWidth: "160px",
                  }}
                  value={
                    typeof phase.start === "string"
                      ? phase.start
                      : phase.start?.interval || "start"
                  }
                  onChange={(value) => {
                    const intervalCount =
                      typeof phase.start === "object" &&
                      phase.start.interval_count
                        ? phase.start.interval_count
                        : 1;
                    handleFieldChange("start", {
                      interval: value,
                      interval_count: intervalCount,
                    });
                  }}
                  disabled={true} // Always hidden/disabled
                >
                  {/* Add BillingType options if needed */}
                </Select>
                <ArrowRightOutlined />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              {/* Duration */}
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label={translate("subscriptions.list.duration")}
              >
                <Flex gap={12} align={"center"}>
                  {/* Hidden End Field */}
                  <InputNumber
                    style={{ width: "100%", maxWidth: "80px" }}
                    min={1}
                    value={
                      typeof phase.end === "object" && phase.end.interval_count
                        ? phase.end.interval_count
                        : 1
                    }
                    onChange={(value) =>
                      handleFieldChange("end.interval_count", value)
                    }
                  />

                  <Select
                    style={{
                      width: "100%",
                      minWidth: "160px",
                      maxWidth: "160px",
                    }}
                    value={
                      typeof phase.end === "string"
                        ? phase.end
                        : phase.end?.interval || "day"
                    }
                    onChange={(value) => {
                      const intervalCount =
                        typeof phase.end === "object" &&
                        phase.end.interval_count
                          ? phase.end.interval_count
                          : 1;
                      handleFieldChange("end", {
                        interval: value,
                        interval_count: intervalCount,
                      });
                    }}
                  >
                    <Select.Option value={BillingType.Day}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Day}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Week}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Week}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Month}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Month}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Year}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Year}`
                      )}
                    </Select.Option>
                  </Select>
                </Flex>
              </Form.Item>
            </Col>

            {/* Conditional Rendering based on pre_contract */}
            {!preContractPhase && (
              <>
                {index !== undefined && totalPhases === index + 1 && (
                  <Col xs={24} md={12}>
                    {/* Automatic Renewal */}
                    <Form.Item
                      label={translate(
                        "product_items.fields.automatic_renewal_switch"
                      )}
                    >
                      <Flex gap={12} align={"center"}>
                        <Switch
                          size={"small"}
                          checked={isAutomaticRenewalEnabled}
                          onChange={(checked) => {
                            if (checked) {
                              handleFieldChange("automatic_renewal", true);
                            } else {
                              handleFieldChange("automatic_renewal", null);
                            }
                          }}
                        />
                        {!isAutomaticRenewalEnabled && (
                          <Typography.Text type="secondary">
                            {translate(
                              "product_items.fields.automatic_renewal_switch_deactivated"
                            )}
                          </Typography.Text>
                        )}
                      </Flex>
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} md={12}>
                  {/* Invoice Interval */}
                  <Form.Item
                    label={translate("product_items.fields.invoice_interval")}
                  >
                    <Flex gap={12} align={"center"}>
                      <Form.Item noStyle>
                        <InputNumber
                          style={{ width: "100%", maxWidth: "80px" }}
                          min={1}
                          value={
                            phase.billing_interval?.interval_count
                              ? phase.billing_interval.interval_count
                              : 1
                          }
                          onChange={(value) =>
                            handleFieldChange(
                              "billing_interval.interval_count",
                              value
                            )
                          }
                          placeholder="Count"
                        />
                      </Form.Item>

                      <Form.Item noStyle>
                        <Select
                          style={{
                            width: "100%",
                            minWidth: "160px",
                            maxWidth: "160px",
                          }}
                          value={
                            phase.billing_interval?.interval
                              ? phase.billing_interval.interval
                              : "day"
                          }
                          onChange={(value) =>
                            handleFieldChange(
                              "billing_interval.interval",
                              value
                            )
                          }
                          placeholder="Interval"
                        >
                          <Select.Option value={BillingType.Day}>
                            {translate(
                              `subscription_products.fields.billing_type.${BillingType.Day}`
                            )}
                          </Select.Option>
                          <Select.Option value={BillingType.Week}>
                            {translate(
                              `subscription_products.fields.billing_type.${BillingType.Week}`
                            )}
                          </Select.Option>
                          <Select.Option value={BillingType.Month}>
                            {translate(
                              `subscription_products.fields.billing_type.${BillingType.Month}`
                            )}
                          </Select.Option>
                          <Select.Option value={BillingType.Year}>
                            {translate(
                              `subscription_products.fields.billing_type.${BillingType.Year}`
                            )}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Flex>
                  </Form.Item>
                </Col>
              </>
            )}

            {/* Issues Field for Issue-Based Subscriptions */}
            {isIssueBasedSubscription && (
              <Col xs={24} md={12}>
                <Form.Item
                  label={translate("subscription_products.fields.issues")}
                  required
                >
                  <Flex gap={12}>
                    <Form.Item
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please enter the interval count",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%", maxWidth: "80px" }}
                        min={1}
                        onChange={(value) =>
                          handleFieldChange("issues.interval_count", value)
                        }
                        value={
                          phase.issues?.interval_count
                            ? phase.issues.interval_count
                            : 1
                        }
                      />
                    </Form.Item>
                  </Flex>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Flex>
      )}
      <Col xs={24} md={24}>
        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={translate("subscription_products.fields.invoice_content")}
        >
          <InvoiceTable
            initialValues={phase.products}
            onProductsChange={(updatedProducts) => {
              // Update phase.products and notify parent
              handleFieldChange("products", updatedProducts);
            }}
            onShippingCost={(totalChange) => {
              handleFieldChange("shipping", totalChange);
            }}
            initialShipping={phase?.shipping}
          />
        </Form.Item>
      </Col>

      {/* Auto-Convert to Contract Switch */}
      {preContractPhase && (
        <Col xs={24} md={12}>
          <Flex align={"center"} gap={12}>
            <Switch
              checked={phase.auto_convert_to_contract}
              size="small"
              onChange={(checked) => {
                setAutoConvertToContract(checked);
                handleFieldChange("auto_convert_to_contract", checked);
              }}
            />
            <Typography.Text
              type={autoConvertToContract ? undefined : "secondary"}
            >
              {translate(
                "subscription_products.fields.pre_contract_phase_auto_convert"
              )}{" "}
              ({translate("product_items.phase_nr")} 2)
            </Typography.Text>
          </Flex>
        </Col>
      )}
    </>
  );
}

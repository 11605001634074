import {SearchOutlined} from "@ant-design/icons";
import {instantMeiliSearch} from '@meilisearch/instant-meilisearch';
import {useGo, useList, useTranslate} from "@refinedev/core";
import {Flex, Select, Tag, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {
    Configure,
    InstantSearch,
    useHits,
    useInstantSearch,
    useSearchBox,
} from 'react-instantsearch';
import {useLocation, useNavigate} from 'react-router-dom';
import {useUser} from "../../contexts/ContextProvider";
import {useSearchContext} from "../../contexts/SearchProvider";
import useTeam from "../../hooks/useTeam";

const {Text} = Typography;

export default function GlobalSearch() {

    const {searchKey} = useSearchContext();
    const [searchClient, setSearchClient] = useState(() => instantMeiliSearch(
        'https://search.tellie.io',
        searchKey,
        {
            finitePagination: true,
        }
    ));

    useEffect(() => {
        const newSearchClient = instantMeiliSearch(
            'https://search.tellie.io',
            searchKey,
            {
                finitePagination: true,
            }
        );

        setSearchClient(newSearchClient);
        newSearchClient.setMeiliSearchParams({
            showRankingScore: true,
        });
    }, [searchKey]);

    const {currentTeam} = useUser();

    const {data: settingsData, isLoading, isFetching, isError, refetch} = useList({
        resource: "settings",
        queryOptions: {
            enabled: !!currentTeam?.account_id, // Verhindert die Abfrage, wenn account_id nicht vorhanden ist
        },
        filters: [{field: "account", operator: "eq", value: currentTeam?.account_id}],
    });

    React.useEffect(() => {
        refetch();
    }, [currentTeam?.account_id, refetch]);

    const location = useLocation();
    const indexName = location.pathname.includes('contacts') ? 'contacts' : location.pathname.includes('orders') ? 'orders' : null;
    if (!indexName || !searchKey) {
        return <></>;
    }


    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <InstantSearch indexName={indexName} searchClient={searchClient.searchClient}
                       future={{preserveSharedStateOnUnmount: false}}>
            <Configure
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hitsPerPage={30}
                attributesToSnippet={['firstname:3']}
                snippetEllipsisText="..."
            />
            <div style={{padding: '1em'}}>
                <GlobalSearchSelect
                    index={indexName}
                    teamSettings={settingsData}
                    placeholder="Suchbegriff eingeben..."
                    style={{width: '100%'}}
                />
            </div>
        </InstantSearch>
    );
}

function GlobalSearchSelect(props) {
    const {query, refine} = useSearchBox();
    const {status} = useInstantSearch();
    const {hits} = useHits();
    const isSearchStalled = status === 'stalled';
    const [inputValue, setInputValue] = useState(query);
    const navigate = useNavigate();
    const {currentTeam} = useTeam();

    useEffect(() => {
        setInputValue(query);
    }, [query]);

    const handleSearch = (value) => {
        setInputValue(value);
        refine(value);
    };

    const handleChange = (value) => {
        setInputValue(value);
    };

    const go = useGo();

    const handleSelect = (value) => {
        const id = String(value).includes('_')
            ? String(value).split('_')[1]
            : String(value);
        go({
            to: {
                resource: hits.find(hit => hit.id === value)?._index === 'contacts' ? 'contacts' : 'orders',
                action: "show",
                id: id,
            },
        });
    };

    function findDifferences(data) {
        const result = [];

        for (const key in data) {
            if (data[key].length > 1) {
                const ids = data[key].map(item => item.id);
                const differingFields = new Set();

                const firstItem = data[key][0];

                data[key].forEach(item => {
                    compareObjects(firstItem, item, '', differingFields);
                });

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                result.push({
                    ids: [...new Set(ids)],
                    fields: [...differingFields]
                });
            }
        }

        return result;
    }

    function compareObjects(base, compare, prefix, differingFields) {
        for (const key in base) {
            if (key.startsWith("_")) continue;
            const baseValue = base[key];
            const compareValue = compare[key];
            const fieldPath = prefix ? `${prefix}.${key}` : key;

            if (typeof baseValue === 'object' && baseValue !== null && !Array.isArray(baseValue)) {
                if (typeof compareValue === 'object' && compareValue !== null) {
                    compareObjects(baseValue, compareValue, fieldPath, differingFields);
                } else {
                    differingFields.add(fieldPath);
                }
            } else if (Array.isArray(baseValue)) {
                if (Array.isArray(compareValue)) {
                    baseValue.forEach((_, index) => {
                        if (JSON.stringify(baseValue[index]) !== JSON.stringify(compareValue[index])) {
                            differingFields.add(`${fieldPath}.${index}`);
                        }
                    });
                } else {
                    differingFields.add(fieldPath);
                }
            } else if (baseValue !== compareValue) {
                differingFields.add(fieldPath);
            }
        }
    }

    const filterHighlightedResults = (hits) => {
        const groupedContacts = {};

        hits.forEach(hit => {
            const keyIdentifier = [
                hit._highlightResult?.firstname?.value || "",
                hit._highlightResult?.lastname?.value || "",
                hit._highlightResult?.company_name?.value || ""
            ].join("|");

            if (!groupedContacts[keyIdentifier]) {
                groupedContacts[keyIdentifier] = [];
            }
            groupedContacts[keyIdentifier].push(hit);
        });

        const groupDifferences = findDifferences(groupedContacts);

        return hits.map(hit => {
            const filteredData = {};
            const hitId = hit.id;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const groupDifference = groupDifferences.find(group => group.ids.includes(hitId));
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const differingFields = groupDifference ? new Set(groupDifference.fields) : new Set();

            if (hit._index === "orders") {
                // Keine Filterung, alle keys übernehmen und "value" auspacken
                const unpackValues = (obj) => {
                    if (Array.isArray(obj)) {
                        return obj.map(item => unpackValues(item));
                    } else if (typeof obj === 'object' && obj !== null) {
                        const unpacked = {};
                        for (const [key, value] of Object.entries(obj)) {
                            unpacked[key] = unpackValues(value);
                        }
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return unpacked.value !== undefined ? unpacked.value : unpacked;
                    }
                    return obj;
                };

                Object.entries(hit._highlightResult).forEach(([key, obj]) => {
                    filteredData[key] = unpackValues(obj);
                });
                filteredData['_id'] = filteredData['id'];
                return filteredData;
            } else if (hit._index === "contacts") {
                Object.entries(hit._highlightResult).forEach(([key, obj]) => {
                    const specialKeys = ['id', 'type', 'source_id'];

                    if (["firstname", "lastname", "salutation", "company_name", "customer_number"].includes(key)) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        if (obj.value && obj.value !== "null") {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            filteredData[key] = obj.value;
                        }
                    }
                    if (key === "id") {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        filteredData['_id'] = obj.value;
                    } else if (key === "type") {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        filteredData['_type'] = obj.value;
                    } else if (key === "_index") {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        filteredData['_index'] = obj.value;
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                    } else if (obj.value?.includes("<mark>") || differingFields.has(key) && !specialKeys.includes(key)) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        filteredData[key] = obj.value;
                    } else if (Array.isArray(obj)) {

                        const markedItems = key === 'activities' ? obj.filter((item) => (
                            item.value?.text?.value ? item.value.text.value.includes("<mark>") : false
                    )) : obj.filter((item, index) =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            Object.values(item).some(subObj => subObj.value?.includes("<mark>")) || differingFields.has(`${key}.${index}`) && !specialKeys.includes(key)
                        );
                        if (markedItems.length > 0) {
                            if(key === 'activities') {
                                filteredData[key] = markedItems;
                            } else {
                                filteredData[key] = markedItems.map(item =>
                                    Object.fromEntries(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        Object.entries(item).map(([subKey, subObj]) => [subKey, subObj.value])
                                    )
                                );
                            }
                        }
                    } else if (typeof obj === 'object') {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const markedItems = Object.entries(obj).filter(([subKey, subObj]) =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            subObj.value?.includes("<mark>") || differingFields.has(`${key}.${subKey}`) && !specialKeys.includes(key)
                        );
                        if (markedItems.length > 0) {
                            filteredData[key] = Object.fromEntries(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                markedItems.map(([subKey, subObj]) => [subKey, subObj.value])
                            );
                        }
                    }
                });

                return filteredData;
            }

            return filteredData;
        });
    };

    const translate = useTranslate();

    function contact(hit, settings) {
        return <div style={{display: 'flex', flexDirection: 'column'}}>
            {hit._type === 'person' ? (
                <Text strong>
                            <span dangerouslySetInnerHTML={{
                                __html: [hit.salutation, hit.firstname, hit.lastname]
                                    .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                                    .join(' ')
                            }}/>
                </Text>
            ) : (
                <div>
                    <Text strong>
                        <span dangerouslySetInnerHTML={{__html: hit.company_name}}/>
                    </Text>
                </div>
            )}

            <div>
                {Object.entries(hit)
                    .filter(([key]) => !key.startsWith('_'))
                    .filter(([key, obj]) => !['salutation', 'firstname', 'lastname', 'company_name'].includes(key))
                    .map(([key, obj]) => (
                        <div key={key} style={{fontSize: '0.9em', color: '#555'}}>
                            {key !== 'custom_fields' ? (
                                <strong>{translate("search.keys." + key, key)}: </strong>
                            ) : null}
                            {Array.isArray(obj) ? (
                                key === 'addresses' ? (
                                    obj.map((item, index) => (
                                        <div key={index} style={{marginLeft: '10px'}}>
                                            <span dangerouslySetInnerHTML={{
                                                __html: `${item.street}, ${item.zip} ${item.city}, ${item.country?.toUpperCase()}`
                                            }}/>
                                        </div>
                                    ))
                                ) : (key === 'activities' ? (
                                    obj.map((item, index) => (
                                        <div key={index} style={{marginLeft: '10px'}}>
                                            <div dangerouslySetInnerHTML={{
                                                __html: item.value?.text?.value
                                                    .replace(/&lt;/g, "<")
                                                    .replace(/&gt;/g, ">")
                                                    .replace(/&amp;/g, "&")
                                                    .replace(/&quot;/g, '"')
                                                    .replace(/&#039;/g, "'")
                                            }}/>
                                        </div>
                                    ))
                                    ) : (
                                    obj.map((item, index) => (
                                        <div key={index} style={{marginLeft: '10px'}}>
                                            {typeof item === 'object' && item !== null ? (
                                                Object.entries(item).map(([subKey, subObj]) => (
                                                    <div key={subKey}>
                                                        <strong>{translate("search.keys." + subKey, subKey)}:</strong>{" "}
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: subObj as string}}/>
                                                    </div>
                                                ))
                                            ) : (
                                                <span
                                                    dangerouslySetInnerHTML={{__html: item as string}}/>
                                            )}
                                        </div>
                                    ))
                                ))
                            ) : typeof obj === 'object' && obj !== null ? (
                                Object.entries(obj).map(([subKey, subObj]) => (

                                    key !== 'custom_fields' ? (
                                        <div key={subKey} style={{marginLeft: '10px'}}>
                                            <strong>{translate("search.keys." + subKey, subKey)}:</strong>{" "}
                                            <span dangerouslySetInnerHTML={{__html: subObj as string}}/>
                                        </div>

                                    ) : (
                                        <div key={subKey}>
                                            <strong>{settings?.contact_custom_fields_json?.find(setting => setting?.field.name === subKey)?.field.label || subKey}:</strong>{" "}
                                            <span dangerouslySetInnerHTML={{__html: subObj as string}}/>
                                        </div>
                                    )
                                ))
                            ) : (
                                <span dangerouslySetInnerHTML={{__html: obj as string}}/>
                            )}
                        </div>
                    ))}
            </div>

        </div>;
    }

    function order(hit) {
        return <div style={{display: 'flex', flexDirection: 'column'}}>
            <Flex justify="space-between">
                <div>
                    <Text strong>
                        #<span dangerouslySetInnerHTML={{__html: [hit.order_number]}}/>
                        &nbsp;<span dangerouslySetInnerHTML={{__html: [hit.name]}}/>
                        {hit.description ? (
                            <>
                                &nbsp; (<span dangerouslySetInnerHTML={{__html: [hit.description]}}/>)
                            </>
                        ) : null}

                    </Text>
                </div>
                <div>
                    {hit.suspend === "true" ? (
                        <>&nbsp;<Tag color="yellow"><span dangerouslySetInnerHTML={{__html: [hit.suspended]}}/></Tag></>
                    ) : null}
                    &nbsp;<Tag><span dangerouslySetInnerHTML={{__html: [hit.status]}}/></Tag>
                </div>
            </Flex>

            {/*{hit.order_date ? (*/}
            {/*    <div>*/}
            {/*        <strong>{translate("search.keys.order_date")}: </strong>*/}
            {/*        <span dangerouslySetInnerHTML={{*/}
            {/*            __html: `${hit.order_date}`*/}
            {/*        }}/>*/}
            {/*    </div>*/}
            {/*) : null}*/}
            {hit.note ? (
                <div>
                    <strong>{translate("search.keys.order_date")}: </strong>
                    <span dangerouslySetInnerHTML={{
                        __html: `${hit.note}`
                    }}/>
                </div>
            ) : null}

            <Flex gap="large">
                <div>
                    <Text strong>
                        {translate("search.keys.invoice_contact")}
                    </Text>
                    <hr/>
                    <span dangerouslySetInnerHTML={{
                        __html: [
                            hit.invoice_contact.company_name,
                            hit.invoice_contact.company_line_2,
                            hit.invoice_contact.salutation,
                            hit.invoice_contact.firstname,
                            hit.invoice_contact.lastname]
                            .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                            .join(' ')
                    }}/><br/>
                    <span dangerouslySetInnerHTML={{
                        __html: [
                            hit.invoice_contact_address.street]
                            .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                            .join(' ')
                    }}/><br/>
                    <span dangerouslySetInnerHTML={{
                        __html: [
                            hit.invoice_contact_address.zip,
                            hit.invoice_contact_address.city]
                            .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                            .join(' ')
                    }}/>
                </div>


                {[
                    hit.delivery_contact.company_name,
                    hit.delivery_contact.company_line_2,
                    hit.delivery_contact.salutation,
                    hit.delivery_contact.firstname,
                    hit.delivery_contact.lastname,
                    hit.delivery_contact_address.street,
                    hit.delivery_contact_address.zip,
                    hit.delivery_contact_address.city
                ].some(value => value && value !== 'null') && (
                    <div>
                        <Text strong>
                            {translate("search.keys.delivery_contact")}
                        </Text>
                        <hr/>
                        <span dangerouslySetInnerHTML={{
                            __html: [
                                hit.delivery_contact.company_name,
                                hit.delivery_contact.company_line_2,
                                hit.delivery_contact.salutation,
                                hit.delivery_contact.firstname,
                                hit.delivery_contact.lastname]
                                .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                                .join(' ')
                        }}/><br/>
                        <span dangerouslySetInnerHTML={{
                            __html: [
                                hit.delivery_contact_address.street]
                                .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                                .join(' ')
                        }}/><br/>
                        <span dangerouslySetInnerHTML={{
                            __html: [
                                hit.delivery_contact_address.zip,
                                hit.delivery_contact_address.city]
                                .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                                .join(' ')
                        }}/>
                    </div>
                )}

                {[
                    hit.contact_person_id.company_name,
                    hit.contact_person_id.company_line_2,
                    hit.contact_person_id.salutation,
                    hit.contact_person_id.firstname,
                    hit.contact_person_id.lastname
                ].some(value => value && value !== 'null') && (
                    <div>
                        <Text strong>
                            {translate("search.keys.contact_person")}
                        </Text>
                        <hr/>
                        <span dangerouslySetInnerHTML={{
                            __html: [
                                hit.contact_person_id.company_name,
                                hit.contact_person_id.company_line_2,
                                hit.contact_person_id.salutation,
                                hit.contact_person_id.firstname,
                                hit.contact_person_id.lastname]
                                .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                                .join(' ')
                        }}/>
                    </div>
                )}
            </Flex>


        </div>;
    }

    return (
        <Flex align="center">
            <div>
                <Tag style={{padding: '6px 8px'}}>{translate("search.index." + props.index, props.index)}  </Tag>
            </div>
            <Select
                suffixIcon={<SearchOutlined/>}
                showSearch
                value={inputValue}
                placeholder={props.placeholder}
                style={props.style}
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                onSelect={handleSelect}
                notFoundContent={isSearchStalled ? 'Lädt …' : null}
                dropdownRender={(menu) => (
                    <div>
                        {menu}
                        {/*<div style={{padding: '8px', textAlign: 'center', borderTop: '1px solid #e8e8e8'}}>*/}
                        {/*    Custom Footer*/}
                        {/*</div>*/}
                    </div>
                )}
                labelRender={(label) => {
                    const hit = hits.find(hit => hit.id === label.value);
                    return (
                        <>
                            {hit?._type === 'person' ? (
                                <Text strong>
                            <span dangerouslySetInnerHTML={{
                                __html: [hit.salutation, hit.firstname, hit.lastname]
                                    .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                                    .join(' ')
                            }}/></Text>) : (
                                <div>
                                    <Text strong>
                                        <span dangerouslySetInnerHTML={{__html: hit?.company_name}}/>
                                    </Text>
                                </div>
                            )}
                        </>
                    )
                }}
            >
                {/*<div>{JSON.stringify(props.teamSettings.data[0], null, 2)}</div>*/}
                {filterHighlightedResults(hits).map((hit) => (
                    <Select.Option key={hit._id} value={hit._id}>
                        {hit._index === 'orders' ? order(hit) : contact(hit, props.teamSettings?.data[0] ?? {contact_custom_fields_json: []})}
                    </Select.Option>
                ))}
            </Select>
        </Flex>
    );
}
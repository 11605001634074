import { PlusOutlined } from "@ant-design/icons";
import { DeleteButton, useTable } from "@refinedev/antd";
import { CanAccess, useNavigation, useTranslate } from "@refinedev/core";
import { Button, Flex, Spin, Table } from "antd";
import React, { PropsWithChildren } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InformationAboutSubscriptionProduct from "../../../components/shop/InformationAboutSubscriptionProduct";
import { useUser } from "../../../contexts/ContextProvider";

const SubscriptionProductsList: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { replace } = useNavigation();

  const { currentTeam } = useUser();
  const { id } = useParams();

  const { tableProps, tableQuery } = useTable({
    resource: "subscription_products",
    sorters: {
      initial: [
        {
          field: "id",
          order: "desc",
        },
      ],
    },
    syncWithLocation: false,
    filters: {
      permanent: [
        {
          field: "account",
          value: currentTeam?.account_id,
          operator: "eq",
        },
        {
          field: "product_item",
          value: id,
          operator: "eq",
        },
      ],
    },
    pagination: { pageSize: 999 },
  });

  const translate = useTranslate();

  const handleRowClick = (event: any, id: any) => {
    if (event.target.className !== "ant-checkbox-input")
      navigate("subscription-products/show/" + id);
  };

  if (!currentTeam || !currentTeam?.account_id || tableProps.loading) {
    return <Spin />;
  }

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      {tableQuery.data?.total == 0 ? (
        <>
          <InformationAboutSubscriptionProduct />
        </>
      ) : (
        <>
          <Flex vertical gap={12}>
            <Table
              {...tableProps}
              rowKey={(record) => record.id ?? ""}
              rowClassName={"clickableRow"}
              loading={currentTeam === undefined}
              onRow={(record) => {
                return {
                  onClick: (event) => handleRowClick(event, record.id),
                };
              }}
              pagination={false}
            >
              <Table.Column
                dataIndex="name"
                title={translate("subscription_products.fields.title")}
              />

              <Table.Column
                title={translate("subscription_products.fields.description")}
                dataIndex="description"
              />
              <Table.Column
                width={100}
                align="center"
                render={(_, record) => (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    <DeleteButton
                      resource="subscription_products"
                      recordItemId={String(record?.id)}
                      type="link"
                      size="small"
                    />
                  </div>
                )}
              />
            </Table>

            <Flex>
              <Button
                icon={<PlusOutlined />}
                type="link"
                style={{ color: "var(--colorPrimary)" }}
                onClick={() => replace("subscription-products/create")}
              >
                {translate("subscription_products.buttons.add_product")}
              </Button>
            </Flex>
          </Flex>
        </>
      )}
      {children}
    </CanAccess>
  );
};
export default SubscriptionProductsList;

import { Button, Card, Col, Image, Row, Space, Typography } from "antd";
import YoutubePlayer from "../common/YoutubePlayer";

const { Title, Paragraph, Text } = Typography;

type Props = {
  onActivate: () => void;
  title?: string;
};

export default function MailingInfo({ onActivate, title }: Props) {
  return (
    <Card
      bordered={false}
      style={{
        maxWidth: 1000,
        margin: "auto",
        padding: "2rem",
        boxShadow: "0 8px 32px rgba(0,0,0,0.05)",
        borderRadius: 16,
      }}
    >
      <Row gutter={[32, 32]} align="middle">
        {/*<Col xs={24} md={8}>*/}
        {/*  <Image*/}
        {/*    width="100%"*/}
        {/*    src="https://placehold.co/300x300?text=Mailing+Modul&font=roboto"*/}
        {/*    alt="Mailing Modul Vorschau"*/}
        {/*    style={{ borderRadius: 16 }}*/}
        {/*    preview={false}*/}
        {/*  />*/}
        {/*</Col>*/}

        <Col xs={24} md={16}>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Title level={2}>Newsletter-Versand</Title>
            <Paragraph type="secondary">
              Automatisiere deine Kommunikation mit Kunden über moderne,
              personalisierte E-Mail-Kampagnen.
            </Paragraph>
            <Paragraph>
              ✓ Intelligente Segmentierung <br />
              ✓ Integrierte Duplikatfilterung <br />
              ✓ Vorlagen-Editor und viele professionelle Vorlagen
            </Paragraph>
            {/*<div style={{ textAlign: "right", marginTop: "2rem" }}>*/}
            {/*  <Button type="primary" size="large" onClick={onActivate}>*/}
            {/*    Jetzt loslegen*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </Space>
        </Col>
      </Row>

      {/*<div style={{ marginTop: "3rem" }}>*/}
      {/*  <Title level={4}>So funktioniert das Modul</Title>*/}
      {/*  <Row gutter={[16, 16]}>*/}
      {/*    <Col xs={24} md={12}>*/}
      {/*      <Image*/}
      {/*        src="https://placehold.co/500x300?text=Screenshot+1&font=roboto"*/}
      {/*        alt="Screenshot 1"*/}
      {/*        width="100%"*/}
      {/*        style={{ borderRadius: 12 }}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*    <Col xs={24} md={12}>*/}
      {/*      <Image*/}
      {/*        src="https://placehold.co/500x300?text=Screenshot+2&font=roboto"*/}
      {/*        alt="Screenshot 2"*/}
      {/*        width="100%"*/}
      {/*        style={{ borderRadius: 12 }}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</div>*/}

      <div style={{ marginTop: "2rem", textAlign: "center" }}>
        <YoutubePlayer videoId="7MJdnGmbDxU" />
      </div>

      <div style={{ marginTop: "2rem", textAlign: "center" }}>
        <Text type="secondary">
          Dieses Modul ist Teil des Wachstumspakets für dein Team.
        </Text>
      </div>
    </Card>
  );
}
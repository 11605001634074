import { BaseKey, CrudFilter, useTranslate } from "@refinedev/core";
import { Button, Modal, Space, Tabs } from "antd";
import { TabsProps } from "antd/lib";
import React, { useEffect } from "react";
import { formatQuery } from "react-querybuilder";
import { parseJsonLogic } from "react-querybuilder/dist/parseJsonLogic";
import { useSearchParams } from "react-router-dom";
import CustomQueryBuilder from "./CustomQueryBuilder";
import GroupList from "./group-list";
import GroupsAndSegmentsFilter from "./GroupsAndSegmentsFilter";
import SegmentList from "./segment-list";
// import moment from "moment";

type OperatorMap = { [key: string]: string };

const operatorConversionMap: OperatorMap = {
  "=": "eq",
  "!=": "ne",
  "<": "lt",
  ">": "gt",
  "<=": "lte",
  ">=": "gte",
  contains: "contains",
  beginsWith: "startswith",
  endsWith: "endswith",
  doesNotContain: "ncontains",
  doesNotBeginWith: "nstartswith",
  doesNotEndWith: "nendswith",
  null: "null",
  notNull: "nnull",
  in: "in",
  notIn: "nin",
  between: "between",
  notBetween: "nbetween",
  inWeek: "and",
};

function convertToCrudOperator(operatorName: string): string | undefined {
  return operatorConversionMap[operatorName];
}

type Rule = {
  field?: string;
  operator?: string;
  value?: any;
  combinator?: string;
  rules?: Rule[];
};
function flattenAndOperators(arr) {
  return arr.reduce((acc, item) => {
    if (item.operator === "and" && Array.isArray(item.value)) {
      return acc.concat(flattenAndOperators(item.value));
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
}
function transformRule(rule: Rule): any {
  const transformed: any = {};
  if (rule.field) {
    transformed.field = rule.field.replace(/^contacts\./, "");
  }

  if (rule.operator) {
    transformed.operator = convertToCrudOperator(rule.operator);
  }
  if (rule.rules) {
    transformed.value = flattenAndOperators(
      rule?.rules?.map(transformRule) ?? []
    );
  }
  if (rule.value) {
    transformed.value = rule.value;
  }

  if (rule.combinator) {
    transformed.operator = rule.combinator;
  }
  return transformed;
}

type Props = {
  handleGroups?: (value: BaseKey[]) => void;
  handleSegments?: (value: any) => void;
  groupsValue?: any;
  setFilters?: (filters: CrudFilter[], behavior?: any) => void;
  filters?: CrudFilter[];
  initialSegmentValue?: any;
  showInWeek?: boolean;
  outsideOpenModal?: boolean;
  setOutsideOpenModal?: (val: boolean) => void;
};
export default function FilterGroup({
  handleGroups,
  handleSegments,
  groupsValue = [],
  setFilters,
  filters,
  initialSegmentValue,
  showInWeek = false,
  outsideOpenModal,
  setOutsideOpenModal,
}: Props) {
  const [openModal, setOpenModal] = React.useState(false);
  const [value, setValue] = React.useState<BaseKey[]>(groupsValue);
  const [segmentsValue, setSegmentsValue] =
    React.useState<any>(initialSegmentValue);
  const [openFilters, setOpenFilters] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const translate = useTranslate();

  const handleGroupChange = (val: BaseKey[]) => {
    setValue(val);
    handleGroups?.(val);
  };

  const handleSegmentsClear = React.useCallback(
    async (byClearButton?: boolean) => {
      const segmentsFilters: any = filters
        ?.filter(
          (filter: any) => !["account", "tags.id"].includes(filter.field)
        )
        .map((filterValue) => ({ ...filterValue, value: undefined }));
      setFilters?.(segmentsFilters ?? []);
      setFilters &&
        setSearchParams((params) => {
          params.delete("segmentId");
          return params;
        });
      if (byClearButton) {
        setOpenFilters(false);
      }
    },
    [filters, setFilters, setSearchParams]
  );
  const handleSegmentChange = React.useCallback(
    async (val: any, isCustom?: boolean) => {
      setSegmentsValue(() =>
        val?.query ? val : initialSegmentValue ? initialSegmentValue : val
      );
      if (!val?.query) {
        setFilters?.([]);
      } else {
        try {
          await handleSegmentsClear();
          const query = parseJsonLogic(val.query);
          const transformed = transformRule(query);
          handleSegments?.({ id: val.id, query: val.query });
          setFilters?.([...flattenAndOperators(transformed?.value ?? [])]);
          setFilters &&
            setSearchParams({ segmentId: isCustom ? "-1" : val.id });
        } catch (error) {
          console.error("Failed to handle segment change", error);
        }
      }
    },
    [
      handleSegments,
      handleSegmentsClear,
      initialSegmentValue,
      setFilters,
      setSearchParams,
    ]
  );

  const handleSegmentViewChange = React.useCallback(
    async (val) => {
      await handleSegmentsClear();
      const jsonQuery = formatQuery(val, "jsonlogic");

      const transformedQuery = transformRule(val);
      handleSegments?.({ id: -1, query: val });
      setSegmentsValue({ id: -1, query: jsonQuery });
      setFilters?.(flattenAndOperators(transformedQuery?.value ?? []));
      setFilters && setSearchParams({ segmentId: "-1" });
    },
    [handleSegments, handleSegmentsClear, setFilters, setSearchParams]
  );

  useEffect(() => {
    const segmentId = searchParams.get("segmentId");
    if (!segmentsValue && segmentId) {
      setSegmentsValue({ id: parseInt(segmentId) });
    }
  }, [searchParams, segmentsValue]);
  const queryBuilderComponent = React.useMemo(
    () => (
      <CustomQueryBuilder
        key={JSON.stringify(segmentsValue?.id)}
        initialQuery={segmentsValue?.query ?? undefined}
        handleChange={handleSegmentViewChange}
        showInWeek={showInWeek}
      />
    ),
    [
      segmentsValue?.id,
      segmentsValue?.query,
      handleSegmentViewChange,
      showInWeek,
    ]
  );
  const items: TabsProps["items"] = [
    { key: "1", label: translate("groups.groups"), children: <GroupList /> },
    {
      key: "2",
      label: translate("segments.segments"),
      children: <SegmentList />,
    },
  ];

  return (
    <Space wrap direction="vertical">
      <Space wrap style={{ minHeight: 33 }}>
        {/*<GroupsAutoComplete value={value} onChange={handleGroupChange} />*/}
        {/*<SegmentsAutoComplete*/}
        {/*  value={segmentsValue?.id}*/}
        {/*  onChange={(val) => handleSegmentChange(val)}*/}
        {/*  onClear={handleSegmentsClear}*/}
        {/*/>*/}
        {/*<div style={{ marginLeft: -5 }}>*/}
        {/*  {(!setOutsideOpenModal || openModal) && (*/}
        {/*    <Button*/}
        {/*      type="text"*/}
        {/*      size="small"*/}
        {/*      onClick={() => setOpenModal(true)}*/}
        {/*      title="Manage Groups & Segments"*/}
        {/*      icon={<SettingOutlined />}*/}
        {/*      style={{ padding: 0 }}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</div>*/}
      </Space>
      <Space wrap>
        <GroupsAndSegmentsFilter
          value={{ groupIds: value, segmentId: segmentsValue }}
          onChange={({ groupIds, segmentId }) => {
            setValue(groupIds ?? []);
            setSegmentsValue(segmentId ?? null);
            handleGroups?.(groupIds ?? []);
            if (segmentId) handleSegmentChange(segmentId);
          }}
          onClear={handleSegmentsClear}
        />

        {segmentsValue && (
          <Button
            type="link"
            size="small"
            onClick={() => setOpenFilters(!openFilters)}
          >
            Edit Filters
          </Button>
        )}
        {openFilters && queryBuilderComponent}
      </Space>
      <Modal
        title={translate("contacts.manage_groups_segments_title")}
        onCancel={() =>
          setOutsideOpenModal && outsideOpenModal != undefined
            ? setOutsideOpenModal(false)
            : setOpenModal(false)
        }
        width={"60%"}
        centered
        footer={null}
        styles={{
          body: {
            // overflowY: "auto",
            // height: "75dvh",
            padding: 20,
            paddingTop: 0,
          },
        }}
        open={
          setOutsideOpenModal && outsideOpenModal != undefined
            ? outsideOpenModal
            : openModal
        }
      >
        <Tabs
          defaultActiveKey="1"
          renderTabBar={(props, DefaultTabBar) => (
            <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <DefaultTabBar
                {...props}
                style={{ background: "#fff", padding: "20px 0" }}
              />
            </div>
          )}
          items={items}
        />
      </Modal>
    </Space>
  );
}

import { useSelect } from "@refinedev/antd";
import { CrudFilters, useTranslate } from "@refinedev/core";
import { Button, Divider, Form, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { useCallback, useRef, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { getContactFormattedName } from "../../utility/contactName";
import CreateContactModal from "./CreateContactModal";

type Props = {
  name?: string;
  label?: string;
  initialValue?: string | number;
  onSelect?: (value: string | number | undefined) => void;
  getContact?: (contact: any) => void;
  filterIds?: string[];
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  disableCreate?: boolean;
  disableType?: string;
};

export default function ContactAutoSelect(props: Props) {
  const {
    label,
    name = "contact",
    onSelect,
    getContact,
    initialValue,
    filterIds,
    required = true,
    disabled = false,
    placeholder,
    disableCreate = false,
    disableType,
  } = props;

  const translate = useTranslate();
  const { currentTeam } = useUser();

  const [searchValue, setSearchValue] = useState("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<any>(null);

  // Build filters for refine's useSelect
  const buildFilters = useCallback((): CrudFilters => {
    const filters: CrudFilters = [
      {
        operator: "or",
        value: [
          { field: "firstname", operator: "contains", value: searchValue },
          { field: "lastname", operator: "contains", value: searchValue },
          { field: "company_name", operator: "contains", value: searchValue },
        ],
      },
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
    ];

    if (filterIds && filterIds.length > 0) {
      filters.push({
        field: "id",
        operator: "nin",
        value: `(${filterIds.join(",")})`,
      });
    }

    return filters;
  }, [searchValue, currentTeam?.account_id, filterIds]);

  interface ContactRecord {
    id: number;
    firstname?: string;
    lastname?: string;
    company_name?: string;
  }

  const { query, selectProps } = useSelect<ContactRecord>({
    resource: "contacts",
    filters: buildFilters(),
    optionLabel: (item) => getContactFormattedName(item),
    defaultValue: initialValue,
    defaultValueQueryOptions: {
      enabled: !!initialValue,
    },
    queryOptions: {
      enabled: !!currentTeam?.account_id,
      onSuccess: () => setLoading(false),
    },
    sorters: [{ field: "created_at", order: "desc" }],
  });

  const handleSearch = useCallback(
    debounce((val: string) => {
      setLoading(true);
      setSearchValue(val);
    }, 300),
    []
  );

  const handleChange = (value: string, option: any) => {
    const selectedItem = query.data?.data.find(
      (item) => String(item.id) == value
    );

    onSelect?.(value);
    getContact?.(selectedItem);
  };

  const handleClear = () => {
    setSearchValue("");
    onSelect?.(undefined);
    getContact?.(undefined);
  };

  const handleCreateSuccess = (contact: any) => {
    query.refetch();
    onSelect?.(String(contact.id));
    getContact?.(contact);
    setOpenCreateModal(false);

    setTimeout(() => {
      inputRef.current?.focus();
    }, 300);
  };

  return (
    <>
      <Form.Item
        name={name}
        label={label ?? translate("contacts.contact_person.title")}
        rules={[{ required }]}
      >
        <Select
          options={selectProps.options}
          value={
            selectProps.value
              ? String((selectProps.value as any).value)
              : undefined
          }
          ref={inputRef}
          showSearch
          loading={loading}
          placeholder={
            placeholder ?? translate("contacts.contact_person.select")
          }
          allowClear
          disabled={disabled}
          filterOption={false}
          onClear={handleClear}
          onSearch={handleSearch}
          onChange={handleChange}
          dropdownRender={(menu) => (
            <>
              {loading ? <Spin style={{ margin: 8 }} /> : menu}
              <Divider style={{ margin: "4px 0" }} />
              {!disableCreate && (
                <Button block onClick={() => setOpenCreateModal(true)}>
                  {translate("contacts.create_new_contact")}
                </Button>
              )}
            </>
          )}
        />
      </Form.Item>

      {openCreateModal && (
        <CreateContactModal
          open={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          onSuccess={handleCreateSuccess}
          disableType={disableType}
        />
      )}
    </>
  );
}

import { BaseOption } from "@refinedev/core";
import { Form, Input, Select, Space, Typography } from "antd";
import React, { useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { useStageSelect } from "../../hooks/useStageSelect";
import FormAutoSelect from "../mailings/FormAutoSelect";
import { SingleElementFormV2 } from "../single-element-form/single-element-form-v2";
import SettingsContainer from "./components/SettingsContainer";
import SettingsItem from "./components/SettingsItem";
import { useSettingsLogic } from "./hooks/useSettingsLogic";

const SettingsPage: React.FC = () => {
  const [activeForm, setActiveForm] = useState<
    "payment_deadline" | "vat_exempt" | "dunnings_deadline"
  >();
  const { currentTeam } = useUser();
  const { settings, updateModuleStatus, translate } = useSettingsLogic({
    modulePath: "shop",
  });
  const { selectProps: stageSelectProps } = useStageSelect(
    undefined,
    "*,pipeline(name)",
    {
      optionLabel: (item) => `${item?.pipeline?.name}/${item?.name}`,
    }
  );
  const vatExemptDefault = "Umsatzsteuerfreie Leistungen gemäß §19 UStG.";
  return (
    <SettingsContainer
      modulePath="shop"
      title={translate("settings.shop.title")}
      description={translate("settings.shop.description")}
      intro={translate("settings.shop.intro")}
    >
      <SettingsItem
        title={translate("settings.shop.variants.title")}
        description={translate("settings.shop.variants.description")}
        checked={settings["shop.variants"]}
        onChange={() =>
          updateModuleStatus("shop.variants", !settings["shop.variants"])
        }
      />

      <SettingsItem
        title={translate("settings.shop.ecommerce.title")}
        description={translate("settings.shop.ecommerce.description")}
        checked={settings["shop.ecommerce"]}
        onChange={() =>
          updateModuleStatus("shop.ecommerce", !settings["shop.ecommerce"])
        }
      ></SettingsItem>

      <SettingsItem
        title={translate("settings.shop.selfservice.title")}
        description={translate("settings.shop.selfservice.description")}
        checked={settings["shop.selfservice"]}
        onChange={() =>
          updateModuleStatus("shop.selfservice", !settings["shop.selfservice"])
        }
      />

      <SettingsItem
        title={translate("settings.shop.draft_invoice.title")}
        description={translate("settings.shop.draft_invoice.description")}
        checked={settings["shop.draft_invoice"]}
        onChange={() =>
          updateModuleStatus(
            "shop.draft_invoice",
            !settings["shop.draft_invoice"]
          )
        }
      />
      <SettingsItem
        title={translate("settings.shop.auto_due_invoices.title")}
        description={translate("settings.shop.auto_due_invoices.description")}
        checked={settings["shop.auto_due_invoices"]}
        onChange={() =>
          updateModuleStatus(
            "shop.auto_due_invoices",
            !settings["shop.auto_due_invoices"]
          )
        }
      />

      <SettingsItem
        title={translate("settings.shop.payment_email_information.title")}
        description={translate(
          "settings.shop.payment_email_information.description"
        )}
        checked={settings["shop.payment_email_information"]}
        onChange={() =>
          updateModuleStatus(
            "shop.payment_email_information",
            !settings["shop.payment_email_information"]
          )
        }
      />
      <SettingsItem
        title={translate("settings.shop.vat_exempt.title")}
        description={translate(
          `settings.shop.vat_exempt${
            settings["shop.vat_exempt"] ? "_label" : ""
          }.description`
        )}
        checked={settings["shop.vat_exempt"]}
        onChange={() =>
          updateModuleStatus("shop.vat_exempt", !settings["shop.vat_exempt"])
        }
      >
        {settings["shop.vat_exempt"] && (
          <SingleElementFormV2
            state={
              activeForm && activeForm === "vat_exempt"
                ? "form"
                : settings["shop.vat_exempt_label"]
                ? "view"
                : "view"
            }
            view={
              <Space size={"small"}>
                <Input.TextArea
                  value={settings["shop.vat_exempt_label"] || vatExemptDefault}
                  placeholder={translate(
                    "settings.shop.vat_exempt_label.placeholder"
                  )}
                  readOnly
                  rows={4}
                  onClick={() => setActiveForm("vat_exempt")}
                  style={{
                    width: "100%",
                    minWidth: "350px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    minHeight: "25px",
                  }}
                />
                {/* </Form.Item> */}
              </Space>
            }
            onClick={() => setActiveForm("vat_exempt")}
            onUpdate={() => {
              setActiveForm(undefined);
            }}
            onCancel={() => setActiveForm(undefined)}
            style={{ paddingLeft: 0 }}
            formProps={{
              async onFinish(value) {
                if (value.payment_deadline) {
                  await updateModuleStatus(
                    "shop.vat_exempt_label",
                    parseInt(value.vat_exempt_label)
                  );
                }
                setActiveForm(undefined);
              },
            }}
          >
            <Form.Item
              name="vat_exempt_label"
              initialValue={
                settings["shop.vat_exempt_label"] ?? vatExemptDefault
              }
              label="lskdf"
              style={{ flex: 1, margin: 0 }}
            >
              <Input.TextArea
                placeholder={translate(
                  "settings.shop.vat_exempt_label.placeholder"
                )}
                rows={4}
                style={{
                  width: "100%",
                  fontSize: "14px",
                  borderRadius: "4px",
                  minHeight: "25px",
                }}
              />
            </Form.Item>
          </SingleElementFormV2>
        )}
      </SettingsItem>
      <SettingsItem
        title={translate("settings.shop.dunnings.title")}
        description={translate("settings.shop.dunnings.description")}
        checked={settings["shop.dunnings"]}
        onChange={() =>
          updateModuleStatus("shop.dunnings", !settings["shop.dunnings"])
        }
      />
      <SettingsItem
        title={translate("settings.shop.dunnings_deadline.title")}
        description={translate("settings.shop.dunnings_deadline.description")}
      >
        <SingleElementFormV2
          state={
            activeForm && activeForm === "dunnings_deadline"
              ? "form"
              : settings["shop.dunnings_deadline"]
              ? "view"
              : "view"
          }
          view={
            <Space size={"middle"}>
              <Input
                value={settings["shop.dunnings_deadline"]}
                placeholder={translate(
                  "settings.shop.dunnings_deadline.placeholder"
                )}
                readOnly
                onClick={() => setActiveForm("dunnings_deadline")}
                style={{
                  width: "100%",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
              />
              {/* </Form.Item> */}
            </Space>
          }
          onClick={() => setActiveForm("dunnings_deadline")}
          onUpdate={() => {
            setActiveForm(undefined);
          }}
          onCancel={() => setActiveForm(undefined)}
          style={{ paddingLeft: 0 }}
          formProps={{
            async onFinish(value) {
              if (value.dunnings_deadline) {
                await updateModuleStatus(
                  "shop.dunnings_deadline",
                  parseInt(value.dunnings_deadline)
                );
              }
              setActiveForm(undefined);
            },
          }}
        >
          <Form.Item
            name="dunnings_deadline"
            initialValue={settings["shop.dunnings_deadline"]}
            style={{ flex: 1, margin: 0 }}
          >
            <Input
              placeholder={translate(
                "settings.shop.dunnings_deadline.placeholder"
              )}
              type="number"
              style={{
                width: "100%",
                fontSize: "14px",
                borderRadius: "4px",
                minHeight: "25px",
              }}
            />
          </Form.Item>
        </SingleElementFormV2>
      </SettingsItem>
      <SettingsItem
        title={translate("settings.shop.dunnings_text.title")}
        description={translate("settings.shop.dunnings_text.description")}
      >
        <Form>
          <Form.Item
            extra={"Mahnung"}
            initialValue={settings["shop.dunning_text"]?.title}
            name="title"
          >
            <Input
              placeholder={translate("global.title")}
              onBlur={(e) =>
                updateModuleStatus("shop.dunning_text.title", e.target.value)
              }
            />
          </Form.Item>
          <Form.Item
            extra={
              "Wir bitten Sie, die nachfolgend aufgelisteten Lieferungen/Leistungen unverzüglich zu begleichen."
            }
            initialValue={settings["shop.dunning_text"]?.introduction}
            name="introduction"
          >
            <Input
              placeholder={translate("global.introduction")}
              onBlur={(e) =>
                updateModuleStatus(
                  "shop.dunning_text.introduction",
                  e.target.value
                )
              }
            />
          </Form.Item>
          <Form.Item
            extra={
              "Sollten Sie den offenen Betrag bereits beglichen haben, betrachten Sie dieses Schreiben als gegenstandslos."
            }
            initialValue={settings["shop.dunning_text"]?.remark}
            name="remark"
          >
            <Input
              placeholder={translate("global.remark")}
              onBlur={(e) =>
                updateModuleStatus("shop.dunning_text.remark", e.target.value)
              }
            />
          </Form.Item>
        </Form>
      </SettingsItem>
      <SettingsItem
        title={translate("settings.shop.dunnings_letter.title")}
        description={translate("settings.shop.dunnings_letter.description")}
        checked={settings["shop.dunnings_letter"]}
        onChange={() =>
          updateModuleStatus(
            "shop.dunnings_letter",
            !settings["shop.dunnings_letter"]
          )
        }
      />
      <SettingsItem
        title={translate("settings.shop.suppress_invoice_reminders.title")}
        description={translate(
          "settings.shop.suppress_invoice_reminders.description"
        )}
      >
        <Select
          {...stageSelectProps}
          value={
            !stageSelectProps.options?.length
              ? null
              : (settings["shop.suppress_invoice_reminders"] as
                  | BaseOption
                  | null
                  | undefined) ?? null
          }
          style={{ minWidth: "100%" }}
          options={[
            { label: "-", value: null },
            ...(stageSelectProps.options ? stageSelectProps.options : []),
          ]}
          onSelect={async (value) => {
            updateModuleStatus(
              "shop.suppress_invoice_reminders",
              value ?? null
            );
          }}
        />
      </SettingsItem>
      {currentTeam?.account_id && (
        <SettingsItem title={translate("settings.shop.sender_mail.title")}>
          <Form>
            <FormAutoSelect
              onSelect={(value) =>
                updateModuleStatus("shop.sender_email", value ?? null)
              }
              teamId={currentTeam?.account_id}
              rules={[]}
              handleClear={() => updateModuleStatus("shop.sender_email", null)}
              label=""
              initialValue={settings["shop.sender_email"]}
            />
            <Typography.Text type="secondary">
              {translate("settings.shop.sender_mail.description")}
            </Typography.Text>
          </Form>
        </SettingsItem>
      )}
      <SettingsItem
        title={translate("settings.shop.payment_deadline.title")}
        description={translate("settings.shop.payment_deadline.description")}
      >
        <SingleElementFormV2
          state={
            activeForm && activeForm === "payment_deadline"
              ? "form"
              : settings["shop.payment_deadline"]
              ? "view"
              : "view"
          }
          view={
            <Space size={"middle"}>
              <Input
                value={settings["shop.payment_deadline"]}
                placeholder={translate(
                  "settings.shop.payment_deadline.placeholder"
                )}
                readOnly
                onClick={() => setActiveForm("payment_deadline")}
                style={{
                  width: "100%",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
              />
              {/* </Form.Item> */}
            </Space>
          }
          onClick={() => setActiveForm("payment_deadline")}
          onUpdate={() => {
            setActiveForm(undefined);
          }}
          onCancel={() => setActiveForm(undefined)}
          style={{ paddingLeft: 0 }}
          formProps={{
            async onFinish(value) {
              if (value.payment_deadline) {
                await updateModuleStatus(
                  "shop.payment_deadline",
                  parseInt(value.payment_deadline)
                );
              }
              setActiveForm(undefined);
            },
          }}
        >
          <Form.Item
            name="payment_deadline"
            initialValue={settings["shop.payment_deadline"]}
            style={{ flex: 1, margin: 0 }}
          >
            <Input
              placeholder={translate(
                "settings.shop.payment_deadline.placeholder"
              )}
              type="number"
              style={{
                width: "100%",
                fontSize: "14px",
                borderRadius: "4px",
                minHeight: "25px",
              }}
            />
          </Form.Item>
        </SingleElementFormV2>
      </SettingsItem>
    </SettingsContainer>
  );
};

export default SettingsPage;

import { Divider, Select, SelectProps, Typography, theme } from "antd";
import React from "react";

type Props = {
  label?: string;
} & SelectProps<any>;

const CustomSelect = React.forwardRef<any, Props>(
  ({ label, className, ...props }, ref) => {
    const { token } = theme.useToken();

    return (
      <>
        <style>
          {`
            .custom-select .ant-select-selector {
              font-size: 30px !important;
            }
          `}
        </style>
        <Select
          {...props}
          ref={ref}
          size="large"
          variant="borderless"
          className={`custom-select ${className || ""}`}
        />
        <Divider
          style={{
            borderColor: props.disabled
              ? token.colorTextDisabled
              : token.colorPrimary,
            marginBlock: "5px",
          }}
        />
        {label && (
          <Typography.Text
            disabled={props.disabled}
            style={{
              color: props.disabled
                ? token.colorTextDisabled
                : token.colorPrimary,
            }}
          >
            {label}
          </Typography.Text>
        )}
      </>
    );
  }
);

export default CustomSelect;

import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import {
  CanAccess,
  useDelete,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Col,
  Drawer,
  Flex,
  Form,
  Input,
  InputNumber,
  List,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
// Removed CollapseProps import
import dayjs from "dayjs";
import React, {
  PropsWithChildren,
  useEffect,
  useState, // Removed useCallback and useMemo
} from "react";
import { useParams } from "react-router-dom";
import { Text } from "../../../components";
import SinglePhase from "../../../components/shop/single-phase";
import { useUser } from "../../../contexts/ContextProvider";
import HelpDrawer from "../../../components/common/HelpDrawer";

const SubscriptionProductsShow: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { currentTeam } = useUser();
  const { show } = useNavigation();
  const { id: prodId, subId } = useParams();
  const [useAdvancedEditor, setUseAdvancedEditor] = useState(false);
  const [phasesOptions, setPhasesOptions] = useState<any[]>([]);
  const [openHelp, setOpenHelp] = useState(false);
  const [hasAutomaticRenewal, setHasAutomaticRenewal] =
    useState<boolean>(false);

  const { mutate: deleteMutation } = useDelete();

  const { formProps, form, query, saveButtonProps } = useForm({
    action: "edit",
    redirect: false,
    mutationMode: "pessimistic",
    id: subId,
    meta: {
      select: "*, product_item(type)",
    },
    invalidates: ["all"],
    onMutationSuccess() {
      closeModal();
    },
  });
  const translate = useTranslate();
  const closeModal = () => {
    if (prodId) show("product_items", prodId);
  };
  const { id, phases, currency, created_at, product_item, advanced_settings } =
    query?.data?.data ?? {};
  useEffect(() => {
    form.setFieldValue("phases", phases);
    setPhasesOptions(phases);
    setUseAdvancedEditor(advanced_settings);
  }, [phases]);

  const handlePhaseDelete = (index: number) => {
    const changedPhase = phasesOptions.filter(
      (_item, indexFilter) => index !== indexFilter
    );
    form.setFieldValue("phases", changedPhase);
    setPhasesOptions(changedPhase);
  };

  const handleChangesOnPhases = (updatedPhase, index) => {
    const updatedPhases: any = structuredClone(phasesOptions);

    updatedPhases[index] = { ...updatedPhases[index], ...updatedPhase };

    if (updatedPhase.end && index < updatedPhases.length - 1) {
      updatedPhases[index + 1].start = updatedPhase.end;
    }

    if (updatedPhase.start && index > 0) {
      updatedPhases[index - 1].end = updatedPhase.start;
    }

    // Ensure the first phase's start is always "start"
    if (index === 0 && !updatedPhase.start) {
      updatedPhases[0].start = "start";
    }
    form.setFieldValue("phases", updatedPhases);
    setPhasesOptions(updatedPhases);
  };

  useEffect(() => {
    // Check if any phase has automatic_renewal enabled
    const renewalEnabled = phasesOptions?.some(
      (phase: any) => phase.automatic_renewal !== null
    );
    setHasAutomaticRenewal(renewalEnabled);
  }, [phasesOptions]);

  if (query?.isError) {
    closeModal();
    return null;
  }

  if (query?.isLoading && !currentTeam?.account_id) {
    return (
      <Drawer
        open
        styles={{
          body: {
            background: "#f5f5f5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        className="entityDrawer"
        destroyOnClose={true}
      >
        <Spin />
      </Drawer>
    );
  }

  const handleAddNewPhase = () => {
    const lastPhase: any = phasesOptions[phasesOptions.length - 1];
    let newPhase;
    if (lastPhase) {
      newPhase = {
        ...lastPhase,
        // hidden: false,
        key: lastPhase.key + 1,
        pre_contract: false, // Ensure new phase is not pre_contract
        auto_convert_to_contract: false, // Initialize auto_convert_to_contract as false
        automatic_renewal: null, // Initialize automatic_renewal as null
        start: lastPhase.end, // Set start based on last phase's end
        end: { ...lastPhase.end }, // Initialize end as per backend logic
        products: lastPhase.products.map((item: any) => ({ ...item })), // Deep copy products
      };
    } else {
      newPhase = {
        key: 1,
        name: "Best deal ever!",
        price: 1,
        billing_interval: {
          interval: "month",
          interval_count: 3,
        },
        start: "start",
        end: {
          interval: "day",
          interval_count: 1,
        },
        tax_rate: 0,
        products: [],
        pre_contract: false, // Initialize pre_contract as false
        auto_convert_to_contract: false, // Initialize auto_convert_to_contract as false
        automatic_renewal: null, // Initialize automatic_renewal as null
      };
    }

    const updatedPhases: any = [...phasesOptions, newPhase];
    form.setFieldValue("phases", updatedPhases);
    setPhasesOptions(updatedPhases);
    // form.submit();
  };

  // const handleSwitchToAdvanced = () => {
  //   setUseAdvancedEditor(true);
  // };

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <Drawer
          open
          push={false}
          extra={
            <Button
              type={"link"}
              size={"small"}
              icon={<QuestionCircleOutlined />}
              onClick={() => setOpenHelp(true)}
            />
          }
          onClose={() => closeModal()}
          width={1050}
          title={translate("subscription_products.show.title")}
          footer={
            <Flex justify="space-between" align="center">
              <Text className="ant-text tertiary">
                {translate("subscription_products.fields.created_at")}{" "}
                {dayjs(created_at).format(
                  translate("page.settings.date_format")
                )}{" "}
                {translate("page.settings.clock")}
              </Text>
              <div>
                <Popconfirm
                  title={translate(
                    "subscription_products.buttons.delete.title_single"
                  )}
                  description={translate(
                    "subscription_products.buttons.delete.description_single"
                  )}
                  onConfirm={() => {
                    if (id) {
                      deleteMutation(
                        {
                          id,
                          resource: "subscription_products",
                        },
                        {
                          onSuccess: () => closeModal(),
                        }
                      );
                    }
                  }}
                  okText={translate("buttons.yes", "Yes")}
                  cancelText={translate("buttons.no", "No")}
                >
                  <Button type="link" danger icon={<DeleteOutlined />}>
                    {translate(
                      "subscription_products.buttons.delete.title_single"
                    )}
                  </Button>
                </Popconfirm>
                <Button {...saveButtonProps} type="primary">
                  {translate("buttons.save")}
                </Button>
              </div>
            </Flex>
          }
        >
          <Form
            layout="vertical"
            {...formProps}
            initialValues={{
              ...formProps.initialValues,
              earliest_cancellation_interval: {
                interval_count: formProps?.initialValues
                  ?.earliest_cancellation_interval?.interval_count
                  ? formProps.initialValues.earliest_cancellation_interval
                      .interval_count
                  : 12,
                interval: formProps?.initialValues
                  ?.earliest_cancellation_interval?.interval
                  ? formProps.initialValues.earliest_cancellation_interval
                      .interval
                  : "month",
              },
              cancellation_interval: {
                interval_count: formProps?.initialValues?.cancellation_interval
                  ?.interval_count
                  ? formProps.initialValues.cancellation_interval.interval_count
                  : 12,
                interval: formProps?.initialValues?.cancellation_interval
                  ?.interval
                  ? formProps.initialValues.cancellation_interval.interval
                  : "month",
              },
              currency: "EUR",
              notice_period: {
                interval_count: formProps?.initialValues?.notice_period
                  ?.interval_count
                  ? formProps.initialValues.notice_period.interval_count
                  : 1,
                interval: formProps?.initialValues?.notice_period?.interval
                  ? formProps.initialValues.notice_period.interval
                  : "month",
              },
            }}
            onFinish={(val) => {
              formProps.onFinish &&
                formProps.onFinish({
                  ...val,
                  currency: "EUR",
                  advanced_settings: useAdvancedEditor,
                });
            }}
          >
            <Row gutter={[16, 1]}>
              <Col xs={24} md={12}>
                <Form.Item
                  name={"name"}
                  label={translate("subscription_products.fields.title")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name={"description"}
                  label={translate("subscription_products.fields.description")}
                >
                  <Input />
                </Form.Item>
              </Col>

              {/*<Col xs={24} md={12}>*/}

              {/*  <Form.Item*/}
              {/*    label={translate(*/}
              {/*      "subscription_products.fields.earliest_cancellation_interval"*/}
              {/*    )}*/}
              {/*    required*/}
              {/*  >*/}
              {/*    <Flex gap={20}>*/}
              {/*      <Form.Item*/}
              {/*        name={[*/}
              {/*          "earliest_cancellation_interval",*/}
              {/*          "interval_count",*/}
              {/*        ]}*/}
              {/*        noStyle*/}
              {/*        rules={[*/}
              {/*          {*/}
              {/*            required: true,*/}
              {/*            message: "Please enter the interval count",*/}
              {/*          },*/}
              {/*        ]}*/}
              {/*      >*/}
              {/*        <InputNumber min={1} />*/}
              {/*      </Form.Item>*/}
              {/*      <Form.Item*/}
              {/*        name={["earliest_cancellation_interval", "interval"]}*/}
              {/*        noStyle*/}
              {/*        rules={[*/}
              {/*          {*/}
              {/*            required: true,*/}
              {/*            message: "Please select the interval",*/}
              {/*          },*/}
              {/*        ]}*/}
              {/*      >*/}
              {/*        <Select*/}
              {/*          options={[*/}
              {/*            {*/}
              {/*              label: translate(*/}
              {/*                "subscription_products.fields.billing_type.day"*/}
              {/*              ),*/}
              {/*              value: "day",*/}
              {/*            },*/}
              {/*            {*/}
              {/*              label: translate(*/}
              {/*                "subscription_products.fields.billing_type.week"*/}
              {/*              ),*/}
              {/*              value: "week",*/}
              {/*            },*/}
              {/*            {*/}
              {/*              label: translate(*/}
              {/*                "subscription_products.fields.billing_type.month"*/}
              {/*              ),*/}
              {/*              value: "month",*/}
              {/*            },*/}
              {/*            {*/}
              {/*              label: translate(*/}
              {/*                "subscription_products.fields.billing_type.year"*/}
              {/*              ),*/}
              {/*              value: "year",*/}
              {/*            },*/}
              {/*          ]}*/}
              {/*        />*/}
              {/*      </Form.Item>*/}
              {/*    </Flex>*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}

              {/*<Col xs={24} md={12}>*/}
              {/*  <Form.Item*/}
              {/*    label={translate(*/}
              {/*      "subscription_products.fields.cancellation_interval"*/}
              {/*    )}*/}
              {/*    required*/}
              {/*  >*/}
              {/*    <Flex gap={20}>*/}
              {/*      <Form.Item*/}
              {/*        name={["cancellation_interval", "interval_count"]}*/}
              {/*        noStyle*/}
              {/*        rules={[*/}
              {/*          {*/}
              {/*            required: true,*/}
              {/*            message: "Please enter the interval count",*/}
              {/*          },*/}
              {/*        ]}*/}
              {/*      >*/}
              {/*        <InputNumber min={1} />*/}
              {/*      </Form.Item>*/}
              {/*      <Form.Item*/}
              {/*        name={["cancellation_interval", "interval"]}*/}
              {/*        noStyle*/}
              {/*        rules={[*/}
              {/*          {*/}
              {/*            required: true,*/}
              {/*            message: "Please select the interval",*/}
              {/*          },*/}
              {/*        ]}*/}
              {/*      >*/}
              {/*        <Select*/}
              {/*          options={[*/}
              {/*            {*/}
              {/*              label: translate(*/}
              {/*                "subscription_products.fields.billing_type.day"*/}
              {/*              ),*/}
              {/*              value: "day",*/}
              {/*            },*/}
              {/*            {*/}
              {/*              label: translate(*/}
              {/*                "subscription_products.fields.billing_type.week"*/}
              {/*              ),*/}
              {/*              value: "week",*/}
              {/*            },*/}
              {/*            {*/}
              {/*              label: translate(*/}
              {/*                "subscription_products.fields.billing_type.month"*/}
              {/*              ),*/}
              {/*              value: "month",*/}
              {/*            },*/}
              {/*            {*/}
              {/*              label: translate(*/}
              {/*                "subscription_products.fields.billing_type.year"*/}
              {/*              ),*/}
              {/*              value: "year",*/}
              {/*            },*/}
              {/*          ]}*/}
              {/*        />*/}
              {/*      </Form.Item>*/}
              {/*    </Flex>*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}

              <Col xs={24} md={12}>
                <Form.Item
                  label={translate(
                    "subscription_products.fields.notice_period"
                  )}
                  required
                >
                  <Flex gap={20}>
                    <Form.Item
                      name={["notice_period", "interval_count"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please enter the interval count",
                        },
                      ]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      name={["notice_period", "interval"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please select the interval",
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.day"
                            ),
                            value: "day",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.week"
                            ),
                            value: "week",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.month"
                            ),
                            value: "month",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.year"
                            ),
                            value: "year",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Flex>
                </Form.Item>
              </Col>

              {/*{product_item?.type == "issue" && (*/}
              {/*  <Col xs={24} md={12}>*/}
              {/*    <Form.Item*/}
              {/*      name={"issues"}*/}
              {/*      label={translate("subscription_products.fields.issues")}*/}
              {/*      rules={[{ required: true }]}*/}
              {/*    >*/}
              {/*      <InputNumber />*/}
              {/*    </Form.Item>*/}
              {/*  </Col>*/}
              {/*)}*/}
            </Row>

            <Space
              direction="vertical"
              size={"large"}
              style={{ width: "100%" }}
            >
              {/* whats this for? */}
              <Form.Item name={"phases"} noStyle>
                <Input hidden />
              </Form.Item>

              {/*{!useAdvancedEditor ? (*/}
              {/*  <SinglePhaseEdit*/}
              {/*    data={phases?.[0] || {}}*/}
              {/*    handleChange={(updatedPhase) => {*/}
              {/*      form.setFieldValue("phases", [updatedPhase]);*/}
              {/*    }}*/}
              {/*    onSwitchToAdvanced={handleSwitchToAdvanced}*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  <>*/}

              {/* do we need that headline? */}
              {/*<Flex justify="space-between" align="center">*/}
              {/*  <Typography.Title*/}
              {/*    level={5}*/}
              {/*    style={{*/}
              {/*      fontSize: "1.02em",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {translate("subscription_products.show.phases")}*/}
              {/*  </Typography.Title>*/}
              {/*</Flex>*/}

              <List
                // You can add additional List props here if needed
                dataSource={phasesOptions}
                loading={!phasesOptions}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <List.Item.Meta
                      title={
                        <Flex gap={24} align={"center"} justify="space-between">
                          <Typography.Title level={5} style={{ margin: 0 }}>
                            {translate("product_items.phase_nr") +
                              " " +
                              (index + 1)}
                          </Typography.Title>
                          <Popconfirm
                            key="delete"
                            title={translate("phases.title_single")}
                            description={translate("phases.description_single")}
                            onConfirm={() => handlePhaseDelete(index)}
                            okText={translate("buttons.yes", "Yes")}
                            cancelText={translate("buttons.no", "No")}
                          >
                            <Button
                              type="text"
                              danger
                              icon={<DeleteOutlined />}
                              style={{ fontSize: "0.80rem", padding: 7 }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              {translate(
                                "subscription_products.show.delete_phase"
                              )}
                            </Button>
                          </Popconfirm>
                        </Flex>
                      }
                      description={
                        <SinglePhase
                          handleChange={(phase) =>
                            handleChangesOnPhases(phase, index)
                          }
                          handlePhaseDelete={() => handlePhaseDelete(index)}
                          handlePhaseAdd={handleAddNewPhase} // Pass handleAddNewPhase here
                          data={item}
                          currency={currency}
                          index={index}
                          isIssueBasedSubscription={
                            product_item?.type === "issue"
                          }
                          totalPhases={phasesOptions?.length} // Pass totalPhases prop
                        />
                      }
                    />
                  </List.Item>
                )}
              />
              {/*  </>*/}
              {/*)}*/}
              {/*<Button*/}
              {/*  type="link"*/}
              {/*  onClick={() => setUseAdvancedEditor(!useAdvancedEditor)}*/}
              {/*>*/}
              {/*  {translate(*/}
              {/*    !useAdvancedEditor*/}
              {/*      ? "subscription_products.buttons.switch_to_advanced"*/}
              {/*      : "subscription_products.buttons.hide_advanced"*/}
              {/*  )}*/}
              {/*</Button>*/}

              {!hasAutomaticRenewal && (
                <Button
                  type="link"
                  style={{
                    fontSize: "0.80rem",
                    fontWeight: 500,
                    padding: 7,
                  }}
                  icon={<PlusOutlined />}
                  onClick={handleAddNewPhase}
                >
                  {translate("subscription_products.list.add")}
                </Button>
              )}
            </Space>
          </Form>
        </Drawer>
        {children}
      </>
      <HelpDrawer
        open={openHelp}
        onClose={() => setOpenHelp(false)}
        module="products/subscriptions/show"
      />
    </CanAccess>
  );
};

export default SubscriptionProductsShow;

import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useTranslate } from "@refinedev/core";
import { Button, Input, InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";

import { calculateShippingCostForLineItem } from "../../../utility/calculateShipping";
import { formatNumber } from "../../../utility/formatNumber";
import ProductItemAutoSelect from "../ProductItemAutoSelect";
import TieredPriceInput, { PriceTier } from "../TieredPriceInput";
import { useShippingTableSelect } from "../useShippingTableSelect";
import "./InvoiceTable.css";

const { Option } = Select;

interface InvoiceProductRowProps {
  id: string | number;
  data: any /* your data type here */;
  isEditing: boolean;
  onSave: (updatedRow: any) => void;
  onDelete: (order: number) => void;
  subscriptionProduct?: boolean;
  order: number;
  contactId?: string | number;
  vat_exempt?: boolean;
}

const InvoiceProductRow: React.FC<InvoiceProductRowProps> = ({
  id,
  data,
  isEditing,
  onSave,
  onDelete,
  subscriptionProduct,
  order,
  contactId,
  vat_exempt,
}) => {
  const [shippingCost, setShippingCost] = useState(0);

  const translate = useTranslate();
  const { selectProps } = useShippingTableSelect();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const [priceObject, setPriceObject] = useState<PriceTier[]>(
    data.price_object
      ? [...data.price_object]
      : [{ price: data.price_per_item || 0, quantity: 1 }]
  );
  useEffect(() => {
    const fetchShippingCost = async () => {
      if (data.shipping_tables) {
        const cost = await calculateShippingCostForLineItem(data);
        setShippingCost(cost);
      } else {
        setShippingCost(0);
      }
    };
    fetchShippingCost();
  }, [data.shipping_tables, data.quantity, data.weight]);
  useEffect(() => {
    const newPriceObject = data.price_object
      ? [...data.price_object]
      : [{ price: data.price_per_item || 0, quantity: 1 }];
    setPriceObject(newPriceObject);
  }, [data]);

  // A small helper to compute the correct price based on tiers:
  const getPriceForQuantity = (
    quantity: number,
    tiers: PriceTier[]
  ): number | null => {
    if (!tiers || tiers.length === 0) return 0;
    let applicablePrice = tiers[0].price;
    for (const tier of tiers) {
      if (quantity >= tier.quantity) {
        applicablePrice = tier.price;
      } else {
        break;
      }
    }
    return applicablePrice;
  };

  const computedPrice =
    priceObject && priceObject.length > 0
      ? getPriceForQuantity(data.quantity, priceObject)
      : 0;

  const handleFieldChange = (field: string, value: any) => {
    const updatedRow = { ...data, [field]: value };

    // If user updates the entire array of price tiers
    if (field === "price_object") {
      updatedRow.price_object = [...value]; // ensure new array reference
      const newPrice = getPriceForQuantity(updatedRow.quantity, value);
      updatedRow.price_per_item = newPrice;
    }

    // If user updates 'quantity', recalc price_per_item from the tier
    if (field === "quantity" && priceObject && priceObject.length > 0) {
      const newPrice = getPriceForQuantity(value, priceObject);
      updatedRow.price_per_item = newPrice;
    }

    onSave(updatedRow);
  };

  const handleProductSelect = async (product: any) => {
    const newPriceObject = product.price_object
      ? [...product.price_object]
      : [{ price: product.netto_price || 0, quantity: 1 }];
    const updatedData = {
      id: data.id,
      name: product.name || product.title,
      reference_id: product.id,
      variant: product.variant || null,
      description: product.description || "",
      type: product.type || "item",
      tax_rate: product.sales_tax || 0,
      price_per_item: newPriceObject[0]?.price ?? product?.netto_price,
      quantity: 1,
      unit: "piece",
      discount: 0,
      price_object: newPriceObject,
      shipping_tables: product.shipping_table,
    };
    if (product.id && contactId) {
      try {
        const response = await fetch(
          `${
            import.meta.env.VITE_SUPABASE_URL
          }/functions/v1/shop-core/discount`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${import.meta.env.VITE_SUPABASE_KEY}`,
            },
            body: JSON.stringify({
              products: product.id,
              contact: contactId,
              type: "simple",
            }),
          }
        );
        if (response.ok) {
          const discountData = await response.json();
          if (Array.isArray(discountData) && discountData.length > 0) {
            updatedData.discount = discountData[0].newDiscount;
          }
        }
      } catch (error) {
        console.error("Error fetching discount:", error);
      }
    }
    setPriceObject(newPriceObject);
    onSave(updatedData);
  };
  return (
    <tbody
      ref={!subscriptionProduct ? setNodeRef : null}
      style={style}
      {...attributes}
    >
      <tr className={`invoice-row ${isEditing ? "editing-row" : ""}`}>
        <td style={{ display: "flex", alignItems: "center" }}>
          {order}
          {!subscriptionProduct && (
            <Button
              type="link"
              size="small"
              icon={<MenuOutlined />}
              {...listeners}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </td>
        <td style={{ minWidth: 360 }}>
          {isEditing ? (
            <ProductItemAutoSelect
              initialValue={
                data?.reference_id && data.reference_id !== -1
                  ? data.reference_id
                  : { id: -1, title: data.name || "" }
              }
              onSelect={handleProductSelect}
              subscriptionProduct={subscriptionProduct}
            />
          ) : (
            data.name || "Enter or select"
          )}
        </td>
        <td>
          {isEditing ? (
            <InputNumber
              min={1}
              value={data.quantity}
              className="small-input-number"
              onChange={(value) => handleFieldChange("quantity", value)}
            />
          ) : (
            data.quantity
          )}
        </td>
        <td>
          {isEditing ? (
            <TieredPriceInput
              invoiceView
              currentQuantity={data.quantity}
              initialPrices={priceObject}
              onPricesChange={(newTiers) => {
                setPriceObject(newTiers);
                handleFieldChange("price_object", newTiers);
              }}
            />
          ) : (
            computedPrice
          )}
        </td>

        {!vat_exempt && (
          <td>
            {isEditing ? (
              <Select
                value={data.tax_rate}
                className="small-select"
                style={{ width: "68px" }}
                popupMatchSelectWidth={false}
                onChange={(value) => handleFieldChange("tax_rate", value)}
              >
                <Option value={19}>19%</Option>
                <Option value={7}>7%</Option>
                <Option value={0}>0%</Option>
              </Select>
            ) : (
              `${data.tax_rate}%`
            )}
          </td>
        )}
        <td>
          {isEditing ? (
            <InputNumber
              min={0}
              max={100}
              defaultValue={0}
              value={data.discount}
              suffix={"%"}
              className="small-input-number"
              onChange={(value) => handleFieldChange("discount", value)}
              controls={false}
              decimalSeparator={translate("global.decimal_seperator")}
            />
          ) : (
            `${data.discount}%`
          )}
        </td>
        <td style={{ textAlign: vat_exempt ? "left" : "right" }}>
          {formatNumber(
            data.quantity *
              (priceObject && priceObject.length > 0
                ? getPriceForQuantity(data.quantity, priceObject)
                : data.price_per_item || 0) *
              (1 - data.discount / 100),
            2
          )}{" "}
          €
        </td>
        <td>
          {!subscriptionProduct && (
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(order);
              }}
            />
          )}
        </td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={3} className="description-row">
          {isEditing ? (
            <Input
              value={data.description}
              onChange={(e) => handleFieldChange("description", e.target.value)}
              placeholder="Beschreibung"
            />
          ) : (
            data.description
          )}
        </td>
        <td colSpan={2}>
          <Select
            placeholder={"Portotabelle wählen"}
            value={data.shipping_tables}
            {...selectProps}
            onChange={(e) => handleFieldChange("shipping_tables", e)}
            allowClear
            style={{ width: "100%" }}
          />
        </td>
        <td colSpan={1} style={{ textAlign: "right" }}>
          {formatNumber(shippingCost)} €
        </td>
      </tr>
    </tbody>
  );
};

export default InvoiceProductRow;

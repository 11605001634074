import { useTranslate } from "@refinedev/core";
import { Button, Input, message, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { supabaseClient } from "../../utility/supabaseClient";

export const TwoFactorSetup: React.FC = () => {
  // Tracks whether 2FA is currently enabled
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  // Whether we have an unverified TOTP factor
  const [isUnverified, setIsUnverified] = useState(false);
  // Loading states
  const [isEnabling, setIsEnabling] = useState(false);

  // TOTP states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [factorId, setFactorId] = useState<string | null>(null);
  const [challengeId, setChallengeId] = useState<string | null>(null);
  const [totpCode, setTotpCode] = useState("");

  const translate = useTranslate();

  // 1. On mount, see if the user has TOTP factors
  useEffect(() => {
    checkTOTPFactors();
  }, []);

  const checkTOTPFactors = async () => {
    try {
      const { data, error } = await supabaseClient.auth.mfa.listFactors();
      if (error) {
        console.error("Error listing factors:", error);
        return;
      }

      // Look for a TOTP factor
      const totpFactor = data?.all?.find((f) => f.factor_type === "totp");
      if (totpFactor) {
        setFactorId(totpFactor.id);

        if (totpFactor.status === "verified") {
          // Fully verified TOTP
          setIs2FAEnabled(true);
          setIsUnverified(false);
        } else {
          // Found an unverified TOTP factor
          setIs2FAEnabled(false);
          setIsUnverified(true);
        }
      } else {
        // no TOTP factor
        setFactorId(null);
        setIs2FAEnabled(false);
        setIsUnverified(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // 2. Toggle Switch
  const onToggle2FA = async (checked: boolean) => {
    if (checked) {
      await handleEnroll2FA();
    } else {
      // Turn OFF 2FA
      await handleDisable2FA();
    }
  };

  // 3. Enroll a new TOTP factor
  const handleEnroll2FA = async () => {
    try {
      setIsEnabling(true);
      const { data: enrollData, error: enrollError } =
        await supabaseClient.auth.mfa.enroll({
          factorType: "totp",
        });
      if (enrollError) throw enrollError;

      const newFactorId = enrollData?.id;
      const qr = enrollData?.totp?.qr_code || enrollData?.totp?.uri;
      setQrCodeUrl(qr || null);
      setFactorId(newFactorId);

      // This new factor is unverified
      setIsUnverified(true);

      // Challenge the factor so we can verify
      await challengeFactor(newFactorId);
    } catch (err: any) {
      console.error("Failed to enable TOTP:", err.message);
      // revert switch if fail
      setIs2FAEnabled(false);
      setIsUnverified(false);
    } finally {
      setIsEnabling(false);
    }
  };

  // 4. Shared factor challenge method
  const challengeFactor = async (factId: string) => {
    const { data: challengeData, error: challengeError } =
      await supabaseClient.auth.mfa.challenge({ factorId: factId });
    if (challengeError) throw challengeError;

    setChallengeId(challengeData?.id ?? null);
    setIsModalOpen(true);
  };

  // 5. Verify TOTP code
  const handleVerify2FA = async () => {
    if (!factorId || !challengeId) {
      console.error("Missing factorId or challengeId");
      return;
    }
    try {
      const { error } = await supabaseClient.auth.mfa.verify({
        factorId,
        challengeId,
        code: totpCode,
      });
      if (error) throw error;

      // success
      setIsUnverified(false);
      setIs2FAEnabled(true);
      message.success(translate("account.2fa.enabled_message"));
      setIsModalOpen(false);
    } catch (err: any) {
      console.error("Failed to verify code:", err.message);
      message.warning(translate("account.2fa.invalid_code"));
    }
  };

  // 6. Disable TOTP by unenrolling
  const handleDisable2FA = async () => {
    if (!factorId) {
      // If we have no factorId, there's no factor to unenroll
      setIs2FAEnabled(false);
      setIsUnverified(false);
      return;
    }
    try {
      const { error } = await supabaseClient.auth.mfa.unenroll({ factorId });
      if (error) throw error;

      // cleared
      setFactorId(null);
      setIs2FAEnabled(false);
      setIsUnverified(false);
      message.info(translate("settings.disabled_2FA"));
    } catch (err: any) {
      console.error("Failed to disable 2FA:", err.message);
      setIs2FAEnabled(true);
    }
  };

  // If user closes the modal w/o verifying
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ margin: "20px 0" }}>
      <h2>{translate("settings.2FA_TOTP")}</h2>
      <Switch
        checked={is2FAEnabled || isUnverified}
        loading={isEnabling}
        onChange={onToggle2FA}
      />
      <span style={{ marginLeft: 8 }}>
        {is2FAEnabled
          ? "Enabled"
          : isUnverified
          ? translate("global.pending_verification")
          : translate("global.disabled")}
      </span>
      {!is2FAEnabled && isUnverified && (
        <Button
          style={{ marginInline: 5 }}
          onClick={() => setIsModalOpen(true)}
          size="small"
          type="link"
        >
          {translate("actions.verify")}
        </Button>
      )}

      <Modal
        title={translate("settings.enable_2FA")}
        open={isModalOpen}
        footer={null}
        onCancel={handleModalClose}
      >
        {qrCodeUrl && (
          <>
            <p>{translate("account.2fa.scan_qr_authenticator")}</p>
            <img src={qrCodeUrl} style={{ height: 200 }} alt="TOTP QR" />
          </>
        )}

        <p style={{ marginTop: 16 }}>{translate("account.2fa.enter_digit")}</p>
        <Input
          style={{ width: 120, marginRight: 8 }}
          placeholder="123-456"
          onChange={(e) => setTotpCode(e.target.value.trim())}
        />
        <Button type="primary" onClick={handleVerify2FA} disabled={!totpCode}>
          {translate("actions.verify")}
        </Button>
      </Modal>
    </div>
  );
};

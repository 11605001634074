import { Button, Popover, Typography } from "antd";
import React from "react";

interface PopoverConfirmationProps {
  title: string;
  content: string;
  confirmLabel: string;
  onConfirm: () => void;
  visible: boolean;
  onVisibilityChange: (visible: boolean) => void;
  buttonType?: "link" | "default" | "text" | "primary" | "dashed" | undefined;
  danger?: boolean;
  disable?: boolean;
  activeButton?: boolean;
}

const PopoverConfirmation: React.FC<PopoverConfirmationProps> = ({
  title,
  content,
  confirmLabel,
  onConfirm,
  visible,
  onVisibilityChange,
  buttonType = "link",
  danger = true,
  disable,
  activeButton = false,
}) => (
  <Popover
    content={
      <div>
        <Typography.Text>{content}</Typography.Text>
        <div style={{ marginTop: 16 }}>
          <Button
            type="primary"
            danger={danger}
            onClick={onConfirm}
            disabled={disable}
            loading={activeButton}
            style={disable ? { opacity: 0.5, pointerEvents: "none" } : {}}
          >
            {confirmLabel}
          </Button>
        </div>
      </div>
    }
    title={<>{title}</>}
    trigger="click"
    open={visible}
    onOpenChange={onVisibilityChange}
  >
    <Button
      type={buttonType}
      danger={danger}
      disabled={disable}
      loading={activeButton}
    >
      {title}
    </Button>
  </Popover>
);

export default PopoverConfirmation;

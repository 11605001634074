import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import {
  useCreate,
  useDelete,
  useGetIdentity,
  useList,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { numberToCurrency } from "../../utility";
import ContactAddress from "../contacts/ContactAddress";
import ContactDisplay from "./ContactDisplay";
import EditInvoice from "./EditInvoice";
import FileDisplay from "./FileDisplay";
import InvoiceFooter from "./FooterPart";
import LineItemDisplay from "./LineItemTable";

export function ShowInvoice({
  contactId,
  invoiceId,
  onClose,
  setSelectedInvoiceId,
}: {
  contactId: any;
  invoiceId: any;
  onClose: any;
  setSelectedInvoiceId?: (id) => void;
}) {
  const [mailOutbox, setMailOutbox] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>();
  const { id: prodId } = useParams();
  const { tableQuery: invoiceData, setFilters } = useTable({
    resource: "invoices",
    pagination: {
      mode: "off",
    },
    filters: {
      permanent: [
        //{ field: "contact", value: contactId, operator: "eq" },
        { field: "id", value: invoiceId, operator: "eq" },
      ],
    },
    syncWithLocation: false,
    meta: {
      select:
        "*,contact(id,email_invoices, type, firstname, lastname, company_name, email ,invoice_postal_mail)",
    },
  });
  const invoice = invoiceData?.data?.data[0];
  const { data: mailOutboxData, isLoading: isMailOutboxLoading } = useList({
    resource: "mail_outbox",
    filters: invoice?.id
      ? [
          {
            operator: "or",
            value: [
              { field: "mail_outbox_type", operator: "eq", value: "invoice" },
              { field: "mail_outbox_type", operator: "eq", value: "dunning" },
            ],
          },
          { field: "mail_outbox_id", operator: "eq", value: invoice.id },
        ]
      : [],
    pagination: { mode: "off" },
    queryOptions: { enabled: !!invoice?.id },
  });

  useEffect(() => {
    if (invoice && mailOutboxData?.data) {
      setMailOutbox(mailOutboxData.data);
    }
  }, [invoice, mailOutboxData]);
  console.log("mail", mailOutbox);
  const translate = useTranslate();

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleEditClick = () => {
    setIsEditModalVisible(true);
  };

  const closeEditModal = () => {
    setIsEditModalVisible(false);
  };
  const { mutateAsync: createInvoice, isLoading: createLoading } = useCreate();
  const { mutateAsync: updateInvoice, isLoading: updateLoading } = useUpdate();
  const { mutateAsync: deleteInvoice, isLoading: deleteLoading } = useDelete();
  const contact = invoice?.contact;

  const lineItems = invoice?.line_items || [];
  const handleDelete = async () => {
    if (invoice?.id) {
      await deleteInvoice({ resource: "invoices", id: invoice?.id });
    }
  };
  const handleClose = async () => {
    await updateInvoice({
      resource: "invoices",
      id: invoice?.id,
      values: { status: "finished" },
    });
  };
  const handleChangeToUnfinish = async () => {
    await updateInvoice({
      resource: "invoices",
      id: invoice?.id,
      values: { status: "unfinished" },
    });
  };
  const { data: me } = useGetIdentity<{ id: string }>();
  const handleDuplicateInvoice = async () => {
    const { data: newInvoice } = await createInvoice({
      resource: "invoices",
      values: {
        ...invoice,
        contact: invoice?.contact?.id,
        status: "unfinished",
        id: undefined,
        integration_target_id: null,
        integration_target: null,
        invoice_number: null,
        amount_paid: 0,
        created_at: undefined,
        date: selectedDate || invoice?.date,
        sepa_xml_file: null,
        created_by: me?.id,
      },
      invalidates: ["list"],
    });
    setSelectedDate(undefined);
    setSelectedInvoiceId?.(newInvoice.id);
  };
  if (!contact) {
    return <></>;
  }
  if (invoiceData?.isLoading) {
    return <Spin />;
  }
  const statusColors: Record<string, string> = {
    unfinished: "red",
    finished: "green",
    unpaid: "orange",
    overdue: "red",
    paid: "green",
    canceled: "grey",
    partially_paid: "blue",
  };

  return (
    <>
      <Modal
        open={!!invoiceId}
        onClose={onClose}
        onCancel={onClose}
        centered
        title={
          <>
            {translate("billing_details.title")}
            {invoice?.invoice_number && <> {invoice?.invoice_number}</>}
          </>
        }
        width={"70%"}
        footer={
          <>
            {invoice?.status == "unfinished" && (
              <Space>
                <Popconfirm
                  title={translate("billing_details.confirmation.delete_title")}
                  description={translate(
                    "billing_details.confirmation.delete_description"
                  )}
                  onConfirm={handleDelete}
                  okText={
                    <>
                      {translate("billing_details.confirmation.ok")} -{" "}
                      {translate("billing_details.confirmation.delete_title")}
                    </>
                  }
                  okButtonProps={{ danger: true }}
                  cancelText={translate("billing_details.confirmation.cancel")}
                >
                  <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    loading={deleteLoading}
                  >
                    {translate("billing_details.buttons.delete")}
                  </Button>
                </Popconfirm>

                <Button
                  type="default"
                  onClick={handleEditClick}
                  icon={<EditOutlined />}
                >
                  {translate("settings.invoices.edit_invoice")}
                </Button>

                <Popconfirm
                  title={translate("billing_details.confirmation.finish_title")}
                  description={translate(
                    "billing_details.confirmation.finish_description"
                  )}
                  onConfirm={handleClose}
                  okText={
                    <>
                      {translate("billing_details.confirmation.ok")} -{" "}
                      {translate("billing_details.confirmation.finish_title")}
                    </>
                  }
                  cancelText={translate("billing_details.confirmation.cancel")}
                >
                  <Button
                    type="primary"
                    loading={updateLoading}
                    icon={<DownloadOutlined />}
                  >
                    {translate("billing_details.buttons.finish")}
                  </Button>
                </Popconfirm>
              </Space>
            )}
            {invoice?.invoice_number == null &&
              invoice?.status == "finished" && (
                <Space>
                  <Popconfirm
                    title={translate(
                      "billing_details.confirmation.unfinish_title"
                    )}
                    description={translate(
                      "billing_details.confirmation.unfinish_description"
                    )}
                    onConfirm={handleChangeToUnfinish}
                    okText={
                      <>
                        {translate("billing_details.confirmation.ok")} -{" "}
                        {translate(
                          "billing_details.confirmation.unfinish_title"
                        )}
                      </>
                    }
                    cancelText={translate(
                      "billing_details.confirmation.cancel"
                    )}
                  >
                    <Button loading={updateLoading}>
                      {translate("billing_details.buttons.unfinish")}
                    </Button>
                  </Popconfirm>
                </Space>
              )}
            {invoice?.status == "canceled" && (
              <Space>
                <Popconfirm
                  title={translate(
                    "billing_details.confirmation.duplicate_title"
                  )}
                  description={
                    <>
                      {translate(
                        "billing_details.confirmation.duplicate_description"
                      )}
                      <Form.Item
                        label={translate(
                          "settings.invoices.fields.invoice_date"
                        )}
                      >
                        <DatePicker
                          defaultValue={
                            invoice.date && dayjs(new Date(invoice.date))
                          }
                          onChange={(date) => {
                            setSelectedDate(
                              dayjs(date).utc(true).toISOString()
                            );
                          }}
                        />
                      </Form.Item>
                    </>
                  }
                  onConfirm={handleDuplicateInvoice}
                  okText={<>{translate("billing_details.confirmation.ok")}</>}
                  cancelText={translate("billing_details.confirmation.cancel")}
                >
                  <Button loading={createLoading}>
                    {translate("billing_details.buttons.duplicate")}
                  </Button>
                </Popconfirm>
              </Space>
            )}
          </>
        }
      >
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          <Descriptions layout="vertical">
            <Descriptions.Item label={translate("billing_details.recipient")}>
              <Space style={{ cursor: "pointer" }} direction={"vertical"}>
                <Space direction={"vertical"}>
                  <ContactDisplay
                    label={translate("settings.contact")}
                    contact={invoice?.contact_name || "No contact provided"}
                  />
                  {invoice?.contact_person_name && (
                    <Typography>{invoice?.contact_person_name}</Typography>
                  )}

                  <ContactAddress contactAddress={invoice?.contact_address} />
                  <Typography>{invoice?.contact_email}</Typography>
                </Space>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("billing_details.status_label")}
            >
              {translate(`billing_details.status.${invoice?.status}`)}
            </Descriptions.Item>

            <Descriptions.Item label={translate("billing_details.paid_label")}>
              <Space align={"center"} size={"middle"}>
                <Typography.Title level={5} style={{ marginTop: 6 }}>
                  {numberToCurrency(invoice?.amount_paid || 0)}{" "}
                  <Typography.Text type={"secondary"}>
                    von {numberToCurrency(invoice?.amount || 0)}
                  </Typography.Text>
                </Typography.Title>
                <Progress
                  size={70}
                  type="circle"
                  strokeWidth={12}
                  percent={(invoice?.amount_paid / invoice?.amount) * 100}
                  format={(percent) => `${percent?.toFixed(0)}%`}
                  status="active"
                  strokeColor={statusColors[invoice?.status] || "blue"}
                />
              </Space>
            </Descriptions.Item>
          </Descriptions>

          <Row gutter={16}>
            <Col span={10}>
              <Tabs
                defaultActiveKey="invoice"
                items={[
                  {
                    key: "invoice",
                    label: translate("commerce.invoice"),
                    children: (
                      <FileDisplay
                        label={translate("files.files")}
                        invoice={invoice}
                        fileType="invoice"
                      />
                    ),
                  },
                  ...(invoice?.dunnings?.length > 0
                    ? [
                        {
                          key: "dunnings",
                          label: translate("settings.shop.dunnings_text.title"),
                          children: (
                            <FileDisplay
                              invoice={invoice}
                              fileType="dunning"
                              label="Dunning Files"
                            />
                          ),
                        },
                      ]
                    : []),
                ]}
              ></Tabs>
            </Col>
            <Col span={14}>
              <Card>
                <LineItemDisplay lineItems={lineItems} />
                <InvoiceFooter
                  taxAmounts={invoice?.tax_amounts || []}
                  totalAmount={invoice?.amount}
                  shippingData={invoice?.shipping}
                />
              </Card>
            </Col>
          </Row>

          {invoiceData.isLoading || isMailOutboxLoading ? (
            <Spin />
          ) : (
            <>
              {mailOutbox.map((item) => (
                <Card key={item.id}>
                  <Space direction={"vertical"} size={"middle"}>
                    <Typography.Text strong>
                      {translate(
                        `billing_details.mail_outbox.type.${item?.type}`
                      )}{" "}
                      <Tag>
                        {translate(
                          `billing_details.mail_outbox.mail_outbox_type.${item?.mail_outbox_type}`
                        )}
                      </Tag>
                      :
                    </Typography.Text>
                    <Row>
                      <Col span={12}>
                        <Descriptions column={1}>
                          <Descriptions.Item
                            label={translate("billing_details.mail_outbox.to")}
                          >
                            <>{item?.type == "email" && item.to}</>
                          </Descriptions.Item>

                          {item?.status == "sent" && (
                            <Descriptions.Item
                              label={translate(
                                "billing_details.mail_outbox.sent_at"
                              )}
                            >
                              {dayjs(item.sent_at).format(
                                translate("global.date_time_format")
                              )}
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      </Col>
                      <Col span={12}>
                        <Descriptions column={1}>
                          <Descriptions.Item
                            label={translate(
                              "billing_details.mail_outbox.status"
                            )}
                          >
                            {translate(
                              `billing_details.mail.status.${
                                item?.status ?? ""
                              }`
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={translate(
                              "billing_details.mail_outbox.delivered"
                            )}
                          >
                            {item?.delivered ? (
                              <>-</>
                            ) : (
                              <>
                                {translate(
                                  "billing_details.mail_outbox.no_delivery_status"
                                )}
                              </>
                            )}
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                  </Space>
                </Card>
              ))}
            </>
          )}
        </Space>
      </Modal>

      {isEditModalVisible && invoice && (
        <EditInvoice
          open={isEditModalVisible}
          invoice={invoice}
          onClose={closeEditModal}
        />
      )}
    </>
  );
}

export default ShowInvoice;

import { useModalForm } from "@refinedev/antd";
import { Button, Form, Input, Select, Spin } from "antd";
import Modal from "antd/es/modal/Modal";
import { supabaseClient } from "../../../utility";
import { useUser } from "../../../contexts/ContextProvider";
import { useNotification, useTranslate } from "@refinedev/core";
import { getUserInviteErrorMessage } from "../../../utility/errorMessages";
import { MailOutlined } from "@ant-design/icons";
import { useState } from "react";
import i18n from "../../../i18n";

type Props = {
  open?: boolean;
  close?: () => void;
  invitations?: any[];
};
export default function InviteMemberModal({ open, close, invitations }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { currentTeam } = useUser();
  const translate = useTranslate();
  const { open: notify } = useNotification();

  const { modalProps, formProps } = useModalForm({
    action: "create",
    resource: "",
  });
  const handleSubmit = async (values: any) => {
    try {
      if (
        !invitations?.some((invitation) => invitation.email == values.email)
      ) {
        const { error } = await supabaseClient.functions.invoke(
          "invite-new-member",
          {
            body: {
              ...values,
              language: i18n.language,
              account_id: currentTeam?.account_id,
            },
          }
        );
        if (error) {
          throw error;
        }
      }

      notify?.({
        description: "Success",
        message: "User invited successfully",
        type: "success",
      });

      formProps.onFinish?.({});
      close?.();
    } catch (error: any) {
      notify?.({
        message: translate("alerts.submitError.title"),
        description: getUserInviteErrorMessage(translate, error.context.status),
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      {...modalProps}
      open={open}
      onCancel={() => {
        close?.();
      }}
      onClose={() => {
        close?.();
      }}
      style={{ maxWidth: 500 }}
      footer={() => (
        <Button
          {...modalProps.okButtonProps}
          onClick={(event) => {
            setIsLoading(true);
            modalProps.okButtonProps?.onClick?.(event);
          }}
          icon={<MailOutlined />}
          loading={isLoading}
        >
          {translate("teams.send_invitation")}
        </Button>
      )}
      title={translate("teams.invite_member")}
    >
      <Form {...formProps} onFinish={handleSubmit} layout="vertical">
        <Form.Item name={"name"} label={translate("teams.members.name")}>
          <Input />
        </Form.Item>
        <Form.Item
          name={"email"}
          label={translate("teams.members.email")}
          rules={[{ type: "email", required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"account_role"}
          label={translate("teams.members.account_role")}
          rules={[{ required: true }]}
          initialValue={"member"}
        >
          <Select
            options={[
              { label: translate("teams.members.owner"), value: "owner" },
              { label: translate("teams.members.member"), value: "member" },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

import React from "react";
import { Typography, Switch, Row, Col, Space, Flex } from "antd";

const { Text } = Typography;

interface SettingsItemProps {
  title: string;
  description?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  children?: React.ReactNode;
  labelWidth?: number;
  descriptionOnLabel?: boolean;
}

const SettingsItem: React.FC<SettingsItemProps> = (props) => {
  const {
    title,
    description,
    checked,
    onChange,
    disabled,
    children,
    descriptionOnLabel,
  } = props;

  const labelWidth = props.labelWidth || 8;
  const inputWidth = 24 - labelWidth;

  const isCheckedPropSet = Object.prototype.hasOwnProperty.call(
    props,
    "checked"
  );
  return (
    <>
      <Row
        gutter={24}
        style={{
          marginBottom: "24px",
          marginTop: "24px",
          flex: 1,
          alignItems: "start",
        }}
      >
        <Col span={labelWidth}>
          <Flex vertical gap={8}>
            <Text strong>{title}</Text>
          </Flex>
        </Col>
        <Col span={inputWidth}>
          <Space
            direction={"vertical"}
            size={"small"}
            style={{ width: "100%" }}
          >
            {/* Render switch if checked prop is set */}
            {isCheckedPropSet && (
              <>
                <Switch
                  disabled={disabled}
                  checked={checked}
                  onChange={onChange}
                />
              </>
            )}

            {children}

            {description && !descriptionOnLabel && (
              <Text type="secondary">{description}</Text>
            )}
          </Space>
        </Col>
        <Col span={labelWidth}>
          {description && descriptionOnLabel && (
            <Text type="secondary">{description}</Text>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SettingsItem;

// hooks/useSubscriptionProductList.ts
import { useEffect, useState } from "react";
import { useUser } from "../contexts/ContextProvider";
import { IPhase } from "../interfaces/phase.interface";
import { supabaseClient } from "../utility";

type SubscriptionProduct = {
  id: string | number;
  name: string;
  description?: string;
  phases?: IPhase[];
  account: string;
  product_item?: {
    title?: string;
  };
};

type ReturnType = {
  options: { label: string; value: string | number }[];
  rawData: SubscriptionProduct[];
  isLoading: boolean;
  error: any;
};

export const useSubscriptionProductList = (searchTerm?: string): ReturnType => {
  const { currentTeam } = useUser();
  const [options, setOptions] = useState<{ label: string; value: any }[]>([]);
  const [rawData, setRawData] = useState<SubscriptionProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const { data, error } = await supabaseClient.rpc(
          "search_subscription_products",
          {
            p_account: currentTeam?.account_id,
            p_search: searchTerm ?? "",
          }
        );

        if (error) throw error;
        const mappedOptions =
          data?.map((item: SubscriptionProduct) => ({
            label: `${item?.product_item?.title} - ${item.name}`,
            value: item.id,
          })) ?? [];

        setOptions(mappedOptions);
        setRawData(data ?? []);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    // Only fetch if we have an account ID
    if (currentTeam?.account_id) {
      fetchData();
    } else {
      // reset if no account
      setOptions([]);
      setRawData([]);
    }
  }, [currentTeam?.account_id, searchTerm]);

  return {
    options,
    rawData,
    isLoading,
    error,
  };
};

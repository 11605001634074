import { useDeleteForJunctionTableMany as deleteMany } from "../hooks/useDeleteForJunctionTable";

export const handleCategoriesUpdate = async (
  values,
  categoriesWithPath,
  params,
  currentTeam,
  createMany
) => {
  const valuesToDelete = categoriesWithPath
    ?.filter((item) => !values.categories.includes(item.id))
    .map((category) => ({
      product_category: category.id,
      product_item: Number(params?.id),
    }));

  const valuesToAdd = values.categories
    ?.filter((item) => !categoriesWithPath.map((cat) => cat.id).includes(item))
    .map((category) => ({
      product_category: category,
      product_item: params?.id,
      account: currentTeam?.account_id,
    }));

  if (valuesToDelete?.length) {
    await deleteMany("product_item_has_product_category", valuesToDelete);
  }

  if (valuesToAdd?.length) {
    await createMany({
      resource: "product_item_has_product_category",
      values: valuesToAdd,
      successNotification: false,
    });
  }
};

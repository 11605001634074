import dayjs from "dayjs";
import { getContactFormattedName } from "./contactName";

export const exportToCSV = (
  filename: string,
  headers: string[],
  data: Record<string, any>[]
) => {
  const csvRows: string[] = [];
  csvRows.push(headers.join(","));
  data.forEach((row) => {
    const values: string[] = headers.map((header) => {
      const escaped = ("" + (row[header] || "")).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  });
  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

type DataRow = {
  contact_phone: string;
  contact_email: string;
  amount_shipping: string;
  amount_paid: string;
  amount: string;
  invoice_number: string;
  order_date: string;
  company_name: string;
  last_name: string;
  first_name: string;
  id?: string;
  title?: string;
  netto_price?: string;
  sales_tax?: string;
  weight?: string;
  ean?: string;
  name?: string;
  email?: string;
  phone?: string;
  company?: string;
  order_number?: string;
  date?: string;
  total_amount?: string;
  status?: string;
};
export const handleExportCSV = (
  type: "products" | "contacts" | "orders" | "payments" | "invoices",
  dataList: { data?: DataRow[] } | any,
  currentLanguage: string,
  columns: any
) => {
  let csvData: any[] = [];
  let fileName = "";
  const headers = columns
    .map((col: any) => col.dataIndex ?? col.key)
    .filter(Boolean);
  const getUniqueOrderCount = (
    contractorOrders,
    deliveryOrders,
    contactPersonOrders
  ) => {
    const allOrderNumbers = [
      ...(contractorOrders?.map((order) => order.order_number) || []),
      ...(deliveryOrders?.map((order) => order.order_number) || []),
      ...(contactPersonOrders?.map((order) => order.order_number) || []),
    ];

    const uniqueOrderNumbers = [...new Set(allOrderNumbers)];

    return uniqueOrderNumbers.length;
  };
  switch (type) {
    case "invoices": {
      const columnKeys = columns
        .map((col) => {
          if (col.dataIndex) {
            return Array.isArray(col.dataIndex)
              ? col.dataIndex.join(".")
              : col.dataIndex;
          }
          return col.key || null;
        })
        .filter(Boolean);
      csvData = (dataList?.data?.data ?? []).map((row) => {
        const filteredRow: Record<string, any> = {};
        columnKeys.forEach((key) => {
          let value = row;
          const keys = key.split(".");
          for (const k of keys) {
            value = value?.[k];
          }
          switch (key) {
            case "date":
              filteredRow["date"] = value ?? "";
              break;
            case "invoice_number":
              filteredRow["invoice_number"] = value ?? "";
              break;
            case "amount":
              filteredRow["amount"] = row?.amount;
              break;
            case "status":
              filteredRow["status"] = row?.status;
              break;
            case "invoice_contact":
              break;

            default:
              filteredRow[key] = value ?? "";
          }
        });

        return filteredRow;
      });

      fileName =
        currentLanguage === "en" ? "invoices_export" : "abrechnungen_export";
      break;
    }
    case "products":
      csvData = (dataList?.data ?? []).map((row) => ({
        id: row.id || "",
        title: row.title || "",
        description: row.description || "",
        netto_price: row.netto_price || "",
        sales_tax: row.sales_tax || "",
        price_object: Array.isArray(row.price_object)
          ? row.price_object[0]?.price ?? ""
          : "",
        weight: row.weight || "",
        ean: row.ean || "",
      }));
      fileName =
        currentLanguage === "en" ? "products_export" : "produkte_export";
      break;

    case "contacts": {
      const columnKeys = columns
        .map((col) => {
          if (col.dataIndex) {
            return Array.isArray(col.dataIndex)
              ? col.dataIndex.join(".")
              : col.dataIndex;
          }
          return col.key || null;
        })
        .filter(Boolean);

      csvData = (dataList?.data ?? []).map((row) => {
        const filteredRow: Record<string, any> = {};
        columnKeys.forEach((key) => {
          let value = row;
          const keys = key.split(".");

          for (const k of keys) {
            value = value?.[k];
          }

          switch (key) {
            case "type":
              filteredRow[key] = row.type ?? "";
              break;
            case "name":
              filteredRow[key] = getContactFormattedName(row);
              break;
            case "orders":
              filteredRow[key] = getUniqueOrderCount(
                row.contractor_orders,
                row.delivery_orders,
                row.contact_person_orders
              );
              break;

            case "deals":
              filteredRow[key] = row?.pipeline_items_count[0]?.count ?? "";
              break;

            case "tags.id":
              filteredRow[key] =
                row.tags?.map((tag: any) => tag.name).join(", ") ?? "";
              break;
          }
        });

        return filteredRow;
      });

      fileName =
        currentLanguage === "en" ? "contacts_export" : "kontakte_export";
      break;
    }

    case "orders": {
      const columnKeys = columns
        .map((col) => {
          if (col.dataIndex) {
            return Array.isArray(col.dataIndex)
              ? col.dataIndex.join(".")
              : col.dataIndex;
          }
          return col.key || null;
        })
        .filter(Boolean);

      csvData = (dataList?.data ?? []).map((row) => {
        const filteredRow: Record<string, any> = {};

        columnKeys.forEach((key) => {
          let value = row;
          const keys = key.split(".");

          for (const k of keys) {
            value = value?.[k];
          }

          switch (key) {
            case "status.label":
              filteredRow["status"] = value ?? "";
              break;
            case "invoice_contact":
              filteredRow["invoice_contact"] = getContactFormattedName(
                row?.invoice_contact
              );
              break;
            case "product":
              filteredRow["product"] = row?.product?.title;
              break;
            case "subscription_product":
              filteredRow["subscription_product"] =
                row?.subscription_product?.name;
              break;
            case "calculated_total":
              filteredRow["calculated_total"] = row?.total?.total;
              break;
            default:
              filteredRow[key] = value ?? "";
          }
        });

        return filteredRow;
      });

      fileName =
        currentLanguage === "en" ? "orders_export" : "bestellungen_export";
      break;
    }

    case "payments": {
      const dateFormat = currentLanguage === "en" ? "DD/MM/YYYY" : "DD.MM.YYYY";

      csvData = (dataList?.data ?? []).map((row) => ({
        id: row.id || "",
        type: row.type || "",
        date: row.date ? dayjs(row.date).format(dateFormat) : "",
        amount: row.amount || "",
        invoice: row.invoice.invoice_number || "",
      }));
      fileName =
        currentLanguage === "en" ? "payments_export" : "zahlungen_export";
      break;
    }

    default:
      console.log("Invalid type for CSV export.");
      return;
  }

  if (csvData.length > 0) {
    exportToCSV(fileName, headers, csvData);
  } else {
    console.log("No data available for CSV export.");
  }
};

import { supabaseClient } from "./supabaseClient";

export const calculateShippingCostForLineItem = async (lineItem) => {
  // If no shipping table is assigned, no shipping cost is calculated.
  if (!lineItem.shipping_tables) return 0;

  // Fetch the shipping table details using supabaseClient
  const { data: shippingTable, error: tableError } = await supabaseClient
    .from("shipping_tables")
    .select("*, shipping_rates!inner(*)")
    .eq("id", lineItem.shipping_tables)
    .order("lower_limit", {
      referencedTable: "shipping_rates",
      ascending: false,
    })
    .single();
  if (tableError || !shippingTable) {
    console.error("Shipping table not found:", tableError);
    return 0;
  }

  // Fetch the shipping rates for this table, sorted by the lower_limit (ascending)
  const shippingRates = shippingTable.shipping_rates;

  if (!shippingRates || shippingRates.length === 0) {
    console.error("Shipping rates not found:");
    return 0;
  }
  // Calculate cost based on table type
  if (shippingTable.type === "count") {
    // Billing based on quantity: find the first rate where quantity <= lower_limit

    const rate = shippingRates.find((rate) => {
      return lineItem.quantity >= rate.lower_limit;
    });
    return rate ? rate.cost : shippingRates[shippingRates.length - 1].cost;
  } else if (shippingTable.type === "weight") {
    const { data: product_item, error: ratesError } = await supabaseClient
      .from("product_items")
      .select("*")
      .eq("id", lineItem.reference_id)
      .single();
    if (ratesError || !product_item || product_item.length === 0) {
      console.error("Product not found:", ratesError);
      return 0;
    }
    const totalWeight = product_item.weight
      ? product_item.weight * lineItem.quantity
      : 0;

    const rate = shippingRates.find((rate) => totalWeight <= rate.lower_limit);

    return rate ? rate.cost : shippingRates[shippingRates.length - 1].cost;
  }
  return 0;
};

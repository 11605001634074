import { message } from "antd";
import type { NotificationProvider } from "@refinedev/core";

export const messageNotificationProvider: NotificationProvider = {
  open: ({ type, message: msg }) => {
    if (!msg) return; // block empty messages
    if (type === "success") {
      //message.success(msg);
    } else if (type === "error") {
      message.error(msg);
    } else if (type === "progress") {
      message.loading(msg);
    }
  },
  close: () => {
    message.destroy();
  },
};
import React, { useState, useEffect } from 'react';
import {Drawer, Spin, Result, Flex} from 'antd';

/**
 * HelpDrawer component for displaying external help content
 *
 * @param {Object} props - Component props
 * @param {boolean} props.open - Controls whether the drawer is open
 * @param {Function} props.onClose - Callback function triggered when the drawer closes
 * @param {string} props.module - Module string that identifies the help content to be loaded
 * @param {string} [props.title='Hilfe'] - Optional title for the drawer
 * @param {number} [props.width=600] - Optional width of the drawer
 * @returns {JSX.Element} HelpDrawer component
 */
const HelpDrawer = ({
                      open,
                      onClose,
                      module,
                      title = 'Hilfe',
                      width = 800
                    }) => {
  const [content, setContent] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const baseUrl = 'https://tellie.io/app-help/';

  useEffect(() => {
    // Only load content when the drawer is open and a module is specified
    if (open && module) {
      setLoading(true);
      setError(null);

      fetch(`${baseUrl}${module}`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.text();
        })
        .then(data => {
          setContent(data);
          setLoading(false);
        })
        .catch(err => {
          console.error('Fehler beim Laden:', err);
          setError('Die angeforderte Hilfeseite kann leider nicht angezeigt werden.');
          setLoading(false);
        });
    }

    // Reset content when the drawer is closed
    if (!open) {
      setContent(null);
    }
  }, [open, module]);

  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spin size="large" />
        </div>
      );
    }

    if (error) {
      return (
        <Result
          status="warning"
          title="Fehler"
          subTitle={
            <Flex vertical gap={24}>
              <div>{error}</div>
              <div style={{ color: "#cccccc", fontFamily: "monospace", fontSize: "0.86em" }}>{module}</div>
            </Flex>
          }
        />
      );
    }

    if (content) {
      return (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      );
    }

    return null;
  };

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      open={open}
      width={width}
      destroyOnClose={true}
    >
      {renderContent()}
    </Drawer>
  );
};

export default HelpDrawer;
import { ApartmentOutlined } from "@ant-design/icons";
import { List } from "@refinedev/antd";
import {
  BaseRecord,
  CanAccess,
  HttpError,
  useNavigation,
  useTable,
  useTranslate,
} from "@refinedev/core";

import { Table } from "antd";
import { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import { useUser } from "../../contexts/ContextProvider";
import CanAccessFallback from "../../components/common/CanAccessFallback";

export default function PipelineOverview() {
  // We explicitly declare dataSource is an array of BaseRecord
  const [dataSource, setDataSource] = useState<BaseRecord[]>([]);
  const { currentTeam } = useUser();
  const { push } = useNavigation();
  const translate = useTranslate();

  const { tableQuery } = useTable<BaseRecord, HttpError>({
    resource: "pipelines",
    pagination: { mode: "off" },
    filters: {
      permanent: [
        {
          field: "account",
          operator: "eq",
          value:
            currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        },
      ],
    },
    sorters: {
      initial: [{ field: "created_at", order: "asc" }],
    },
  });

  useEffect(() => {
    if (tableQuery.data?.data) {
      setDataSource(tableQuery.data.data);
    }
  }, [tableQuery.data]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
  ];

  const handleRowClick = (record: BaseRecord) => {
    if (record?.id) {
      push(`show/${record.id}`);
    }
  };
  const breadcrumbItems = [
    {
      icon: <ApartmentOutlined />,
      title: translate("pipelines.pipelines"),
    },
  ];

  return (
    <CanAccess fallback={<CanAccessFallback module="pipeline" />}>
      <List title={<CustomBreadcrumb items={breadcrumbItems} />}>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey="uuid"
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            style: { cursor: "pointer" },
          })}
        />
      </List>
    </CanAccess>
  );
}

import { EditButton, Show } from "@refinedev/antd";
import { useShow, useTranslate, useUpdate } from "@refinedev/core";
import { Col, Flex, Row, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { numberToCurrency } from "../../../utility";
import {
  currentPhase,
  getProductsFrom,
  showProductsJson,
} from "../../../utility/shop";
import DividerTitle from "../../common/DividerTitle";
import HorizontalItemDisplay from "../../common/HorizontalItemDisplay";
import ContactDetails from "../../contacts/ContactDetails";
import ShowInvoice from "../../invoices/ShowInvoice";
import IssuesTable from "../issues/IssuesTable";
import EditOrder from "./EditOrder";
import ListInvoices from "./ListInvoices";
import OrderTitle from "./OrderTitle";

interface OrderTitleProps {
  orderId?: number;
  onClose: () => void;
}

const OrderMainView: React.FC<OrderTitleProps> = ({ orderId, onClose }) => {
  const [showInvoice, setShowInvoice] = useState<any>();
  const [editOrder, setEditOrder] = useState<boolean>(false);
  const translate = useTranslate();
  const { mutate } = useUpdate();

  const { query } = useShow({
    resource: "orders",
    id: orderId || undefined,
    meta: {
      select:
        "*, " +
        "invoice_contact(*, phone_numbers(*)), " +
        "invoice_contact_address(*), " +
        "contact_person_id(*), " +
        "delivery_contact(*), " +
        "delivery_contact_address(*), " +
        "product(type), " +
        "invoices(*, parent_invoice:parent(*)), " +
        "product_items(*), " +
        "forecasts(*)?order=date_start.asc",
    },
  });

  const { data, isLoading, isError } = query;

  const order = data?.data ?? {};

  const { forecasts = [] } = order;
  const nextForecast = forecasts[0];

  const phase = currentPhase(order?.components);
  const subscriptionProductTableData = showProductsJson(phase, translate);

  const handleUnsuspend = () => {
    mutate(
      {
        resource: "orders", // Passen Sie den Ressourcennamen an
        id: order?.id, // ID des Datensatzes
        values: { suspended: false }, // Die neuen Werte
      },
      {
        onSuccess: () => {
          // console.log("Erfolgreich aktualisiert!");
        },
        onError: (error) => {
          console.error("Fehler:", error);
        },
      }
    );
  };

  const subscriptionProductTableColumns = [
    {
      title: translate("product_items.show.title"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: translate("product_items.show.billing_frequency"),
      dataIndex: "billing_interval",
      key: "price",
    },
    {
      title: translate("payments.amount"),
      dataIndex: "price",
      key: "price",
    },
  ];
  return (
    <>
      <Show
        title={
          <OrderTitle
            type={order?.type}
            product_items={order?.product_items}
            name={order?.name}
            suspended={order?.suspended}
            handleUnsuspend={handleUnsuspend}
            order_number={order?.order_number}
            source_id={order?.source_id}
          />
        }
        headerProps={{ onBack: () => onClose() }}
        breadcrumb={false}
        headerButtons={({ editButtonProps }) => (
          <>
            <Space size={"large"}>
              {order?.status && (
                <Space>
                  <span>{translate("billing_details.status_label")}: </span>
                  <Typography.Text
                    strong
                    style={{
                      color:
                        order?.status == "active"
                          ? "green"
                          : order?.status == "terminated"
                          ? "red"
                          : "orange",
                    }}
                  >
                    {translate(`subscriptions.status.${order?.status}`)}
                  </Typography.Text>
                </Space>
              )}
              {editButtonProps && (
                <EditButton
                  {...editButtonProps}
                  onClick={() => setEditOrder(true)}
                />
              )}
            </Space>
          </>
        )}
      >
        <Row gutter={64}>
          <Col span={16}>
            <HorizontalItemDisplay
              title={translate("orders.show.title")}
              columns={2}
              items={[
                {
                  label: translate("settings.invoices.invoice_contact"),
                  value: (
                    <>
                      <ContactDetails
                        contact={order?.invoice_contact}
                        address={order?.invoice_contact_address}
                        contact_person={order?.contact_person_id}
                      />
                      <Typography>{order?.invoice_contact?.email}</Typography>{" "}
                    </>
                  ),
                },
                {
                  label: translate("subscriptions.fields.delivery_contact"),
                  value: (
                    <ContactDetails
                      contact={order?.delivery_contact}
                      address={order?.delivery_contact_address}
                    />
                  ),
                  // onClick: () => setShowContact(order?.delivery_contact?.id),
                  // onEdit: () => setDeliveryModalOpen(true),
                },
                {
                  label: translate(`subscriptions.fields.order_date`),
                  value: dayjs(order?.order_date).format(
                    translate("global.date_format")
                  ),
                },
                {
                  label: translate(`subscriptions.fields.execution_date`),
                  value: dayjs(order?.start_date).format(
                    translate("global.date_format")
                  ),
                },
                ...(order?.type == "simple" && order?.shipping_type
                  ? [
                      {
                        label: translate(
                          `subscriptions.fields.execution_date_${order?.shipping_type}`
                        ),
                        value: (
                          <div>
                            {order?.shipping_type == "service" ||
                              (order?.shipping_type == "delivery" && (
                                <>
                                  {dayjs(order?.start_date).format(
                                    translate("global.date_format")
                                  )}
                                </>
                              ))}
                          </div>
                        ),
                      },
                    ]
                  : []),
                ...(order?.type == "subscription"
                  ? [
                      {
                        label: translate(`orders.show.current_phase`),
                        value: (
                          <div>
                            {phase?.start_date && phase?.end_date && (
                              <>
                                {dayjs(phase?.start_date).format(
                                  translate("global.date_format")
                                )}{" "}
                                -{" "}
                                {dayjs(phase?.end_date).format(
                                  translate("global.date_format")
                                )}
                              </>
                            )}
                            {phase?.start_date && !phase?.end_date && (
                              <>
                                {dayjs(phase?.start_date).format(
                                  translate("global.date_format")
                                )}
                              </>
                            )}
                          </div>
                        ),
                      },
                      ...(order?.status == "canceled"
                        ? [
                            {
                              label: translate("subscriptions.status.canceled"),
                              value: dayjs(phase?.end_date).format(
                                translate("global.date_format")
                              ),
                            },
                          ]
                        : []),
                      ...(order?.status == "terminated"
                        ? [
                            {
                              label: translate("subscriptions.status.canceled"),
                              value: translate(
                                "subscriptions.status.terminated_info"
                              ),
                            },
                          ]
                        : []),
                      ...(["canceled", "terminated"].includes(order?.status)
                        ? [
                            {
                              label: translate("orders.termination_date"),
                              value: dayjs(order?.next_notice_date).format(
                                translate("page.settings.date_format")
                              ),
                            },
                          ]
                        : []),
                    ]
                  : []),
                ...(order?.type == "subscription" &&
                !["terminated", "canceled"].includes(order?.status)
                  ? [
                      {
                        label: translate("orders.billed_until"),
                        value:
                          order?.billed_until > order?.start_date
                            ? dayjs(order?.billed_until).format(
                                translate("page.settings.date_format")
                              )
                            : "-",
                      },
                      {
                        label: translate("orders.show.next_invoice"),
                        value: nextForecast?.date_start ? (
                          <Space>
                            {dayjs(nextForecast.date_start).format(
                              translate("page.settings.date_format")
                            )}
                            {numberToCurrency(nextForecast.amount)}
                          </Space>
                        ) : (
                          "-"
                        ),
                      },
                      ...(order?.product?.type == "issue"
                        ? [
                            {
                              label: translate(
                                "subscriptions.issues_left.title"
                              ),
                              value: order?.issues_left || 0,
                            },
                          ]
                        : []),
                    ]
                  : []),
                {
                  label: translate("subscriptions.fields.note"),
                  value: order?.note || "-",
                },
              ]}
            />

            <DividerTitle title={translate("orders.show.positions")} />

            {order?.type == "subscription" && (
              <Flex vertical gap={16}>
                <Table
                  scroll={{ x: "max-content" }}
                  dataSource={subscriptionProductTableData}
                  columns={subscriptionProductTableColumns}
                  pagination={false}
                />
              </Flex>
            )}

            {order?.type == "simple" && (
              <Space direction={"vertical"}>
                {getProductsFrom(phase)
                  ?.products?.map((product) => product.name)
                  .join(", ")}
              </Space>
            )}

            <Space
              direction="vertical"
              size={"large"}
              style={{ width: "100%" }}
            >
              {order?.product?.type == "issue" && order?.id && (
                <>
                  <DividerTitle
                    title={translate("subscriptions.issues_left.transactions")}
                  />
                  <IssuesTable
                    orderId={order?.id}
                    totalIssuesLeft={order?.issues_left ?? 0}
                    contact_id={order?.invoice_contact.id}
                    components={order?.components}
                  />
                </>
              )}
            </Space>
          </Col>

          <Col span={8}>
            <DividerTitle title={translate("commerce.invoices")} />
            <ListInvoices invoices={order?.invoices} onClick={setShowInvoice} />
          </Col>
        </Row>
      </Show>

      {/*<OrdersShowInvoice*/}
      {/*  order={order}*/}
      {/*  showInvoice={showInvoice}*/}
      {/*  onClose={() => setShowInvoice(undefined)}*/}
      {/*/>*/}

      {showInvoice?.id}
      <ShowInvoice
        contactId={order?.invoice_contact?.id}
        invoiceId={showInvoice?.id}
        onClose={() => setShowInvoice(null)}
        setSelectedInvoiceId={setShowInvoice}
      />

      <EditOrder
        order={order}
        visible={editOrder}
        onClose={() => setEditOrder(false)}
      />
    </>
  );
};

export default OrderMainView;

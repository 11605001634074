import {
  ArrowRightOutlined,
  PlusOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import {
  Button,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Result,
  Select,
  Space,
  Typography,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useState } from "react";
import { getContactFormattedName } from "../../../utility/contactName";
import AddressAutoselect from "../../common/AddressAutoselect";
import ContactAutoSelect from "../../contacts/ContactAutoSelect";
import ContactAutoSelectForConnection from "../../contacts/ContactAutoSelectForConnection";
import InvoiceTable from "../product-items";
import SinglePhase from "../single-phase";
import SubscriptionPhaseShowStartEnd from "./SubscriptionPhaseShowStartEnd";
dayjs.extend(utc);

interface EditOrderProps {
  order: any;
  visible: boolean;
  onClose: () => void;
}

const EditOrder: React.FC<EditOrderProps> = ({ order, visible, onClose }) => {
  const translate = useTranslate();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [dangerZone, setDangerZone] = useState(false);

  const { formProps, form } = useForm({
    action: "edit",
    resource: "orders",
    id: order?.id,
    redirect: false,
    onMutationSuccess: async (updatedData) => {
      form.setFieldsValue(updatedData.data);
      setDangerZone(false);
      onClose();
    },
    invalidates: ["all"],
    mutationMode: "pessimistic",
  });

  const deliveryContact = Form.useWatch("delivery_contact", form);

  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      setConfirmVisible(true);
    } else {
      setDangerZone(false);
      onClose();
    }
  };

  const handlePhaseDelete = (index: number) => {
    const updatedPhases = order?.components.filter(
      (_item, idx) => idx !== index
    );
    form.setFieldValue("components", updatedPhases);

    // Recalculate contract dates when deleting a phase
    const contractDates = calculateContractDates(updatedPhases);
    if (contractDates) {
      form.setFieldValue("contract_end_date", contractDates.contract_end_date);
      form.setFieldValue("next_notice_date", contractDates.next_notice_date);
    }

    form.submit();
  };

  const calculateContractDates = (phases) => {
    // Find the last phase to determine contract end date
    if (!phases || phases.length === 0) return null;

    // Sort phases by end_date (if available) or start_date
    const sortedPhases = [...phases].sort((a, b) => {
      const aDate = a.end_date
        ? dayjs.utc(a.end_date)
        : dayjs.utc(a.start_date);
      const bDate = b.end_date
        ? dayjs.utc(b.end_date)
        : dayjs.utc(b.start_date);
      return bDate.diff(aDate); // Latest date first
    });

    const lastPhase = sortedPhases[0];

    // Contract end date is the end date of the last phase
    const contractEndDate = lastPhase.end_date
      ? dayjs.utc(lastPhase.end_date).toISOString()
      : null;

    // Use notice_period from the order table (JSONB column)
    let nextNoticeDate: any = null;

    if (contractEndDate && order?.notice_period) {
      const { interval, interval_count } = order.notice_period;
      const contractEndMoment = dayjs.utc(contractEndDate);
      // Subtract the notice period from the contract end date
      nextNoticeDate = contractEndMoment
        .subtract(interval_count, interval)
        .add(1, "day")
        .toISOString();
    }

    return {
      contract_end_date: contractEndDate,
      next_notice_date: nextNoticeDate,
    };
  };

  const handleChangesOnPhases = (phase: any, index: number) => {
    const updatedPhases = order?.components.map((item: any, idx: number) =>
      idx === index ? phase : item
    );
    form.setFieldValue("components", updatedPhases);

    // Recalculate contract dates when phases change
    const contractDates = calculateContractDates(updatedPhases);
    if (contractDates) {
      form.setFieldValue("contract_end_date", contractDates.contract_end_date);
      form.setFieldValue("next_notice_date", contractDates.next_notice_date);
    }
  };

  const handleAddNewPhase = () => {
    const lastPhase = order?.components[order?.components?.length - 1];
    const newPhase = lastPhase
      ? {
          ...lastPhase,
          key: lastPhase.key + 1,
          start: lastPhase.start,
          end: lastPhase.end,
          billing_interval: { ...lastPhase.billing_interval },
          products: lastPhase?.products?.map((item: any) => ({ ...item })),
        }
      : {
          key: 1,
          billing_interval: { interval: "month", interval_count: 3 },
          start: dayjs().format(translate("global.date_format")),
          end: dayjs().format(translate("global.date_format")),
          products: [],
        };

    const updatedPhases = [...order.components, newPhase];
    form.setFieldValue("components", updatedPhases);

    // Recalculate contract dates when adding a new phase
    const contractDates = calculateContractDates(updatedPhases);
    if (contractDates) {
      form.setFieldValue("contract_end_date", contractDates.contract_end_date);
      form.setFieldValue("next_notice_date", contractDates.next_notice_date);
    }

    form.submit();
  };

  const watchedStatus = useWatch("status", form);

  const statusItems = [
    ...(order?.status === "upcoming"
      ? [
          {
            value: "upcoming",
            label: translate("subscriptions.status.upcoming"),
          },
        ]
      : []),
    {
      value: "active",
      label: translate("subscriptions.status.active"),
      disabled: ["upcoming", "terminated"].includes(order?.status),
    },
    {
      value: "canceled",
      label: translate("subscriptions.status.canceled"),
      disabled: ["upcoming", "terminated"].includes(order?.status),
    },
    {
      value: "terminated",
      label: translate("subscriptions.status.terminated"),
      disabled: ["upcoming"].includes(order?.status),
    },
  ];

  // Function to format dates for display when danger zone is not enabled
  const formatPhaseDate = (dateString) => {
    if (!dateString) return "-";
    return dayjs(dateString).format(translate("global.date_format"));
  };
  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        ...formProps.initialValues,
        invoice_contact_address: formProps.initialValues
          ?.invoice_contact_address
          ? String(formProps.initialValues?.invoice_contact_address || "")
          : undefined,
        delivery_contact_address: formProps.initialValues
          ?.delivery_contact_address
          ? String(formProps.initialValues?.delivery_contact_address || "")
          : undefined,
      }}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      labelAlign="left"
    >
      <Modal
        title={translate("orders.edit_single_order")}
        width={"70%"}
        style={{ minWidth: "900px", maxWidth: "1200px" }}
        open={visible}
        onCancel={handleCancel}
        onOk={() => form.submit()}
      >
        <Form.Item label={translate("settings.invoices.invoice_contact")}>
          <Space>
            <Typography.Text strong>
              {getContactFormattedName(order.invoice_contact)}
            </Typography.Text>
            <Typography.Text type={"secondary"}>
              ({translate("billing_details.recipient")})
            </Typography.Text>
          </Space>
        </Form.Item>

        <Form.Item
          label={
            <>
              <Flex gap={8} align={"center"} justify={"flex-end"}>
                <span
                  style={{
                    display: "block",
                    borderRadius: 4,
                    width: 18,
                    height: 18,
                    backgroundColor:
                      watchedStatus === "active"
                        ? "green"
                        : watchedStatus === "terminated"
                        ? "red"
                        : "orange",
                  }}
                />
                {translate("billing_details.status_label")}
              </Flex>
            </>
          }
          extra={translate(`subscriptions.status.${watchedStatus}_info`)}
          name={"status"}
        >
          <Select
            options={statusItems}
            onChange={(value) => form.setFieldValue("status", value)}
          />
        </Form.Item>

        <ContactAutoSelectForConnection
          contactId={order?.invoice_contact?.id}
          showConnected={true}
          name={"contact_person_id"}
          label={translate("contacts.contact_persons")}
          onSelect={(value) =>
            form.setFieldValue("contact_person_id", value || null)
          }
          required={false}
        />
        <AddressAutoselect
          contactId={order?.invoice_contact?.id}
          name={"invoice_contact_address"}
          label={translate("subscriptions.fields.invoice_contact_address")}
          onSelect={(value) =>
            form.setFieldValue("invoice_contact_address", value || null)
          }
          required={false}
        />
        <Divider />
        <ContactAutoSelect
          //key={order?.delivery_contact}
          label={translate("subscriptions.fields.delivery_contact")}
          name={"delivery_contact"}
          initialValue={order?.delivery_contact?.id}
          required={false}
          onSelect={(value) => {
            form.setFieldValue("delivery_contact_address", null);
            form.setFieldValue("delivery_contact", value || null);
          }}
        />
        <AddressAutoselect
          contactId={deliveryContact || 0}
          //key={order?.delivery_contact_address}
          label={translate("orders.fields.delivery_contact_address")}
          name="delivery_contact_address"
          required={false}
          onSelect={(value) =>
            form.setFieldValue("delivery_contact_address", value || null)
          }
        />
        <Divider />

        <Form.Item
          label={translate("subscriptions.fields.note")}
          name="note"
          extra={translate("subscriptions.fields.note_extra")}
        >
          <Input />
        </Form.Item>
        {!dangerZone ? (
          <Form.Item
            label={translate("orders.danger_settings")}
            extra={translate("orders.danger_settings_description")}
          >
            <Popconfirm
              overlayStyle={{ width: 420 }}
              icon={<WarningOutlined style={{ color: "red" }} />}
              okButtonProps={{ danger: true }}
              title={translate("orders.danger_settings")}
              description={translate("orders.danger_settings_confirm")}
              okText={translate("buttons.yes")}
              cancelText={translate("buttons.cancel")}
              onConfirm={() => setDangerZone(true)}
            >
              <Button style={{ paddingInline: 0 }} type={"link"}>
                {translate("orders.enable_danger_settings")}
              </Button>
            </Popconfirm>
          </Form.Item>
        ) : (
          <>
            {order?.start_date &&
              dayjs(order.start_date).isAfter(dayjs(), "day") && (
                <Form.Item
                  label={<>{translate("orders.fields.start_date")}</>}
                  name={"start_date"}
                  getValueProps={(value) => ({
                    value: value ? dayjs(value).utc() : null,
                  })}
                  normalize={(value) =>
                    value ? dayjs(value).utc().toISOString() : null
                  }
                >
                  <DatePicker
                    format={{ format: translate("global.date_format") }}
                  />
                </Form.Item>
              )}

            <Form.Item
              label={<>{translate("orders.billed_until")}</>}
              name={"billed_until"}
              getValueProps={(value) => ({
                value: value ? dayjs(value).utc() : null,
              })}
              normalize={(value) =>
                value ? dayjs(value).utc().toISOString() : null
              }
            >
              <DatePicker format={translate("global.date_format")} />
            </Form.Item>
          </>
        )}

        {order.type == "simple" && (
          <InvoiceTable
            initialValues={order?.components?.products}
            initialShipping={order?.components?.shipping}
          />
        )}
        {order.type == "subscription" && (
          <>
            <List
              dataSource={order?.components}
              renderItem={(phase: any, index: number) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    title={
                      dangerZone ? (
                        <SubscriptionPhaseShowStartEnd
                          key={index}
                          item={phase}
                          index={index}
                          onDateChange={(idx, field, value) => {
                            // Create a deep copy to ensure all properties are properly copied
                            const updatedPhase = JSON.parse(
                              JSON.stringify(phase)
                            );
                            updatedPhase[field] = value;
                            handleChangesOnPhases(updatedPhase, idx);
                          }}
                        />
                      ) : (
                        <Flex align="center" gap={16}>
                          <Typography.Title level={5} style={{ margin: 0 }}>
                            {formatPhaseDate(phase.start_date)}
                          </Typography.Title>
                          <ArrowRightOutlined style={{ fontSize: 14 }} />
                          <Typography.Title level={5} style={{ margin: 0 }}>
                            {phase.end_date
                              ? formatPhaseDate(phase.end_date)
                              : "-"}
                          </Typography.Title>
                        </Flex>
                      )
                    }
                    description={
                      <SinglePhase
                        handleChange={(current) =>
                          handleChangesOnPhases(current, index)
                        }
                        handlePhaseDelete={
                          dangerZone
                            ? () => handlePhaseDelete(index)
                            : undefined
                        }
                        data={phase}
                        totalPhases={order?.components?.length}
                        index={index}
                        isSubscription
                      />
                    }
                  />
                  <Form.Item name="components">
                    <Input hidden />
                  </Form.Item>
                  <Form.Item name="contract_end_date">
                    <Input hidden />
                  </Form.Item>
                  <Form.Item name="next_notice_date">
                    <Input hidden />
                  </Form.Item>
                </List.Item>
              )}
            />

            {dangerZone && (
              <Button
                type="link"
                icon={<PlusOutlined />}
                onClick={handleAddNewPhase}
                style={{ fontSize: "0.80rem", fontWeight: 500, padding: 7 }}
              >
                {translate("subscription_products.list.add")}
              </Button>
            )}
          </>
        )}
      </Modal>

      <Modal
        open={confirmVisible}
        onOk={() => {
          setConfirmVisible(false);
          form.resetFields();
          onClose();
        }}
        onCancel={() => {
          setConfirmVisible(false);
        }}
        okText="Ja"
        cancelText="Nein"
        okButtonProps={{ danger: true }}
      >
        <Result status="warning" title={translate("warnWhenUnsavedChanges")} />
      </Modal>
    </Form>
  );
};

export default EditOrder;

import React, { PropsWithChildren } from "react";
import { useUser } from "../../contexts/ContextProvider";
import SettingsContainer from "./components/SettingsContainer";
import SettingsItem from "./components/SettingsItem";
import { useSettingsLogic } from "./hooks/useSettingsLogic";

const CustomerNumberSettings: React.FC<PropsWithChildren> = ({ children }) => {
  const { currentTeam } = useUser();
  const { settings, updateModuleStatus, translate } = useSettingsLogic({
    modulePath: "contacts",
  });
  return (
    <>
      <SettingsContainer
        modulePath="contacts"
        title={translate("settings.shop.title")}
        description={translate("settings.integrations.description", "")}
        alwaysActive
      >
        <SettingsItem
          title={translate(
            "settings.contacts.customer_number.lexware_office_title"
          )}
          description={translate(
            "settings.contacts.customer_number.lexware_office_description"
          )}
          checked={settings["contacts.lexware_office_customer_number"]}
          onChange={() =>
            updateModuleStatus(
              "contacts.lexware_office_customer_number",
              !settings["contacts.lexware_office_customer_number"]
            )
          }
        />
        <SettingsItem
          title={translate("settings.contacts.customer_number.internal_title")}
          description={translate(
            "settings.contacts.customer_number.internal_description"
          )}
          checked={settings["contacts.internal_customer_number"]}
          onChange={() =>
            updateModuleStatus(
              "contacts.internal_customer_number",
              !settings["contacts.internal_customer_number"]
            )
          }
        />
      </SettingsContainer>
      {children}
    </>
  );
};

export default CustomerNumberSettings;

import { MailOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Button, Space, Typography } from "antd";
import { useState } from "react";
import ResendDocumentModal from "./ResendDocumentModal";

const DocumentResendButton: React.FC<{
  document: any; // Invoice or Dunning
  type: "invoice" | "dunning";
}> = ({ document, type }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const translate = useTranslate();

  return (
    <>
      <Button type="default" onClick={() => setModalVisible(true)}>
        <Space>
          <MailOutlined />
          <Typography.Text>
            {translate(
              `settings.${type === "invoice" ? "invoices" : "dunnings"}.resend`
            )}
          </Typography.Text>
        </Space>
      </Button>
      {modalVisible && (
        <ResendDocumentModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          document={document}
          type={type}
        />
      )}
    </>
  );
};

export default DocumentResendButton;

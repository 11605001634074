import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useForm } from "@refinedev/antd";
import {
  useGetIdentity,
  useGetLocale,
  useList,
  useNotification,
  useSetLocale,
  useTranslate,
  useUpdatePassword,
} from "@refinedev/core";
import {
  Avatar,
  Button,
  Col,
  Flex,
  Form,
  Grid,
  InputRef,
  MenuProps,
  Row,
  Select,
  Space,
  Steps,
  Typography,
} from "antd";
import { StepProps } from "antd/lib";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Images } from "../../components/Images";
import CustomInput from "../../components/form/customInput";
import CustomSelect from "../../components/form/customInput/select";
import useTeam from "../../hooks/useTeam";
import useTeamFn from "../../hooks/useTeamFn";
import { supabaseClient } from "../../utility";
import styles from "./style.module.css";

const countryCodes = [
  "DE",
  "AT",
  "CH",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
];

const getCountryOptions = () => {
  return countryCodes.map((country) => ({
    value: country,
    label: i18n.t(`countries.${country}`), // Nutzung der `useTranslate`-Funktion hier
  }));
};

const AccountSetup: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [fnameHasValue, setFnameHasValue] = useState(false);
  const [lnameHasValue, setLnameHasValue] = useState(false);
  const [cnameHasValue, setCnameHasValue] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const { personalAccount, updateTeam, allTeams } = useTeam();
  const { mutate: updatePassword } = useUpdatePassword<{
    password: string;
    redirectTo?: { path?: string };
  }>();
  const hasTeam = useMemo(
    () => allTeams?.some((team) => !team.personal_account),
    [allTeams]
  );
  const { createTeam } = useTeamFn();

  async function handleTeamCreate(
    company: string,
    email: string,
    firstname: string,
    lastname: string,
    website: string,
    phone_number: string,
    country: string
  ) {
    await createTeam({
      name: company,
    });

    const { data, error } = await supabaseClient.functions.invoke(
      "api-v1/update-pipeline-on-user-account-setup",
      {
        method: "POST",
        body: {
          record: {
            email,
            company,
            firstname,
            lastname,
            website,
            phone_number,
            country: i18n.t(`countries.${country}`),
          },
        },
      }
    );

    if (error) {
      console.error("Error invoking Edge Function:", error);
    } else {
      console.log("Successfully updated pipeline item:", data);
    }

    navigate("/");
  }
  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.sm === "undefined" ? false : !breakpoint.sm;
  const translate = useTranslate();
  const navigate = useNavigate();
  const { open } = useNotification();
  const { formProps, form } = useForm({
    redirect: false,
    autoSave: {
      enabled: false,
    },

    warnWhenUnsavedChanges: false,
    mutationMode: "pessimistic",
  });
  const { data: me } = useGetIdentity<any>();
  const { data: invitations } = useList({
    resource: "user_invitations",
    meta: { schema: "basejump" },
    filters: [{ field: "email", value: me?.email, operator: "eq" }],
  });
  const logedWithProvider = useMemo(
    () => ["azure", "github"].includes(me?.app_metadata?.provider),
    [me?.app_metadata?.provider]
  );

  const country = Form.useWatch("country", form);
  const password = Form.useWatch("password", form);
  const confirmPassword = Form.useWatch("confirmPassword", form);

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const getLocale = useGetLocale();
  const currentLocale = getLocale();
  const changeLanguage = useSetLocale();
  const { i18n } = useTranslation();

  const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => changeLanguage(lang),
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
      label:
        lang === "en"
          ? translate("page.settings.english")
          : translate("page.settings.german"),
    }));

  const firstnameRef = useRef<InputRef>(null);
  const companyNameRef = useRef<InputRef>(null);
  const websiteRef = useRef<HTMLDivElement>(null);
  const countryRef = useRef<any>(null);
  const passwordRef = useRef<InputRef>(null);
  const nextButton0 = useRef<HTMLButtonElement>(null);
  const nextButton1 = useRef<HTMLButtonElement>(null);
  const nextButton2 = useRef<HTMLButtonElement>(null);
  const nextButton3 = useRef<HTMLButtonElement>(null);
  const nextButton4 = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (currentStep === 0) {
      firstnameRef.current?.focus();
    } else if (currentStep === 1) {
      companyNameRef.current?.focus();
    } else if (currentStep === 2) {
      websiteRef.current?.focus();
    } else if (currentStep === 3) {
      passwordRef.current?.focus();
    }
  }, [currentStep]);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (currentStep === 0) nextButton0.current?.click();
        if (currentStep === 1) nextButton1.current?.click();
        if (currentStep === 2) nextButton2.current?.click();
        if (currentStep === 3) nextButton3.current?.click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentStep]);

  const ojbSelectLang = menuItems.map((item: any) => {
    return { label: item.label, value: item.key };
  });
  const handleSaveAccount = async () => {
    const formInput = form.getFieldsValue(true);
    if (formInput) {
      const data = await updateTeam(personalAccount?.account_id, {
        name: `${formInput.firstname} ${formInput.lastname}`,
        public_metadata: {
          ...personalAccount.metadata,
          firstname: formInput.firstname,
          lastname: formInput.lastname,
          country: formInput.country,
          details: { company_name: formInput.company },
          initialSetup: true,
          website_url: formInput.website_url,
          phone_number: formInput.phone_number,
        },
      });
      if (data?.account_id) {
        if (!logedWithProvider) {
          updatePassword(
            {
              password: formInput.password,
              redirectTo: {},
            },
            {
              onSuccess: (succData) => {
                if (succData.success) {
                  setAccountCreated(true);
                }
              },
            }
          );
        }

        // eslint-disable-next-line no-extra-boolean-cast
        !!invitations?.data?.length
          ? navigate("/team/invitations")
          : hasTeam
          ? navigate("/team/create")
          : await handleTeamCreate(
              data?.metadata?.details?.company_name,
              me?.email,
              formInput.firstname,
              formInput.lastname,
              formInput.website_url,
              formInput.phone_number,
              formInput.country
            );
      }
    }
  };

  const content: {
    title: ReactNode;
    description: ReactNode;
    button: ReactNode;
  }[] = [
    {
      title: (
        <Typography.Text style={{ fontWeight: "500", marginBottom: 20 }}>
          {translate("account.your_full_name") + ":"}
        </Typography.Text>
      ),
      description: (
        <>
          <Flex gap={12} vertical={isMobile}>
            <Form.Item
              name={"firstname"}
              rules={[
                {
                  required: true,
                  message: translate("validation.field_required"),
                },
              ]}
            >
              <CustomInput
                placeholder={`${translate("users.firstname")}*`}
                disabled={currentStep != 0}
                autoFocus
                ref={firstnameRef}
                onChange={(e: any) => {
                  setFnameHasValue(
                    e.target.value != undefined &&
                      e.target.value != "" &&
                      e.target.value != null
                      ? true
                      : false
                  );
                }}
              />
            </Form.Item>
            <Form.Item
              name={"lastname"}
              rules={[
                {
                  required: true,
                  message: translate("validation.field_required"),
                },
              ]}
            >
              <CustomInput
                placeholder={`${translate("users.lastname")}*`}
                disabled={currentStep != 0}
                onChange={(e: any) => {
                  setLnameHasValue(
                    e.target.value != undefined &&
                      e.target.value != "" &&
                      e.target.value != null
                      ? true
                      : false
                  );
                }}
              />
            </Form.Item>
          </Flex>
        </>
      ),
      button: (
        <Space className={styles.actionButtons}>
          <Button
            type="primary"
            className={styles.actionButtons}
            onClick={() => next()}
            disabled={!fnameHasValue || !lnameHasValue}
            ref={nextButton0}
          >
            {translate("buttons.next")}
          </Button>
        </Space>
      ),
    },
    {
      title: (
        <Typography.Text style={{ fontWeight: "500", marginBottom: 20 }}>
          {translate("account.your_company_name") + ":"}
        </Typography.Text>
      ),
      description: (
        <>
          <Form.Item
            name={"company"}
            rules={[
              {
                required: true,
                message: translate("validation.field_required"),
              },
            ]}
          >
            <CustomInput
              placeholder={`${translate("teams.company_name")}*`}
              disabled={currentStep != 1}
              ref={companyNameRef}
              onChange={(e: any) =>
                setCnameHasValue(
                  e.target.value != undefined &&
                    e.target.value != "" &&
                    e.target.value != null
                    ? true
                    : false
                )
              }
            />
          </Form.Item>
          <Typography.Text style={{ fontWeight: "500", marginBottom: 20 }}>
            {translate("account.select_country") + ":"}
          </Typography.Text>
          <Form.Item
            name={"country"}
            rules={[
              {
                required: true,
                message: translate("validation.field_required"),
              },
            ]}
          >
            <CustomSelect
              placeholder={translate("account.select_country") + "*"}
              showSearch={true}
              ref={countryRef}
              optionFilterProp="label"
              options={getCountryOptions()}
            />
          </Form.Item>
        </>
      ),
      button: (
        <Space className={styles.actionButtons}>
          <Button onClick={() => prev()}>
            {translate("buttons.previous")}
          </Button>
          <Button
            type="primary"
            onClick={() => next()}
            disabled={!cnameHasValue || !country}
            ref={nextButton1}
          >
            {translate("buttons.next")}
          </Button>
        </Space>
      ),
    },
    {
      title: (
        <Typography.Text style={{ fontWeight: "500", marginBottom: 20 }}>
          {translate("account.your_website") + ":"}
        </Typography.Text>
      ),
      description: (
        <>
          <Form.Item name={"website_url"}>
            <CustomInput
              prefix="https://"
              styles={{
                input: {
                  paddingLeft: 0,
                },
              }}
              onChange={(e: any) => {
                setWebsiteUrl(
                  e.target.value != undefined &&
                    e.target.value != "" &&
                    e.target.value != null
                    ? true
                    : false
                );
              }}
            />
          </Form.Item>
          <Typography.Text style={{ fontWeight: "500", marginBottom: 20 }}>
            {translate("contacts.phone_singular") + ":"}
          </Typography.Text>
          <Form.Item name={"phone_number"}>
            <CustomInput
              placeholder="+49"
              onChange={(e: any) => {
                setPhoneNumber(
                  e.target.value != undefined &&
                    e.target.value != "" &&
                    e.target.value != null
                    ? true
                    : false
                );
              }}
            />
          </Form.Item>
        </>
      ),
      button: (
        <Space className={styles.actionButtons}>
          <Button onClick={() => prev()}>
            {translate("buttons.previous")}
          </Button>
          <Button type="primary" onClick={() => next()} ref={nextButton2}>
            {translate("buttons.next")}
          </Button>
        </Space>
      ),
    },
    ...(logedWithProvider
      ? []
      : [
          {
            title: (
              <Typography.Text style={{ fontWeight: "500", marginBottom: 20 }}>
                {translate("account.set_password", "Set your password") + ":"}
              </Typography.Text>
            ),
            description: (
              <Space direction="vertical" size={"large"}>
                <Form.Item
                  name="password"
                  style={{ marginBottom: "12px" }}
                  help={translate("validation.password_requirements")}
                  rules={[
                    {
                      min: 8,
                      message: translate("validation.password_min_length", {
                        min: 8,
                      }),
                    },
                    {
                      pattern: /(?=.*[a-z])(?=.*[A-Z])/,
                      message: translate(
                        "validation.password_upper_and_lowercase"
                      ),
                    },
                    {
                      pattern: /(?=.*\d)/,
                      message: translate(
                        "validation.password_at_least_one_number"
                      ),
                    },
                  ]}
                >
                  <CustomInput
                    ref={passwordRef}
                    type="password"
                    placeholder="●●●●●●●●"
                    size="large"
                    label={translate("account.password", "Password")}
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  // hasFeedback
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: translate(
                        "validation.confirm_required",
                        "Confirm password is required"
                      ),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            translate(
                              "validation.passwords_do_not_match",
                              "Passwords do not match"
                            )
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <CustomInput
                    type="password"
                    placeholder="●●●●●●●●"
                    size="large"
                    label={translate(
                      "account.password_confirm",
                      "Confirm Password"
                    )}
                  />
                </Form.Item>
              </Space>
            ),
            button: (
              <Space className={styles.actionButtons}>
                <Button onClick={() => prev()}>
                  {translate("buttons.previous")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSaveAccount()}
                  disabled={
                    !password ||
                    password?.length < 6 ||
                    password !== confirmPassword
                  }
                  ref={nextButton4}
                >
                  {translate("buttons.next")}
                </Button>
              </Space>
            ),
          },
        ]),
  ];
  const items: StepProps[] = [
    {
      disabled: currentStep != 0,
    },
    {
      disabled: currentStep != 1,
    },
    {
      disabled: currentStep != 2,
    },
    ...(logedWithProvider
      ? []
      : [
          {
            disabled: currentStep != 3,
          },
        ]),
  ];
  useEffect(() => {
    if (personalAccount?.metadata?.initialSetup) navigate("/");
  }, [personalAccount]);
  return (
    <>
      <Row>
        <Col
          md={16}
          style={{
            minHeight: "100vh",
            height: "auto",
            padding: isMobile ? "0 20px" : "0 50px",
          }}
          xs={24}
        >
          <Flex
            justify="space-between"
            align="center"
            style={{ height: "15vh" }}
          >
            <Typography.Title
              style={{
                margin: 0,
                fontSize: isMobile ? "1.5rem" : "revert-layer",
              }}
            >
              {translate("account.setup")}
            </Typography.Title>
            <Form.Item style={{ margin: 0 }}>
              <Select
                options={ojbSelectLang}
                defaultValue={currentLocale}
                onSelect={(lang) => {
                  changeLanguage(lang);
                  open?.({
                    description: translate("notifications.success"),
                    message: translate("notifications.editSuccess"),
                    type: "success",
                  });
                }}
              />
            </Form.Item>
          </Flex>
          <Flex
            style={{ height: "85vh" }}
            justify="flex-start"
            align="center"
            gap={isMobile ? 20 : 0}
          >
            <Steps
              direction="vertical"
              current={currentStep}
              items={items}
              style={{ width: "10%", height: "70%" }}
            />
            <Form
              {...formProps}
              form={form}
              style={{ width: "90%", height: "70%" }}
            >
              <Flex justify="space-between" vertical style={{ height: "100%" }}>
                <Flex
                  vertical
                  align="stretch"
                  justify="center"
                  style={{ height: "100%" }}
                >
                  {content[currentStep].title}
                  {content[currentStep].description}
                </Flex>
                {content[currentStep].button}
              </Flex>
            </Form>
          </Flex>
        </Col>
        <Col
          md={8}
          xs={0}
          style={{
            width: "33.33%", // Adjust this to match the span of the column (8/24 = 1/3)
            height: "100vh",
            position: "fixed",
            top: 0,
            right: 0,
            background: "blue",
            backgroundImage: `url(${Images.RegisterCover})`,
            backgroundSize: "cover",
            backgroundPositionX: "50%",
          }}
        />
      </Row>
      {children}
    </>
  );
};
export default AccountSetup;

import { useCreate, useNavigation, useTranslate } from "@refinedev/core";
import {Button, Col, Form, Modal, Radio, Row, message, Space} from "antd";
import React, { useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";
import { getSectionsConfig } from "./sections/sectionsConfig";
import HelpDrawer from "../common/HelpDrawer";
import {QuestionCircleOutlined} from "@ant-design/icons";

type Props = {
  onSuccess?: (data: any) => void;
  onClose?: () => void;
  open?: boolean;
  disableType?: string;
  contact_has_contact_id?: number;
};
const CreateContactModal = ({
  onSuccess,
  open,
  onClose,
  disableType,
  contact_has_contact_id,
}: Props) => {
  const [form] = Form.useForm();
  const { list } = useNavigation();
  const { mutateAsync: createMutate } = useCreate({
    successNotification: false,
    invalidates: ["list"],
  });

  const { currentTeam } = useUser();
  const [contactType, setContactType] = useState(
    disableType == "company" ? "person" : "company"
  );
  const [dynamicSections, setDynamicSections] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(null); // Add this line
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<any>(null);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const translate = useTranslate();
  const handleAddressSubmit = (addressData: any) => {
    setSelectedAddress(addressData);
  };
  const handlePhoneSubmit = (phoneData: any) => {
    setSelectedPhoneNumber(phoneData);
  };
  const baseSectionsConfig = getSectionsConfig(
    translate,
    form,
    handleAddressSubmit,
    handlePhoneSubmit
  );
  const sectionsConfig = (() => {
    if (disableType === "company") {
      return baseSectionsConfig.filter(
        (section) => section.key !== "company_id"
      );
    }
    if (disableType === "person") {
      return baseSectionsConfig.filter(
        (section) => section.key !== "contact_person"
      );
    }
    return baseSectionsConfig;
  })();
  const generateCustomerNumber = async () => {
    if (!currentTeam?.account_id) return;
    try {
      const { data, error } = await supabaseClient.rpc(
        "get_next_customer_number",
        { account_id: currentTeam.account_id }
      );

      if (error) {
        console.error("Error generating customer number:", error);
        return;
      }

      form.setFieldsValue({ customer_number: data });
    } catch (error) {
      console.error("Error generating customer number:", error);
    }
  };

  const handleAddSection = (sectionKey: string) => {
    if (!dynamicSections.includes(sectionKey)) {
      setDynamicSections((prev) => [...prev, sectionKey]);
      const sectionConfig = sectionsConfig.find(
        ({ key }) => key === sectionKey
      );
      if (sectionConfig?.component) {
        setTimeout(() => {
          const inputElement = document.querySelector(
            `input#${sectionKey}, textarea#${sectionKey}, select#${sectionKey}`
          );
          if (inputElement) {
            (inputElement as HTMLElement).focus();
          }
        }, 0);
      }
    }
  };

  const groupSectionsByRow = (sections: typeof sectionsConfig) => {
    const rows: Array<typeof sections> = [];
    let currentRow: typeof sections = [];
    let currentSpan = 0;

    sections.forEach((section) => {
      if (currentSpan + section.span > 24) {
        rows.push(currentRow);
        currentRow = [];
        currentSpan = 0;
      }
      currentRow.push(section);
      currentSpan += section.span;
    });

    if (currentRow.length > 0) {
      rows.push(currentRow);
    }

    return rows;
  };

  const renderSections = () => {
    const allSections = [
      ...sectionsConfig.filter(
        ({ required, types }) => required && types.includes(contactType)
      ),
      ...(dynamicSections
        .map((sectionKey) =>
          sectionsConfig.find(({ key }) => key === sectionKey)
        )
        .filter(Boolean) as typeof sectionsConfig),
    ];

    const groupedSections = groupSectionsByRow(allSections);

    return groupedSections.map((row, index) => (
      <Row key={index} gutter={[12, 0]} style={{ marginBottom: 0 }}>
        {row.map(({ key, component: Component, span, props = {} }) => (
          <Col key={key} span={span}>
            <Form.Item name={key} layout="vertical" style={{ marginBottom: 8 }}>
              <Component
                onPhoneSubmit={function (addressData: any): void {
                  // console.log("");
                }}
                onAddressSubmit={function (addressData: any): void {
                  // console.log("");
                }}
                generateCustomerNumber={generateCustomerNumber}
                {...props}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    ));
  };

  const handleSave = async (values: any, closeAfterSave: boolean) => {
    setSaveButtonLoading(true);

    try {
      await form.validateFields();
    } catch (error) {
      setSaveButtonLoading(false);
      return;
    }
    try {
      if (!currentTeam?.account_id) {
        throw new Error("Missing account information.");
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        phone_number,
        phone_label,
        contact,
        company_id,
        address,
        ...contactValues
      } = values;
      const mainContact = await createMutate({
        resource: "contacts",
        values: {
          ...contactValues,
          type: contactType,
          account: currentTeam.account_id,
        },
      });

      if (selectedPhoneNumber) {
        for (const phoneNumber of selectedPhoneNumber) {
          await createMutate({
            resource: "phone_numbers",
            values: {
              contact: mainContact.data.id,
              number: phoneNumber.phone_number,
              label: phoneNumber.phone_label,
              account: currentTeam.account_id,
            },
          });
        }
      }
      if (selectedAddress && Array.isArray(selectedAddress)) {
        for (const address of selectedAddress) {
          const addressResponse = await createMutate({
            resource: "addresses",
            values: {
              label: address?.label,
              type: address?.type,
              address_supplement: address?.address_supplement,
              city: address?.city,
              country: address?.country,
              street: address?.street,
              zip: address?.zip,
              account: currentTeam?.account_id,
            },
          });

          const newAddressId = addressResponse?.data?.id;

          if (newAddressId) {
            await createMutate({
              resource: "contact_has_address",
              values: {
                contact: mainContact?.data?.id,
                address: newAddressId,
                account: currentTeam?.account_id,
              },
            });
          }
        }
      }

      if (mainContact) {
        if (contact_has_contact_id) {
          await supabaseClient.from("contact_has_contact").insert({
            contact_id: contact_has_contact_id,
            child: mainContact.data.id,
            account: currentTeam.account_id,
          });
        } else {
          const connectionId = contact || company_id;
          if (connectionId) {
            await supabaseClient.from("contact_has_contact").insert({
              contact_id: connectionId,
              child: mainContact.data.id,
              account: currentTeam.account_id,
            });
          }
        }
      }
      message.success(translate("contacts.create_new_contact_success"));

      if (closeAfterSave && !onSuccess) {
        list("contacts", "replace");
      } else {
        form.resetFields();
        form.setFieldsValue({ type: contactType });
        setDynamicSections([]);
        if (onSuccess) onSuccess(mainContact.data);
      }
    } catch (error) {
      console.error("Error saving contact:", error);
      message.error(translate("contacts.create_new_contact_failed"));
    } finally {
      setSaveButtonLoading(false);
    }
  };

  return (
    <Form form={form} onFinish={(values) => handleSave(values, true)}>
      <Modal
        open={open == undefined ? true : open}
        title={
          <Space>
            {translate("contacts.create_new_contact")}
            <Button
              type={"link"}
              size={"small"}
              icon={<QuestionCircleOutlined />}
              onClick={() => setOpenHelp(true)}
              style={{ marginLeft: 12 }}
            />
          </Space>
        }
        onCancel={() => (onClose ? onClose() : list("contacts", "replace"))}
        width={760}
        footer={[
          <Button
            key="cancel"
            onClick={() => (onClose ? onClose() : list("contacts", "replace"))}
            style={{ float: "left" }}
          >
            {translate("buttons.cancel")}
          </Button>,
          <Button
            key="save-and-new"
            type="default"
            loading={saveButtonLoading}
            onClick={() => handleSave(form.getFieldsValue(), false)}
          >
            {translate("buttons.save_and_new")}
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={saveButtonLoading}
            onClick={() => handleSave(form.getFieldsValue(), true)}
          >
            {translate("buttons.save")}
          </Button>,
        ]}
      >
        <Form.Item name="type" initialValue={contactType} layout="vertical">
          <Radio.Group
            value={contactType}
            onChange={(e) => setContactType(e.target.value)}
            optionType="default"
            buttonStyle="solid"
          >
            {disableType != "company" && (
              <Radio.Button value="company">
                {translate("contacts.segmented.company")}
              </Radio.Button>
            )}
            {disableType != "person" && (
              <Radio.Button value="person">
                {translate("contacts.segmented.person")}
              </Radio.Button>
            )}
          </Radio.Group>
        </Form.Item>

        {renderSections()}

        <Row gutter={[12, 0]} style={{ marginBottom: 0 }}>
          {sectionsConfig
            .filter(
              ({ required, types }) => !required && types.includes(contactType)
            )
            .map(({ key, title }) => (
              <Button
                key={key}
                type="link"
                onClick={() => handleAddSection(key)}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.color = dynamicSections.includes(key)
                    ? "#a6a6a6"
                    : "#404040")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.color = dynamicSections.includes(key)
                    ? "#bfbfbf"
                    : "#595959")
                }
                style={{
                  color: dynamicSections.includes(key) ? "#bfbfbf" : "#595959",
                  fontWeight: dynamicSections.includes(key) ? "500" : "600",
                  transition: "color 0.3s ease",
                }}
              >
                {title}
              </Button>
            ))}
        </Row>
      </Modal>

      <HelpDrawer
        open={openHelp}
        onClose={() => setOpenHelp(false)}
        module="contacts/create"
      />

    </Form>
  );
};

export default CreateContactModal;

import { Authenticated, CanAccess, Refine } from "@refinedev/core";
import { DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { ConfigProvider } from "antd";
import deDE from "antd/locale/de_DE";
import enUS from "antd/locale/en_US";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

import {
  AuthPage,
  ErrorComponent,
  ThemedLayoutV2,
  ThemedTitleV2,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import Icon, {
  ApartmentOutlined,
  DesktopOutlined,
  EuroOutlined,
  FileOutlined,
  MailOutlined,
  ProductOutlined,
  RocketOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { App as AntdApp } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { NavigateToResource } from "./components/CustomNavigateToResource";
import Layout from "./components/Layout/Layout";
import { Sider } from "./components/Layout/sider";
import { AppIcon } from "./components/app-icon";
import { RegisterPage } from "./components/auth/RegisterPage";
import VerifyPage from "./components/auth/VerifyPage";
import NotLogedInInvitationWrapper from "./components/auth/invitation/InviteWrapper";

import CanAccessFallback from "./components/common/CanAccessFallback";
import GlobalSearch from "./components/common/GlobalSearch";
import ShowSentEmail from "./components/mailings/ShowSentEmail";
import CustomerNumberSettings from "./components/settings/CustomerNumberSettings";
import InvoicesList from "./components/settings/InvoicesList";
import MailingSettings from "./components/settings/MailingSettings";
import ShopSettings from "./components/settings/ShopSettings";
import SubscriptionContracts from "./components/teams/settings/subscription-contracts";
import TeamSettings from "./components/teams/settings/team-settings";
import { UserProvider } from "./contexts/ContextProvider/userProvider";
import { usePipelineContext } from "./contexts/PipelineProvider";
import { SearchProvider } from "./contexts/SearchProvider/useSearchContext";
import { ColorModeContextProvider } from "./contexts/color-mode";
import useSentry from "./hooks/useSentry";
import ActivitiesList from "./pages/activities/list";
import AutomationsCreate from "./pages/automations/create";
import AutomationsEdit from "./pages/automations/edit";
import AutomationsList from "./pages/automations/list";
import ExceptionShow from "./pages/exceptions/show";
import PipelineOverview from "./pages/pipelines/overview";
import IntegrationsList from "./pages/settings/integrations/list";
import InvoiceCreate from "./pages/shop/invoices/create";
import InvoiceDrawer from "./pages/shop/invoices/show";
import OrdersCreate from "./pages/shop/orders/create";
import OrdersShow from "./pages/shop/orders/show";
import PaymentsList from "./pages/shop/payments";
import CreateProductItem from "./pages/shop/product-items/create";
import ProductIssuesCreate from "./pages/shop/product-items/product-issues/create";
import ProductIssueShow from "./pages/shop/product-items/product-issues/show";
import ProductItemShow from "./pages/shop/product-items/show";
import SubscriptionProductsCreate from "./pages/shop/subscription-products/create";
import SubscriptionProductsShow from "./pages/shop/subscription-products/show";
import CreateTeam from "./pages/team/create";
import RedirectTeam from "./pages/team/redirectTeam";
import AccountSetup from "./pages/welcome/AccountSetup";
import Invitations from "./pages/welcome/Invitations";
import Onboarding from "./pages/welcome/onboarding";
import authProvider from "./providers/authProvider";
import { dataProvider, liveProvider } from "./providers/supabase";
import { themeProvider } from "./providers/themeProvider";
import "./styles/antd.css";
import "./styles/fc.css";
import "./styles/index.css";
import { supabaseClient } from "./utility";
import { RedirectHomeRoute } from "./utility/RedirectHomeRoute";
import { ValidTeamChecker } from "./utility/ValidTeamChecker";
import { capitalizeEveryWord } from "./utility/transform";
import MicrosoftIcon from "./assets/icons/microsoft";

const EmptyPipelines = React.lazy(() => import("./pages/pipelines/empty"));

const SettingsList = React.lazy(() => import("./pages/settings"));

const EventList = React.lazy(() => import("./pages/events/list"));
const EventShow = React.lazy(() => import("./pages/events/show"));
const EventCreate = React.lazy(() => import("./pages/events/create"));
const EventEdit = React.lazy(() => import("./pages/events/edit"));

import { messageNotificationProvider } from "./providers/messageNotificationProvider";

const WebshopList = React.lazy(
  () => import("./pages/shop/product-webshop/list")
);

const OrdersList = React.lazy(() => import("./pages/shop/orders/list"));
const WebshopCreate = React.lazy(
  () => import("./pages/shop/product-webshop/create")
);
const WebshopShow = React.lazy(
  () => import("./pages/shop/product-webshop/show")
);
const ProductCategorieList = React.lazy(
  () => import("./pages/shop/product-categories/list")
);
const ForecastList = React.lazy(() => import("./pages/shop/forecasts/list"));
const ShippingTablesList = React.lazy(
  () => import("./pages/shop/shipping/list")
);
const ShippingTablesShow = React.lazy(
  () => import("./pages/shop/shipping/show")
);
const CreateProductCategory = React.lazy(
  () => import("./pages/shop/product-categories/create")
);
const EditProductCategory = React.lazy(
  () => import("./pages/shop/product-categories/edit")
);
const ProductItemList = React.lazy(
  () => import("./pages/shop/product-items/list")
);
const FileList = React.lazy(() => import("../src/pages/settings/files/list"));
const PipelineItemEdit = React.lazy(
  () => import("../src/pages/pipelines/items/edit")
);
const PipelineItemCreate = React.lazy(
  () => import("../src/pages/pipelines/items/create")
);
const PipelineItemShow = React.lazy(
  () => import("../src/pages/pipelines/items/show/index")
);
const PipelineShow = React.lazy(() => import("../src/pages/pipelines/show"));

const StagesShow = React.lazy(
  () => import("../src/pages/pipelines/stages/show")
);
const PipelineEdit = React.lazy(() => import("../src/pages/pipelines/edit"));
const PipelineAutomationEdit = React.lazy(
  () => import("../src/pages/pipelines/automation/edit")
);
const PipelineStoreShow = React.lazy(
  () => import("../src/pages/pipelines/pipelines-store/show")
);
const PipelineStoreList = React.lazy(
  () => import("../src/pages/pipelines/pipelines-store/list")
);

const PipelineList = React.lazy(() => import("../src/pages/pipelines/list"));

// const AccountShow = React.lazy(() => import("../src/pages/account/show"));
const TagList = React.lazy(() => import("../src/pages/settings/tags/list"));
const TagShow = React.lazy(() => import("../src/pages/settings/tags/show"));
const TagEdit = React.lazy(() => import("../src/pages/settings/tags/edit"));
const AIShow = React.lazy(() => import("../src/pages/settings/ai"));

const InvoicesListPage = React.lazy(() => import("./pages/shop/invoices/list"));
const MailingsCreate = React.lazy(() => import("./pages/mailings/create"));
const MailingsList = React.lazy(() => import("./pages/mailings/list"));
const MailingsEdit = React.lazy(() => import("./pages/mailings/edit"));
const ContactShow = React.lazy(() => import("../src/pages/contacts/show"));
const ContactCreate = React.lazy(() => import("../src/pages/contacts/create"));
const DashboardPage = React.lazy(() => import("../src/pages/dashboard/index"));
const ContactList = React.lazy(() => import("../src/pages/contacts/list"));
const ExceptionList = React.lazy(() => import("../src/pages/exceptions/list"));

function App() {
  const { pipelines, canAccess } = usePipelineContext();
  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: any) => t(key, params as string),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  useSentry();
  return (
    <ConfigProvider
      locale={t("page.settings.locale") === "de" ? deDE : enUS}
      theme={themeProvider}
    >
      <BrowserRouter>
        <RefineKbarProvider>
          <ColorModeContextProvider>
            <AntdApp>
              <DevtoolsProvider>
                <Refine
                  dataProvider={{
                    default: dataProvider(supabaseClient),
                  }}
                  liveProvider={liveProvider(supabaseClient)}
                  authProvider={authProvider}
                  accessControlProvider={{
                    can: async ({ resource, action }) => {
                      if (
                        resource == "welcome" &&
                        action == "list" &&
                        canAccess?.personal_modules?.welcome == false
                      ) {
                        return { can: false, reason: "Deactivated" };
                      }
                      if (
                        (resource === "pipelines" ||
                          resource === "pipelines-menu") &&
                        action === "list" &&
                        !canAccess?.modules?.pipeline
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "pipelines" ||
                          resource === "pipelines-menu") &&
                        action === "list" &&
                        (canAccess?.modules?.pipeline === false ||
                          !canAccess?.personal_modules?.pipeline)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        resource === "pipeline_stages" &&
                        (canAccess?.modules?.pipeline === false ||
                          canAccess?.personal_modules?.pipeline === false)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "shop" ||
                          resource === "product_items" ||
                          resource === "subscription_products" ||
                          resource === "orders" ||
                          resource === "invoices" ||
                          resource === "payments") &&
                        action === "list" &&
                        (canAccess?.personal_modules?.shop?.enabled === false ||
                          canAccess?.personal_modules?.shop?.enabled ===
                            undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "shop" ||
                          resource === "product_items" ||
                          resource === "subscription_products" ||
                          resource === "orders") &&
                        action === "list" &&
                        canAccess?.modules?.shop === true &&
                        !canAccess?.personal_modules?.shop
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "newsletters" ||
                          resource === "newsletters" ||
                          resource === "newsletters") &&
                        action === "list" &&
                        (canAccess?.modules?.mailing === false ||
                          canAccess?.modules?.mailing === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }

                      if (
                        (resource === "newsletters" ||
                          resource === "info_mails" ||
                          resource === "newsletters") &&
                        action === "list" &&
                        canAccess?.modules?.mailing === true &&
                        !canAccess?.personal_modules?.mailing
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        resource === "automations" &&
                        action === "list" &&
                        (canAccess?.modules?.automations === false ||
                          canAccess?.modules?.automations === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        resource === "automations" &&
                        action === "list" &&
                        (canAccess?.modules?.automations === false ||
                          canAccess?.modules?.automations === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      return { can: true };
                    },
                  }}
                  routerProvider={routerBindings}
                  //notificationProvider={useNotificationProvider}
                  notificationProvider={messageNotificationProvider}
                  i18nProvider={i18nProvider}
                  resources={[
                    {
                      name: "dashboard",
                      list: "/:teamSlug",
                      meta: {
                        label: t("dashboard.dashboard"),
                        icon: <DesktopOutlined />,
                      },
                    },
                    {
                      name: "activities",
                      list: "/:teamSlug/activities",
                      meta: {
                        label: "Last activities",
                        hide: true,
                        icon: <SettingOutlined />,
                      },
                    },
                    {
                      name: "exceptions",
                      list: "/:teamSlug/exceptions",
                      meta: {
                        label: "Exceptions",
                        hide: true,
                        icon: <SettingOutlined />,
                      },
                    },

                    {
                      name: "contacts",
                      list: "/:teamSlug/contacts",
                      create: "/:teamSlug/contacts/create",
                      edit: "/:teamSlug/contacts/edit/:id",
                      show: "/:teamSlug/contacts/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <TeamOutlined />,
                      },
                    },
                    ...(canAccess?.modules?.events
                      ? [
                          {
                            name: "events",
                            list: "/:teamSlug/events",
                            create: "/:teamSlug/events/create",
                            edit: "/:teamSlug/events/edit/:id",
                            show: "/:teamSlug/events/show/:id",
                            meta: {
                              canDelete: true,
                              icon: <TeamOutlined />,
                            },
                          },
                        ]
                      : []),
                    {
                      name: "pipelines-menu",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/edit",
                      list: "/:teamSlug/pipelines",
                      meta: {
                        icon: <ApartmentOutlined />,
                        label: "Pipelines",
                      },
                    },
                    {
                      name: "pipeline_items",
                      list: "/:teamSlug/pipelines/show/:pipelineId/items",
                      create:
                        "/:teamSlug/pipelines/show/:pipelineId/items/create",
                      show: "/:teamSlug/pipelines/show/:pipelineId/items/:id",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/items/edit:id",
                      meta: {
                        hide: true,
                        parent: "pipelines",
                      },
                    },
                    ...(canAccess?.modules?.pipeline == true &&
                    canAccess?.personal_modules?.pipeline
                      ? [...pipelines]
                      : []),
                    {
                      name: "product_categories",
                      list: "/:teamSlug/shop/product-categories",
                      create: "/:teamSlug/shop/product-categories/create",
                      edit: "/:teamSlug/shop/product-categories/edit/:id",
                      show: "/:teamSlug/shop/product-categories/show/:id",

                      meta: {
                        canDelete: true,
                        hide: true,
                        parent: "product_items",
                        label: t(
                          "commerce.product_categories",
                          "Product categories"
                        ),
                      },
                    },

                    {
                      name: "product_items",
                      list: "/:teamSlug/shop/product-items",
                      create: "/:teamSlug/shop/product-items/create",
                      edit: "/:teamSlug/shop/product-items/edit/:id",
                      show: "/:teamSlug/shop/product-items/show/:id",

                      meta: {
                        icon: <ProductOutlined />,
                        canDelete: true,
                        label: t("commerce.product_items", "Product items"),
                      },
                    },
                    {
                      name: "orders",
                      list: "/:teamSlug/shop/orders",
                      create: "/:teamSlug/shop/orders/create",
                      edit: "/:teamSlug/shop/orders/edit/:id",
                      show: "/:teamSlug/shop/orders/show/:id",

                      meta: {
                        icon: <ShoppingCartOutlined />,
                        canDelete: true,
                        label: t("commerce.orders", "Orders"),
                      },
                    },
                    {
                      name: "forecasts",
                      list: "/:teamSlug/shop/forecasts",

                      meta: {
                        canDelete: true,
                        hide: true,
                        label: t("commerce.forecasts.title", "Forecasts"),
                      },
                    },
                    {
                      name: "shipping_tables",
                      list: "/:teamSlug/shop/shipping",
                      show: "/:teamSlug/shop/shipping/show/:id",

                      meta: {
                        canDelete: true,
                        hide: true,
                        label: t("commerce.shipping.title", "Shipping"),
                      },
                    },

                    {
                      name: "invoices",
                      list: "/:teamSlug/shop/invoices",
                      create: "/:teamSlug/shop/invoices/create",
                      edit: "/:teamSlug/shop/invoices/edit/:id",
                      show: "/:teamSlug/shop/invoices/show/:id",

                      meta: {
                        icon: <FileOutlined />,
                        canDelete: true,
                        label: t("commerce.invoices", "Invoices"),
                      },
                    },
                    {
                      name: "payments",
                      list: "/:teamSlug/shop/payments",
                      meta: {
                        icon: <EuroOutlined />,
                        label: t("commerce.payments", "Payments"),
                      },
                    },
                    {
                      name: "webshops",
                      list: "/:teamSlug/shop/webshop",
                      create: "/:teamSlug/shop/webshop/create",
                      edit: "/:teamSlug/shop/webshop/edit/:id",
                      show: "/:teamSlug/shop/webshop/show/:id",

                      meta: {
                        canDelete: true,
                        label: t("page.shop", "Shop"),
                        hide: !canAccess?.personal_modules?.shop?.webshops,
                      },
                    },

                    {
                      name: "product_issues",
                      list: "/:teamSlug/shop/product-items/show/:id/",
                      create:
                        "/:teamSlug/shop/product-items/:id/product-issues/create",
                      show: "/:teamSlug/shop/product-items/show/:id/product-issues/:issueId",
                      meta: {
                        canDelete: true,
                        hide: true,
                        parent: "product_items",
                        label: t("commerce.product_items", "Product items"),
                      },
                    },
                    {
                      name: "subscription_products",
                      list: "/:teamSlug/shop/product-items/show/:prodId/subscription-products",
                      create:
                        "/:teamSlug/shop/product-items/show/:prodId/subscription-products/create",
                      edit: "/:teamSlug/shop/product-items/show/:prodId/subscription-products/edit/:subId",
                      show: "/:teamSlug/shop/product-items/show/:prodId/subscription-products/show/:subId",
                      meta: {
                        canDelete: true,
                        hide: true,
                        parent: "product_items",
                        label: t(
                          "subscription_products.list.title",
                          "Subscription Products"
                        ),
                      },
                    },
                    {
                      name: "info_mails",
                      list: "/:teamSlug/newsletters",
                      create: "/:teamSlug/newsletters/create",
                      edit: "/:teamSlug/newsletters/edit/:id",
                      show: "/:teamSlug/newsletters/show/:id",
                      meta: {
                        icon: <MailOutlined />,
                        canDelete: true,
                        label: t("mailings.title"),
                      },
                    },

                    ...(canAccess?.modules?.automations == true
                      ? [
                          {
                            name: "automations",
                            list: "/:teamSlug/automations",
                            create: "/:teamSlug/automations/create",
                            edit: "/:teamSlug/automations/edit/:id",
                            show: "/:teamSlug/automations/show/:id",
                            meta: {
                              canDelete: true,
                              icon: <RocketOutlined />,
                            },
                          },
                        ]
                      : []),
                    {
                      name: "settings",
                      list: "/:teamSlug/settings",
                      meta: { icon: <SettingOutlined />, hide: true },
                    },
                    {
                      name: "account",
                      list: "/account",
                      meta: {
                        label: t("settings.account"),
                        hide: true,
                      },
                    },

                    {
                      name: "pipeline_stages_custom_field",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/settings/custom-fields",
                      meta: {
                        parent: "settings",
                        hide: true,
                      },
                    },
                    {
                      name: "pipeline_stages",
                      show: "/:teamSlug/pipelines/show/:pipelineId/:stageId",
                    },

                    // {
                    //   name: "team-members",
                    //   list: "/:teamSlug/team-members",
                    //   meta: { hide: true },
                    // },
                  ]}
                  options={{
                    syncWithLocation: true,
                    warnWhenUnsavedChanges: true,
                    useNewQueryKeys: true,
                    projectId: "m5CFOK-FLhH7r-EYpiQH",
                    liveMode: "auto",
                    disableTelemetry: true,
                  }}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Authenticated
                          key="authenticated-inner"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <RedirectHomeRoute />
                        </Authenticated>
                      }
                    />

                    <Route
                      element={
                        <Authenticated
                          key="authenticated-inner"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <UserProvider>
                            <SearchProvider>
                              <ThemedLayoutV2
                                Header={() => <GlobalSearch />}
                                Sider={() => <Sider />}
                                Title={({ collapsed }) => (
                                  <ThemedTitleV2
                                    collapsed={collapsed}
                                    text="CRM"
                                    icon={<AppIcon />}
                                  />
                                )}
                              >
                                <Layout>
                                  <Outlet />
                                </Layout>
                              </ThemedLayoutV2>
                            </SearchProvider>
                          </UserProvider>
                        </Authenticated>
                      }
                    >
                      <Route
                        path="/:teamSlug"
                        element={
                          <ValidTeamChecker>
                            <Outlet />
                          </ValidTeamChecker>
                        }
                      >
                        <Route
                          path="pipelines"
                          element={
                            <Suspense>
                              <PipelineOverview
                                key={canAccess?.personal_modules?.pipeline}
                              />
                            </Suspense>
                          }
                        ></Route>
                        <Route
                          element={
                            <Suspense>
                              <DashboardPage>
                                <Outlet />
                              </DashboardPage>
                            </Suspense>
                          }
                        >
                          <Route index element={null} />
                          <Route
                            path="contacts/:id"
                            element={
                              <Suspense>
                                <ContactShow>
                                  <Outlet />
                                </ContactShow>
                              </Suspense>
                            }
                          />
                          <Route
                            path="deal-show/:itemId"
                            element={
                              <Suspense>
                                <PipelineItemShow />
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="welcome"
                          element={
                            <Suspense>
                              <Onboarding />
                              <Outlet />
                            </Suspense>
                          }
                        />
                        <Route
                          path="contacts"
                          element={
                            <Suspense>
                              <ContactList>
                                <Outlet />
                              </ContactList>
                            </Suspense>
                          }
                        >
                          <Route index element={null} />
                          <Route
                            path="create"
                            element={
                              <Suspense>
                                <ContactCreate>
                                  <Outlet />
                                </ContactCreate>
                              </Suspense>
                            }
                          />
                          <Route
                            path="show/:id"
                            element={
                              <Suspense>
                                <ContactShow>
                                  <Outlet />
                                </ContactShow>
                              </Suspense>
                            }
                          >
                            <Route
                              path="show/:invoiceId"
                              element={
                                <Suspense>
                                  <InvoiceDrawer />
                                </Suspense>
                              }
                            />
                          </Route>
                        </Route>

                        <Route
                          path="activities"
                          element={
                            <Suspense>
                              <ActivitiesList />
                            </Suspense>
                          }
                        ></Route>

                        <Route
                          path="events"
                          element={
                            <Suspense>
                              <EventList>
                                <Outlet />
                              </EventList>
                            </Suspense>
                          }
                        >
                          <Route index element={null} />
                          <Route
                            path="create"
                            element={
                              <Suspense>
                                <EventCreate>
                                  <Outlet />
                                </EventCreate>
                              </Suspense>
                            }
                          />

                          <Route
                            path="show/:id"
                            element={
                              <Suspense>
                                <EventShow>
                                  <Outlet />
                                </EventShow>
                              </Suspense>
                            }
                          />

                          <Route
                            path="edit/:id"
                            element={
                              <Suspense>
                                <EventEdit>
                                  <Outlet />
                                </EventEdit>
                              </Suspense>
                            }
                          />
                        </Route>

                        <Route
                          path="exceptions"
                          element={
                            <Suspense>
                              <ExceptionList>
                                <Outlet />
                              </ExceptionList>
                            </Suspense>
                          }
                        >
                          <Route index element={null} />

                          <Route
                            path="show/:id"
                            element={
                              <Suspense fallback={<div>Loading...</div>}>
                                <ExceptionShow>
                                  <Outlet />
                                </ExceptionShow>
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="shop"
                          element={
                            <CanAccess
                              key={canAccess?.personal_modules?.shop?.enabled}
                              fallback={
                                <CanAccessFallback module="shop.enabled" />
                              }
                            >
                              <Outlet />
                            </CanAccess>
                          }
                        >
                          <Route
                            path="webshop"
                            element={
                              <Suspense>
                                <WebshopList />
                                <Outlet />
                              </Suspense>
                            }
                          >
                            <Route index element={null} />

                            <Route
                              path="create"
                              element={
                                <Suspense>
                                  <WebshopCreate />
                                </Suspense>
                              }
                            />
                            <Route
                              path="show/:id"
                              element={
                                <Suspense>
                                  <WebshopShow />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="product-categories"
                            element={
                              <Suspense>
                                <ProductCategorieList />
                                <Outlet />
                              </Suspense>
                            }
                          >
                            <Route index element={null} />

                            <Route
                              path="create"
                              element={
                                <Suspense>
                                  <CreateProductCategory />
                                </Suspense>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense>
                                  <EditProductCategory />
                                </Suspense>
                              }
                            />
                          </Route>

                          <Route
                            path="product-items"
                            element={
                              <Suspense>
                                <ProductItemList />
                                <Outlet />
                              </Suspense>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="show/:id"
                              element={
                                <Suspense>
                                  <ProductItemShow>
                                    <Outlet />
                                  </ProductItemShow>
                                </Suspense>
                              }
                            >
                              <Route index element={null} />
                              <Route
                                path="product-issues/:issueId"
                                element={<ProductIssueShow />}
                              />
                              <Route
                                path="product-issues/create"
                                element={<ProductIssuesCreate />}
                              />
                              <Route
                                path="subscription-products"
                                element={<Outlet />}
                              >
                                <Route index element={null} />
                                <Route
                                  path="create"
                                  element={
                                    <Suspense>
                                      <SubscriptionProductsCreate>
                                        <Outlet />
                                      </SubscriptionProductsCreate>
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="show/:subId"
                                  element={
                                    <Suspense>
                                      <SubscriptionProductsShow>
                                        <Outlet />
                                      </SubscriptionProductsShow>
                                    </Suspense>
                                  }
                                />
                              </Route>
                            </Route>
                            <Route
                              path="create"
                              element={<CreateProductItem />}
                            />
                          </Route>

                          <Route
                            path="orders"
                            element={
                              <Suspense>
                                <OrdersList>
                                  <Outlet />
                                </OrdersList>
                              </Suspense>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="create"
                              element={
                                <Suspense>
                                  <OrdersCreate />
                                </Suspense>
                              }
                            />
                            <Route
                              path="show/:id"
                              element={
                                <Suspense>
                                  <OrdersShow>
                                    <Outlet />
                                  </OrdersShow>
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="forecasts"
                            element={
                              <Suspense>
                                <ForecastList />
                                <Outlet />
                              </Suspense>
                            }
                          >
                            <Route index element={null} />
                          </Route>

                          <Route path="shipping" element={<Outlet />}>
                            <Route
                              index
                              element={
                                <Suspense>
                                  <ShippingTablesList />
                                </Suspense>
                              }
                            />

                            <Route
                              path="show/:id"
                              element={
                                <Suspense>
                                  <ShippingTablesShow />
                                </Suspense>
                              }
                            />
                          </Route>

                          <Route
                            path="invoices"
                            element={
                              <Suspense>
                                <InvoicesListPage>
                                  <Outlet />
                                </InvoicesListPage>
                              </Suspense>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="create"
                              element={
                                <Suspense>
                                  <InvoiceCreate />
                                </Suspense>
                              }
                            />
                            <Route
                              path="show/:invoiceId"
                              element={
                                <Suspense>
                                  <InvoiceDrawer />
                                </Suspense>
                              }
                            ></Route>
                          </Route>
                          <Route
                            path="payments"
                            element={
                              <Suspense>
                                <PaymentsList>
                                  <Outlet />
                                </PaymentsList>
                              </Suspense>
                            }
                          />
                        </Route>

                        <Route path="newsletters" element={<Outlet />}>
                          <Route
                            index
                            element={
                              <Suspense>
                                <MailingsList
                                  key={canAccess?.personal_modules?.mailing}
                                />
                              </Suspense>
                            }
                          />

                          <Route
                            path="show/:id"
                            element={
                              <Suspense>
                                <ShowSentEmail />
                              </Suspense>
                            }
                          />
                          <Route
                            path="edit/:id"
                            element={
                              <Suspense>
                                <MailingsEdit />
                              </Suspense>
                            }
                          />
                          <Route
                            path="create"
                            element={
                              <Suspense>
                                <MailingsCreate />
                              </Suspense>
                            }
                          />
                        </Route>

                        <Route
                          index
                          element={<NavigateToResource resource="tags" />}
                        />

                        <Route
                          element={
                            <Suspense>
                              <SettingsList>
                                <Outlet />
                              </SettingsList>
                            </Suspense>
                          }
                          path="settings"
                        >
                          <Route index element={null} />
                          <Route
                            path="subscription-contracts"
                            element={
                              <Suspense>
                                <SubscriptionContracts>
                                  <Outlet />
                                </SubscriptionContracts>
                              </Suspense>
                            }
                          />
                          <Route
                            path="invoices"
                            element={
                              <Suspense>
                                <InvoicesList>
                                  <Outlet />
                                </InvoicesList>
                              </Suspense>
                            }
                          />
                          <Route
                            path="workspace"
                            element={
                              <Suspense>
                                <TeamSettings>
                                  <Outlet />
                                </TeamSettings>
                              </Suspense>
                            }
                          />
                          {/* <Route
                            path="team/create"
                            element={
                              <Suspense>
                                <TeamCreate />
                              </Suspense>
                            }
                          /> */}
                          <Route
                            path="shop"
                            element={
                              <Suspense>
                                <ShopSettings />
                              </Suspense>
                            }
                          />

                          <Route path="pipelines">
                            <Route
                              element={
                                <Suspense>
                                  <PipelineList>
                                    <Outlet />
                                  </PipelineList>
                                </Suspense>
                              }
                            >
                              <Route index element={null} />
                            </Route>
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense>
                                  <PipelineEdit />
                                </Suspense>
                              }
                            />
                            <Route
                              path="store"
                              element={
                                <Suspense>
                                  <PipelineStoreList>
                                    <Outlet />
                                  </PipelineStoreList>
                                </Suspense>
                              }
                            >
                              <Route
                                path="show/:storePipelineId"
                                element={
                                  <Suspense>
                                    <PipelineStoreShow />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="show/:pipelineId/automation/:pipelineStageId"
                              element={
                                // <Layout action="edit" resource="automation">
                                <Suspense>
                                  <PipelineAutomationEdit />
                                </Suspense>
                                // </Layout>
                              }
                            />

                            <Route
                              path=":id/:stageId"
                              element={
                                // <Layout
                                //   action="show"
                                //   resource="pipeline_stages"
                                // >
                                <Suspense>
                                  <StagesShow
                                    key={canAccess?.personal_modules?.pipeline}
                                  />
                                </Suspense>
                                // </Layout>
                              }
                            />
                          </Route>
                          <Route
                            path="groups"
                            element={
                              // <Layout action="list" resource="groups">
                              <Suspense>
                                <TagList>
                                  <Outlet />
                                </TagList>
                              </Suspense>
                              // </Layout>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="show/:id"
                              element={
                                // <Layout action="show" resource="groups">
                                <Suspense>
                                  <TagShow>
                                    <Outlet />
                                  </TagShow>
                                </Suspense>
                                // </Layout>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense>
                                  <TagEdit>
                                    <Outlet />
                                  </TagEdit>
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route path="files">
                            <Route
                              index
                              element={
                                <Suspense>
                                  <FileList />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="integrations"
                            element={
                              <Suspense>
                                <IntegrationsList />
                              </Suspense>
                            }
                          />
                          <Route
                            path="mailing"
                            element={
                              <Suspense>
                                <MailingSettings />
                              </Suspense>
                            }
                          />
                          <Route
                            path="customer_number"
                            element={
                              <Suspense>
                                <CustomerNumberSettings />
                              </Suspense>
                            }
                          />
                          <Route
                            path="ai"
                            element={
                              <Suspense>
                                <AIShow />
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="pipelines"
                          element={
                            <Suspense>
                              <EmptyPipelines />
                            </Suspense>
                          }
                        />
                        <Route
                          path="pipelines/show/:pipelineId/settings"
                          element={
                            // <Layout action="edit" resource="pipelines">
                            <Suspense>
                              <PipelineEdit
                                key={canAccess?.personal_modules?.pipeline}
                              />
                            </Suspense>
                            // </Layout>
                          }
                        />

                        <Route
                          path="pipelines/show/:pipelineId/settings/:stageId"
                          element={
                            <Suspense>
                              <StagesShow
                                key={canAccess?.personal_modules?.pipeline}
                              />
                            </Suspense>
                          }
                        />
                        <Route
                          path="pipelines/show/:pipelineId/settings/:stageId/automation"
                          element={
                            <Suspense>
                              <PipelineAutomationEdit />
                            </Suspense>
                          }
                        />
                        <Route
                          path="pipelines/show/:pipelineId"
                          element={
                            <Suspense>
                              {/* <CanAccess
                                resource="pipelines"
                                action="list"
                                fallback={t("module.noAccessTitle")}
                              > */}
                              <PipelineShow>
                                <Outlet />
                              </PipelineShow>
                              {/* </CanAccess> */}
                            </Suspense>
                          }
                        >
                          <Route
                            path="items/:itemId"
                            element={
                              // <Layout action="show" resource="pipeline_items">
                              <Suspense>
                                <PipelineItemShow />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="items/create"
                            element={
                              // <Layout action="create" resource="pipeline_items">
                              <Suspense>
                                <PipelineItemCreate dashboardUsage={false} />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="items/edit/:itemId"
                            element={
                              // <Layout action="edit" resource="pipeline_items">
                              <Suspense>
                                <PipelineItemEdit />
                              </Suspense>
                              // </Layout>
                            }
                          />
                        </Route>

                        <Route
                          path="*"
                          element={
                            <Layout action="show" resource="pipeline_items">
                              <ErrorComponent />
                            </Layout>
                          }
                        />

                        <Route
                          path="automations"
                          element={
                            <Suspense>
                              <AutomationsList>
                                <Outlet />
                              </AutomationsList>
                            </Suspense>
                          }
                        >
                          <Route index element={null} />
                          <Route
                            path="create"
                            element={
                              <Suspense>
                                <AutomationsCreate>
                                  <Outlet />
                                </AutomationsCreate>
                              </Suspense>
                            }
                          />

                          <Route
                            path="show/:id"
                            element={
                              <Suspense>
                                <ContactShow>
                                  <Outlet />
                                </ContactShow>
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="automations/edit/:id"
                          element={
                            <Suspense>
                              <AutomationsEdit />
                            </Suspense>
                          }
                        />
                      </Route>
                    </Route>

                    <Route
                      path="team"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <RedirectTeam />
                        </Authenticated>
                      }
                    />
                    <Route
                      path="team/create"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <CreateTeam />
                        </Authenticated>
                      }
                    />
                    <Route
                      path="team/invitations"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<NotLogedInInvitationWrapper />}
                        >
                          <Invitations />
                        </Authenticated>
                      }
                    />
                    <Route
                      path="/account/setup"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <AccountSetup />
                        </Authenticated>
                      }
                    />
                    <Route
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<Outlet />}
                        >
                          <NavigateToResource />
                        </Authenticated>
                      }
                    >
                      <Route
                        path="/login"
                        element={
                          <AuthPage
                            type="login"
                            title=""
                            formProps={{
                              initialValues: { email: "", password: "" },
                            }}
                            providers={[
                              {
                                name: "azure",
                                icon: <Icon component={MicrosoftIcon} />,
                                label: t(
                                  "pages.register.login_with_microsoft",
                                  "Sign in with Microsoft"
                                ),
                              },
                              // {
                              //   name: "github",
                              //   icon: <GithubOutlined />,
                              //   label: "Github",
                              // },
                            ]}
                          />
                        }
                      />

                      <Route
                        path="/register"
                        element={
                          <RegisterPage
                            wrapperProps={{
                              style: {
                                background:
                                  "url('/images/authpage-register.jpg')",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px",
                              },
                            }}
                            providers={[
                              {
                                name: "azure",
                                icon: <Icon component={MicrosoftIcon} />,
                                label: t(
                                  "pages.register.login_with_microsoft",
                                  "Sign in with Microsoft"
                                ),
                              },
                            ]}
                            title={t(
                              "pages.register.welcome",
                              "Sign up now and try Tellie for free and without obligation."
                            )}
                          />
                        }
                      />
                      <Route
                        path="/forgot-password"
                        element={
                          <AuthPage
                            type="forgotPassword"
                            wrapperProps={{
                              style: {
                                background:
                                  "linear-gradient(45deg, #3f96ff 30%, #6fC6ff 90%)",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px",
                              },
                            }}
                            title=""
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="/update-password"
                      element={
                        <AuthPage
                          type="updatePassword"
                          title={
                            <ThemedTitleV2
                              collapsed={false}
                              text="CRM"
                              icon={<AppIcon />}
                            />
                          }
                        />
                      }
                    />
                    <Route path="/verify" element={<VerifyPage />} />
                  </Routes>

                  <RefineKbar />
                  <UnsavedChangesNotifier />
                  <DocumentTitleHandler
                    handler={({ resource, action, params }) => {
                      return (
                        `${params?.id ? `#${params.id}` : ""} ${
                          action !== "list"
                            ? capitalizeEveryWord(action ?? "")
                            : ""
                        } ${capitalizeEveryWord(
                          resource?.name == "info_mails"
                            ? "Mailings"
                            : resource?.name ?? ""
                        )}`.trim() + " | Tellie"
                      );
                    }}
                  />
                </Refine>
              </DevtoolsProvider>
            </AntdApp>
          </ColorModeContextProvider>
        </RefineKbarProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;

import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { numberToCurrency } from "../../utility";

export interface PriceTier {
  quantity: number;
  price: number | null;
}

interface TieredPriceInputProps {
  initialPrices?: PriceTier[] | null;
  onPricesChange?: (prices: PriceTier[]) => void;
  // If used in Invoice row:
  invoiceView?: boolean;
  currentQuantity?: number;
  price_per_item?: number;
  disabled?: boolean;
  disableLabel?: boolean;
  displayText?: boolean;
}

const TieredPriceInput: React.FC<TieredPriceInputProps> = ({
  initialPrices,
  onPricesChange,
  invoiceView = false,
  currentQuantity,
  price_per_item,
  disabled,
  disableLabel,
  displayText = false,
}) => {
  const translate = useTranslate();

  // Always start with at least one tier.
  const safeInitialPrices: PriceTier[] =
    Array.isArray(initialPrices) && initialPrices.length > 0
      ? [...initialPrices]
      : [{ quantity: 1, price: price_per_item ?? 0 }];

  const [prices, setPrices] = useState<PriceTier[]>(safeInitialPrices);
  const [modalVisible, setModalVisible] = useState(false);

  // Manage new row entry for adding tiers.
  const [newEntry, setNewEntry] = useState<PriceTier>({
    quantity: safeInitialPrices[safeInitialPrices.length - 1].quantity + 1,
    price: null,
  });
  const [isNewEntryComplete, setIsNewEntryComplete] = useState(false);

  // Update state if initialPrices change.
  useEffect(() => {
    setPrices(safeInitialPrices);
    setNewEntry({
      quantity: safeInitialPrices[safeInitialPrices.length - 1].quantity + 1,
      price: null,
    });
  }, [initialPrices]);

  const handlePriceChange = (
    index: number,
    key: keyof PriceTier,
    value: string
  ) => {
    const newPrices = [...prices];
    newPrices[index] = { ...newPrices[index], [key]: Number(value) };
    setPrices(newPrices);
    onPricesChange?.(newPrices);
  };

  const handleNewEntryChange = (
    key: keyof PriceTier,
    value: string | number | null
  ) => {
    let newValue: number | null;
    if (typeof value === "number") {
      newValue = value;
    } else if (typeof value === "string") {
      newValue = value.trim() === "" ? null : Number(value);
    } else {
      newValue = null;
    }
    const updatedEntry = { ...newEntry, [key]: newValue };
    setNewEntry(updatedEntry);
    if (
      updatedEntry.quantity > prices[prices.length - 1].quantity &&
      updatedEntry.price !== null
    ) {
      setIsNewEntryComplete(true);
    } else {
      setIsNewEntryComplete(false);
    }
  };

  const confirmNewEntry = () => {
    if (isNewEntryComplete) {
      const updatedPrices = [...prices, newEntry].sort(
        (a, b) => a.quantity - b.quantity
      );
      setPrices(updatedPrices);
      setNewEntry({
        quantity: updatedPrices[updatedPrices.length - 1].quantity + 1,
        price: null,
      });
      setIsNewEntryComplete(false);
    }
  };

  const removePriceTier = (index: number) => {
    const newPrices = prices.filter((_, i) => i !== index);
    setPrices(newPrices);
  };

  const validateModalData = (): boolean => {
    for (let i = 0; i < prices.length; i++) {
      const tier = prices[i];
      if (tier.price === null || isNaN(tier.price)) return false;
      if (i > 0 && tier.quantity <= prices[i - 1].quantity) return false;
      if (i < prices.length - 1 && tier.quantity >= prices[i + 1].quantity)
        return false;
    }
    return true;
  };

  const getPriceForQuantity = (
    quantity: number,
    tiers: PriceTier[]
  ): number => {
    if (!tiers || tiers.length === 0) return 0;
    let applicablePrice = tiers[0].price as number;
    for (const tier of tiers) {
      if (quantity >= tier.quantity) {
        applicablePrice = tier.price as number;
      } else {
        break;
      }
    }
    return applicablePrice;
  };

  const validPrices = prices.filter((p) => p.price !== null && !isNaN(p.price));
  const lowestPrice =
    validPrices.length > 0
      ? Math.min(...validPrices.map((p) => p.price as number))
      : null;
  const highestPrice =
    validPrices.length > 0
      ? Math.max(...validPrices.map((p) => p.price as number))
      : null;

  // Compute the display text based on lowest and highest prices.
  const priceDisplayText =
    lowestPrice !== null &&
    highestPrice !== null &&
    lowestPrice === highestPrice
      ? numberToCurrency(lowestPrice)
      : `${numberToCurrency(lowestPrice ?? 0)} - ${numberToCurrency(
          highestPrice ?? 0
        )}`;

  // If displayText is true, render a simple text view.
  if (displayText) {
    return (
      <div>
        {!disableLabel && (
          <label>{translate("product_items.fields.price")}</label>
        )}
        <span>{priceDisplayText}</span>
      </div>
    );
  }

  const isSingleEditable = prices.length === 1;

  const handleModalOk = () => {
    if (!validateModalData()) {
      message.error("Bitte korrigieren Sie fehlerhafte Preise.");
      return;
    }
    onPricesChange?.(prices);
    setModalVisible(false);
  };

  const columns = [
    {
      title: "Menge ab",
      dataIndex: "quantity",
      render: (_: any, record: any, index: number) => (
        <Input
          type="number"
          value={record.isNew ? newEntry.quantity : record.quantity}
          onChange={(e) =>
            record.isNew
              ? handleNewEntryChange("quantity", e.target.value)
              : handlePriceChange(index, "quantity", e.target.value)
          }
          // Disable the first row if it’s not a new entry.
          disabled={!record.isNew && index === 0}
        />
      ),
    },
    {
      title: "Preis (€)",
      dataIndex: "price",
      render: (_: any, record: any, index: number) => (
        <InputNumber
          value={record.isNew ? newEntry.price : record.price}
          onChange={(value) =>
            record.isNew
              ? handleNewEntryChange("price", value)
              : handlePriceChange(index, "price", String(value))
          }
          onBlur={confirmNewEntry}
          decimalSeparator={translate("global.decimal_seperator")}
          precision={2}
          parser={(valueStr: any) => {
            const currentSeparator = translate("global.decimal_seperator");
            let sanitized = valueStr
              ? valueStr.toString().replace(/[^\d.,-]/g, "")
              : "";
            sanitized =
              currentSeparator === "."
                ? sanitized.replace(",", ".")
                : sanitized.replace(",", ".");
            return parseFloat(sanitized);
          }}
        />
      ),
    },
    {
      title: "Aktion",
      dataIndex: "action",
      render: (_: any, record: any, index: number) => {
        if (index == 0) return;
        return !record.isNew ? (
          <Button danger onClick={() => removePriceTier(index)}>
            Löschen
          </Button>
        ) : (
          <Button
            icon={<CloseOutlined />}
            onClick={() =>
              setNewEntry({
                quantity: prices[prices.length - 1].quantity + 1,
                price: null,
              })
            }
          />
        );
      },
    },
  ];

  const trigger =
    (invoiceView && currentQuantity !== undefined) || isSingleEditable ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Form.Item
          label={!invoiceView ? translate("product_items.fields.price") : null}
          noStyle={invoiceView}
        >
          <InputNumber
            value={
              (invoiceView && isSingleEditable) ||
              (!invoiceView && isSingleEditable)
                ? prices[0]?.price ?? ""
                : currentQuantity &&
                  getPriceForQuantity(currentQuantity, prices)
            }
            placeholder="Preis eingeben"
            onChange={(value) => handlePriceChange(0, "price", String(value))}
            decimalSeparator={translate("global.decimal_seperator")}
            precision={2}
            parser={(valueStr: any) => {
              const currentSeparator = translate("global.decimal_seperator");
              let sanitized = valueStr
                ? valueStr.toString().replace(/[^\d.,-]/g, "")
                : "";
              sanitized =
                currentSeparator === "."
                  ? sanitized.replace(",", ".")
                  : sanitized.replace(",", ".");
              return parseFloat(sanitized);
            }}
            readOnly={invoiceView && !isSingleEditable}
            addonAfter={!invoiceView && "EUR"}
            disabled={disabled}
          />
          <Button
            onClick={() => setModalVisible(true)}
            icon={<EditOutlined />}
            style={{ marginLeft: 8 }}
            disabled={disabled}
          />
        </Form.Item>
      </div>
    ) : (
      <Form.Item
        label={!disableLabel && translate("product_items.fields.price")}
        noStyle={disableLabel}
      >
        <Button onClick={() => setModalVisible(true)} disabled={disabled}>
          {priceDisplayText}
        </Button>
      </Form.Item>
    );

  return (
    <div>
      {trigger}
      <Modal
        title="Gestaffelte Preise"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleModalOk}
        okButtonProps={{ disabled: !validateModalData() }}
      >
        <Table
          dataSource={[...prices, { ...newEntry, isNew: true }]}
          columns={columns}
          rowKey={(_, idx) => idx?.toString() ?? Math.random().toString()}
          pagination={false}
        />
        {!validateModalData() && (
          <Alert
            style={{ marginTop: 10 }}
            message="Quantity should be in order from smallest number to biggest one and they cannot be equal"
            type="error"
            showIcon
          />
        )}
      </Modal>
    </div>
  );
};

export default TieredPriceInput;

import { Form, Select } from "antd";
import { useTranslate } from "@refinedev/core";
import InputSalutation from "../InputSalutation";

const SalutationSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.salutation.label")}
      name="salutation"
    >
      <InputSalutation />
    </Form.Item>
  );
};

export default SalutationSection;

import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useList, useOne, useTranslate } from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";
import { validateEmail } from "../../utility/validation";

interface Props {
  visible: boolean;
  onClose: () => void;
  document: any;
  type: "invoice" | "dunning";
}

const ResendDocumentModal: React.FC<Props> = ({
  visible,
  onClose,
  document,
  type,
}) => {
  const [form] = Form.useForm();
  const [method, setMethod] = useState<"email" | "letter">("letter");
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const { currentTeam } = useUser();
  const translate = useTranslate();

  const isInvoice = type === "invoice";
  const fileName = `${document.invoice_number}${
    isInvoice ? "" : "_mahnung"
  }.pdf`;
  const filePath = `teams/${currentTeam?.account_id}/invoices/${fileName}`;

  const { data: sender } = useOne({
    resource: "email_sender_addresses",
    id: currentTeam?.metadata?.module?.shop?.sender_email,
  });

  const { data: mailOutbox } = useList({
    resource: "mail_outbox",
    filters: document?.id
      ? [
          { field: "mail_outbox_type", operator: "eq", value: type },
          { field: "mail_outbox_id", operator: "eq", value: document.id },
        ]
      : [],
    pagination: { mode: "off" },
    queryOptions: { enabled: !!document?.id },
  });

  const formattedDate = dayjs(document?.date).format("DD.MM.YYYY");

  const getDefaults = () => {
    const to = [document.contact_email || document.contact?.email_invoices]
      .flat()
      .filter(Boolean);

    if (isInvoice) {
      return {
        subject: `Rechnung ${document.invoice_number}`,
        body: `Sehr geehrte Damen und Herren,

im Anhang finden Sie Ihre Rechnung ${document.invoice_number} vom ${formattedDate}.

Bei Fragen stehen wir Ihnen gerne zur Verfügung.

Mit freundlichen Grüßen,`,
        email: to,
      };
    }

    return {
      subject: `Zahlungserinnerung zur Rechnung ${document.invoice_number}`,
      body: `Sehr geehrte Damen und Herren,

anbei übersenden wir Ihnen eine Zahlungserinnerung. Vielleicht ist es Ihrer Aufmerksamkeit entgangen, dass unten stehende Posten zur Zahlung fällig sind. Wir bitten höflich um Ausgleich bis zum ${formattedDate}. 

Sollte sich unser Schreiben mit Ihrer Zahlung überschnitten haben, so betrachten Sie dieses Schreiben bitte als gegenstandslos.

Mit freundlichen Grüßen,`,
      email: to,
    };
  };

  const handleSend = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      const payload: any =
        method === "email"
          ? {
              from: sender?.data.email,
              to: values.email,
              attachments: [{ path: filePath, name: fileName }],
              body: values.body,
              subject: values.subject,
              account: currentTeam?.account_id,
              mail_outbox_type: type,
              mail_outbox_id: document.id,
              from_name: sender?.data?.name,
              signature: sender?.data?.signature,
            }
          : {
              type: "letter",
              attachments: [{ path: filePath, name: fileName }],
              account: currentTeam?.account_id,
              mail_outbox_type: type,
              mail_outbox_id: document.id,
            };

      const { error, data } = await supabaseClient.functions.invoke(
        "invoices/resend-invoice",
        {
          method: "POST",
          body: payload,
        }
      );

      if (error) throw error;

      message.success(translate(`settings.${type}s.resent_successfully`));
      onClose();
    } catch (error: any) {
      message.error(error.message || "Versand fehlgeschlagen");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={translate(`settings.${type}s.resend_title`)}
      open={visible}
      onCancel={onClose}
      onOk={handleSend}
      okText={translate("settings.invoices.send_now")}
      confirmLoading={loading}
      centered
    >
      <Form form={form} layout="vertical" initialValues={getDefaults()}>
        <Form.Item>
          <Radio.Group
            onChange={(e) => setMethod(e.target.value)}
            value={method}
          >
            <Radio value="letter">
              {translate("settings.invoices.letter")}
            </Radio>
            <Radio value="email">{translate("settings.invoices.email")}</Radio>
          </Radio.Group>
        </Form.Item>

        {method === "email" && (
          <>
            <Form.Item
              name="email"
              label={translate("settings.invoices.recipient_email")}
              rules={[
                { required: true, message: "Bitte geben Sie eine E-Mail an" },
                () => ({
                  validator(_, value: string[]) {
                    if (!value?.every(validateEmail)) {
                      return Promise.reject(
                        new Error("Ungültige E-Mail-Adresse")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select mode="tags" dropdownStyle={{ display: "none" }} />
            </Form.Item>

            <Form.Item name="subject" label="Betreff">
              <Input />
            </Form.Item>

            <Form.Item
              name="body"
              label="Nachricht"
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={8} />
            </Form.Item>

            <Form.Item label="Signatur">
              <Typography.Paragraph
                style={{ whiteSpace: "pre-wrap", color: "gray" }}
              >
                {sender?.data?.signature}
              </Typography.Paragraph>
            </Form.Item>
          </>
        )}

        {method === "letter" && <p>Dokument wird als Brief verschickt.</p>}
      </Form>

      <Button
        type="link"
        size="small"
        onClick={() => setShowHistory(!showHistory)}
      >
        {showHistory ? <MinusOutlined /> : <PlusOutlined />} Verlauf{" "}
        {showHistory ? "ausblenden" : "anzeigen"}
      </Button>

      {showHistory &&
        mailOutbox?.data.map((item) => (
          <Card key={item.id} size="small">
            <Row>
              <Col span={12}>
                <Descriptions column={1}>
                  <Descriptions.Item label="Empfänger">
                    {item.type === "email" && item.to}
                  </Descriptions.Item>
                  <Descriptions.Item label="Gesendet am">
                    {item.status === "sent" &&
                      dayjs(item.sent_at).format("DD.MM.YYYY HH:mm")}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={12}>
                <Descriptions column={1}>
                  <Descriptions.Item label="Status">
                    {translate(
                      `billing_details.mail.status.${item?.status ?? ""}`
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Zustellung">
                    {item?.delivered ? "-" : "Nicht bestätigt"}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Card>
        ))}
    </Modal>
  );
};

export default ResendDocumentModal;

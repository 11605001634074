import { HistoryOutlined } from "@ant-design/icons";
import { List as RefineList, useTable } from "@refinedev/antd";
import { useGo, useTranslate } from "@refinedev/core";
import { Button, List as AntList, Typography, Pagination, Space } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import { useUser } from "../../contexts/ContextProvider";
import i18n from "../../i18n";

dayjs.extend(relativeTime);

const ActivitiesList = () => {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const go = useGo();

  const { tableProps, setCurrent, setPageSize, tableQuery } = useTable({
    resource: "last_activities",
    meta: {
      select: "*",
    },
    filters: {
      permanent: [
        {
          field: "account",
          operator: "eq",
          value: currentTeam?.account_id,
        },
      ],
    },
    sorters: {
      initial: [{ field: "created_at", order: "desc" }],
    },
    pagination: {
      pageSize: 10,
    },
  });

  const renderActivityText = (activity: any) => {
    const contact = activity?.contact;
    const user = activity?.user?.name;
    let fullname = "";

    if (contact?.type === "person") {
      fullname = `${contact.firstname || ""} ${contact.lastname || ""}`.trim();
    } else if (contact?.type === "company") {
      fullname = contact.company_name?.trim() || "";
    }

    const language = i18n.language || "en";

    if (language === "en") {
      switch (activity.resource_name) {
        case "invoices":
          return `An invoice was created for ${fullname}`;
        case "orders":
          return `${user} placed an order for ${fullname}`;
        case "pipeline_items":
          return `${user} added a pipeline item for ${fullname}`;
      }
    }

    if (language === "de") {
      switch (activity.resource_name) {
        case "invoices":
          return `Eine Rechnung wurde für ${fullname} erstellt`;
        case "orders":
          return `${user} hat eine Bestellung für ${fullname} aufgegeben`;
        case "pipeline_items":
          return `${user} hat ein Pipeline-Element für ${fullname} hinzugefügt`;
      }
    }

    return "";
  };

  const handleView = (activity: any) => {
    if (activity.resource_name !== "pipeline_items") {
      go({
        to: {
          resource: activity.resource_name,
          action: "show",
          id: activity.resource_id,
        },
      });
    } else {
      // pipeline_items may not have a "show" route, so go to pipeline listing
      go({
        to: {
          resource: "pipelines",
          action: "list",
        },
      });
    }
  };

  const breadcrumbItems = [
    {
      icon: <HistoryOutlined />,
      title: translate("last_activities.title"),
    },
  ];

  return (
    <RefineList
      title={<CustomBreadcrumb items={breadcrumbItems} />}
      headerButtons={({ defaultButtons }) =>
        tableProps.dataSource && tableProps.dataSource.length > 0
          ? defaultButtons
          : null
      }
    >
      <AntList
        // You can set "bordered" or itemLayout, etc. as needed:
        // bordered
        // itemLayout="vertical"
        dataSource={tableQuery.data?.data || []}
        renderItem={(activity: any) => (
          <AntList.Item
            // If you want each row to look card-like, add style or a border
            style={{
              borderRadius: 6,
              marginBottom: 8,
              padding: 12,
            }}
            actions={[
              <Button
                type="link"
                size="small"
                onClick={() => handleView(activity)}
                key="view"
              >
                {translate("dashboard.view", "View")}
              </Button>,
            ]}
          >
            <AntList.Item.Meta
              title={
                <Typography.Text strong>
                  {renderActivityText(activity)}
                </Typography.Text>
              }
              description={
                <Typography.Paragraph
                  type="secondary"
                  style={{ marginBottom: 0 }}
                >
                  {dayjs(activity.created_at).fromNow()}
                </Typography.Paragraph>
              }
            />
          </AntList.Item>
        )}
        style={{ background: "white" }}
      />

      {/* Custom pagination at the bottom */}
      <Space
        style={{ marginTop: 24, width: "100%", justifyContent: "flex-end" }}
      >
        {tableProps.pagination && (
          <Pagination
            current={tableProps.pagination.current || 1}
            pageSize={tableProps.pagination.pageSize || 5}
            total={tableProps.pagination.total || 0}
            showSizeChanger
            onChange={(page, pageSize) => {
              setCurrent(page);
              setPageSize(pageSize);
            }}
          />
        )}
      </Space>
    </RefineList>
  );
};

export default ActivitiesList;

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";

const { Option } = Select;

const AddressPreview = ({
  onAddressSubmit,
}: {
  onAddressSubmit: (data: any) => void;
}) => {
  const translate = useTranslate();
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState("Home_Address");

  useEffect(() => {
    form.setFieldsValue({
      addresses: [{ type: "", label: "" }],
    });
  }, [form]);
  const renderCommonFields = (index: number) => (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={[index, "zip"]}
            label={translate("contacts.zip")}
            rules={[{ required: true }]}
          >
            <Input placeholder={translate("contacts.zip")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[index, "city"]}
            label={translate("contacts.city")}
            rules={[{ required: true }]}
          >
            <Input placeholder={translate("contacts.city")} />
          </Form.Item>
        </Col>
      </Row>
      {selectedType != "Bulk_Recipient_Address" && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={[index, "country"]}
              label={translate("contacts.country")}
            >
              <Select
                placeholder={translate("contacts.country")}
                options={[
                  { value: "de", label: translate("countries.DE") },
                  { value: "at", label: translate("countries.AT") },
                  { value: "ch", label: translate("countries.CH") },
                  { value: "us", label: translate("countries.US") },
                  { value: "fr", label: translate("countries.FR") },
                  { value: "uk", label: translate("countries.UK") },
                  { value: "es", label: translate("countries.ES") },
                  { value: "it", label: translate("countries.IT") },
                ]}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );

  const renderAddressFields = (type: string, index: number) => {
    let typeSpecificFields;
    switch (type) {
      case "Home_Address":
        typeSpecificFields = (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={[index, "address_supplement"]}
                label={translate("contacts.address_optional")}
              >
                <Input placeholder={translate("contacts.address_optional")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[index, "street"]}
                label={translate("contacts.street")}
                rules={[{ required: true }]}
              >
                <Input placeholder={translate("contacts.street")} />
              </Form.Item>
            </Col>
          </Row>
        );
        break;
      case "PO_Box_Address":
        typeSpecificFields = (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={[index, "street"]}
                label={translate("contacts.po_box_address")}
                rules={[{ required: true }]}
              >
                <Input placeholder={translate("contacts.po_box_address")} />
              </Form.Item>
            </Col>
          </Row>
        );
        break;
      case "Packing_Station":
        typeSpecificFields = (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={[index, "address_supplement"]}
                label={translate("contacts.post_number")}
              >
                <Input placeholder={translate("contacts.post_number")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[index, "street"]}
                label={translate("contacts.packstation")}
                rules={[{ required: true }]}
              >
                <Input placeholder={translate("contacts.packstation")} />
              </Form.Item>
            </Col>
          </Row>
        );
        break;

      case "Bulk_Recipient_Address":
        break;
      default:
        break;
    }

    return (
      <>
        {typeSpecificFields}
        {renderCommonFields(index)}
      </>
    );
  };
  const handleFormSubmit = () => {
    const values = form.getFieldsValue();
    onAddressSubmit(values.addresses);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(changedValues) => {
        if (Object.keys(changedValues).length > 0) {
          handleFormSubmit();
        }
      }}
      initialValues={{ type: selectedType }}
      onFinish={handleFormSubmit}
    >
      <Form.List name="addresses">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div
                key={key}
                style={{
                  position: "relative",
                  border: "1px solid #d9d9d9",
                  padding: "16px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                }}
              >
                <CloseOutlined
                  onClick={() => remove(key)}
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    cursor: "pointer",
                    color: "black",
                  }}
                />
                <Row gutter={16}>
                  {selectedType != "Bulk_Recipient_Address" && (
                    <Col span={12}>
                      <Form.Item
                        name={[name, "label"]}
                        label={translate("contacts.label")}
                      >
                        <Input placeholder={translate("contacts.label")} />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={12}>
                    <Form.Item
                      name={[name, "type"]}
                      label={translate("contacts.address_modal.type")}
                    >
                      <Select
                        placeholder={translate("contacts.address_modal.type")}
                        onChange={(value) => setSelectedType(value)}
                      >
                        <Option value="Home_Address">
                          {translate("contacts.address_modal.home_address")}
                        </Option>
                        <Option value="PO_Box_Address">
                          {translate("contacts.address_modal.po_box_address")}
                        </Option>
                        <Option value="Packing_Station">
                          {translate("contacts.address_modal.packing_station")}
                        </Option>
                        <Option value="Bulk_Recipient_Address">
                          {translate(
                            "contacts.address_modal.bulk_recipient_address"
                          )}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {form.getFieldValue(["addresses", name, "type"]) &&
                  renderAddressFields(
                    form.getFieldValue(["addresses", name, "type"]),
                    name
                  )}{" "}
              </div>
            ))}

            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={() => add()}
              style={{ width: "100%" }}
            >
              {translate("buttons.add_address")}
            </Button>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default AddressPreview;

import { useSelect } from "@refinedev/antd";
import { useUser } from "../../contexts/ContextProvider";

export const useShippingTableSelect = () => {
  const { currentTeam } = useUser();

  return useSelect<{
    id: string;
    name: string;
  }>({
    meta: {
      select: "*",
    },
    optionLabel: "name",
    optionValue: "id",
    resource: "shipping_tables",
    filters: [
      {
        field: "account",
        value: currentTeam?.account_id,
        operator: "eq",
      },
    ],
  });
};

import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Button, DatePicker, Flex, Popconfirm, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { BillingType } from "../../../interfaces/phase.interface";

interface SubscriptionPhaseShowStartEndProps {
  item: {
    start_date: string;
    end_date: string | null;
  };
  index: number;
  handlePhaseDelete?: (index: number) => void;
  onDateChange?: (
    index: number,
    field: "start_date" | "end_date",
    value: string | null
  ) => void;
}

const SubscriptionPhaseShowStartEnd: React.FC<
  SubscriptionPhaseShowStartEndProps
> = ({ item, index, handlePhaseDelete, onDateChange }) => {
  const translate = useTranslate();
  const [canEditStartDate, setCanEditStartDate] = useState(false);
  const [canEditEndDate, setCanEditEndDate] = useState(false);

  useEffect(() => {
    const now = dayjs();
    const startDate = dayjs(item.start_date);
    const endDate = item.end_date ? dayjs(item.end_date) : null;

    // Determine edit permissions based on the three conditions

    // a) Both dates in the past - no editing allowed
    if (
      startDate.isBefore(now, "day") &&
      endDate &&
      endDate.isBefore(now, "day")
    ) {
      setCanEditStartDate(false);
      setCanEditEndDate(false);
    }
    // b) Start date in past, end date in future (active phase) - only end date can be changed
    else if (
      startDate.isBefore(now, "day") &&
      endDate &&
      endDate.isAfter(now, "day")
    ) {
      setCanEditStartDate(false);
      setCanEditEndDate(true);
    }
    // c) Both dates in future (upcoming phase) - both dates can be changed
    else if (startDate.isAfter(now, "day")) {
      setCanEditStartDate(true);
      setCanEditEndDate(true);
    }
  }, [item.start_date, item.end_date]);

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    if (onDateChange && date) {
      const adjustedDate = date.hour(12).minute(0).second(0).millisecond(0);
      onDateChange(index, "start_date", adjustedDate.format("YYYY-MM-DD"));
    }
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    if (onDateChange) {
      if (date) {
        const adjustedDate = date.hour(12).minute(0).second(0).millisecond(0);
        onDateChange(index, "end_date", adjustedDate.format("YYYY-MM-DD"));
      } else {
        onDateChange(index, "end_date", null);
      }
    }
  };

  return (
    <Flex align="center" gap={16}>
      {canEditStartDate ? (
        <DatePicker
          defaultValue={dayjs(item.start_date)}
          format={translate("global.date_format")}
          onChange={handleStartDateChange}
          allowClear={false}
        />
      ) : (
        <Typography.Title level={5} style={{ margin: 0 }}>
          {dayjs(item.start_date).format(translate("global.date_format"))}
        </Typography.Title>
      )}

      <ArrowRightOutlined style={{ fontSize: 14 }} />

      {canEditEndDate ? (
        <DatePicker
          defaultValue={item.end_date ? dayjs(item.end_date) : undefined}
          format={translate("global.date_format")}
          onChange={handleEndDateChange}
          allowClear={true}
        />
      ) : (
        <Typography.Title level={5} style={{ margin: 0 }}>
          {item.end_date == null
            ? translate(
                "subscription_products.fields.billing_type." + BillingType.Month
              )
            : dayjs(item.end_date).format(translate("global.date_format"))}
        </Typography.Title>
      )}

      {handlePhaseDelete && (
        <Popconfirm
          title={translate("subscription_products.buttons.delete.title_single")}
          description={translate(
            "subscription_products.buttons.delete.description_single"
          )}
          onConfirm={() => {
            handlePhaseDelete(index);
          }}
          okText={translate("buttons.yes", "Yes")}
          cancelText={translate("buttons.no", "No")}
        >
          <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            style={{ fontSize: "0.80rem", padding: 7 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {translate("subscription_products.show.delete_phase")}
          </Button>
        </Popconfirm>
      )}
    </Flex>
  );
};

export default SubscriptionPhaseShowStartEnd;

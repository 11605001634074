import React, { useEffect, useState } from "react";
import { Select } from "antd";
import {useList, useTranslate} from "@refinedev/core";
import {useUser} from "../../contexts/ContextProvider";

type InputSalutationProps = {
  value?: string; // Kontrollierter Wert
  onChange?: (value: string) => void; // Event für Wertänderungen
};

const InputSalutation: React.FC<InputSalutationProps> = ({ value, onChange }) => {
  const [salutations, setSalutations] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const translate = useTranslate();
  const { currentTeam } = useUser();

  const { data: contactsList } = useList({
    resource: "contacts",
    pagination: { mode: "off" },
    filters: [
      {
        field: "account",
        value: currentTeam?.account_id,
        operator: "eq",
      },
    ],
    meta: { select: "salutation" },
  });

  useEffect(() => {
    if (contactsList?.data) {
      const uniqueSalutations = Array.from(
        new Set(contactsList.data.map((contact) => contact.salutation).filter(Boolean))
      );
      setSalutations(uniqueSalutations);
    }
  }, [contactsList]);

  return (
    <Select
      showSearch
      style={{ width: "100%" }}
      placeholder={translate("contacts.select_salutation")}
      optionFilterProp="children"
      allowClear
      value={value} // Wert aus dem Formular übernehmen
      onChange={(val) => {
        if (onChange) onChange(val); // Aktualisierung im Formular
        if (!salutations.includes(val)) {
          setSalutations([...salutations, val]); // Benutzerdefinierte Eingabe hinzufügen
        }
      }}
      onSearch={(val) => setInputValue(val)} // Benutzerdefinierte Eingabe zwischenspeichern
      onBlur={() => {
        if (inputValue && !salutations.includes(inputValue)) {
          setSalutations([...salutations, inputValue]); // Benutzerdefinierten Wert hinzufügen
          if (onChange) onChange(inputValue);
        }
      }}
      filterOption={(input, option) =>
        option?.children?.toString().toLowerCase().includes(input.toLowerCase()) ?? false
      }
    >
      {salutations?.length && salutations.map((salutation) => (
        (salutation && salutation != "") && (
          <Select.Option key={salutation} value={salutation}>
            {salutation}
          </Select.Option>
        )
      ))}
    </Select>
  );
};

export default InputSalutation;

import { PlusOutlined } from "@ant-design/icons";
import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useTranslate } from "@refinedev/core";
import { Button, Flex, Form, InputNumber, Space } from "antd";
import { useEffect, useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import { roundToTwoDecimals } from "../../../utility";
import { calculateShippingCostForLineItem } from "../../../utility/calculateShipping";
import { formatNumber } from "../../../utility/formatNumber";
import InvoiceProductRow from "./InvoiceProductRow";
import "./InvoiceTable.css";

type ShippingData = {
  cost: number; // shipping cost (€)
  discount: number; // discount % (0 - 100)
  tax_rate: number;
};

type Props = {
  initialValues?: any[];
  initialShipping?: ShippingData;
  onProductsChange?: (products: any[]) => void;
  onTotalChange?: (totals: any) => void;
  onShippingCost?: (shippingData: {
    cost: number;
    discount: number;
    tax_rate: number;
  }) => void;
  subscriptionProduct?: boolean;
  contactId?: string | number;
};

const InvoiceTable = ({
  initialValues = [],
  initialShipping = { cost: 0, discount: 0, tax_rate: 0 },
  onProductsChange,
  onTotalChange,
  onShippingCost,
  subscriptionProduct = false,
  contactId,
}: Props) => {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const vat_exempt = currentTeam?.metadata?.module?.shop?.vat_exempt;
  // -----------------------------
  // 1) Products State
  // -----------------------------
  const [products, setProducts] = useState(
    initialValues.length > 0
      ? initialValues
      : [
          {
            id: "product-1",
            position: 1,
            type: "item",
            name: "",
            description: "",
            reference_id: null,
            price_per_item: 0,
            variant: null,
            tax_rate: vat_exempt ? 0 : 19,
            discount: 0,
            quantity: 1,
          },
        ]
  );
  const [idCounter, setIdCounter] = useState(products.length + 1);

  // -----------------------------
  // 2) Shipping State
  // -----------------------------
  const [shippingData, setShippingData] =
    useState<ShippingData>(initialShipping);

  // -----------------------------
  // 3) Totals State
  // -----------------------------
  const [totals, setTotals] = useState<any>();

  // -----------------------------
  // Lifecycle
  // -----------------------------
  useEffect(() => {
    const updateTotals = async () => {
      // Map products without the 'position' field if needed.
      const mappedProducts = products.map(
        ({ position, ...productData }) => productData
      );

      // Call your async totals function.
      const newTotals = await calculateTotalsAsync();
      setTotals(newTotals);

      if (onProductsChange) {
        onProductsChange(mappedProducts);
      }
      if (onTotalChange) {
        onTotalChange(newTotals);
      }
      // Update shippingData if needed (adjust field names if necessary)
      setShippingData((prev) => ({
        ...prev,
        tax_rate: newTotals.shipping_tax_rate, // assuming newTotals includes this field
      }));
    };

    updateTotals();
  }, [products]);

  useEffect(() => {
    const updateTotals = async () => {
      const newTotals = await calculateTotalsAsync();
      setTotals(newTotals);

      if (onShippingCost) {
        onShippingCost({
          cost: shippingData?.cost,
          discount: shippingData.discount,
          tax_rate: newTotals.shipping_tax_rate,
        });
      }
      if (onTotalChange) {
        onTotalChange(newTotals);
      }
    };

    updateTotals();
  }, [shippingData]);

  // -----------------------------
  // 4) Handlers
  // -----------------------------

  // a) Product row updates
  const handleSaveRow = (updatedProduct, index) => {
    setProducts((prev) =>
      prev.map((product, idx) => {
        if (idx === index) {
          return { ...updatedProduct };
        }
        return product;
      })
    );
  };

  const handleDeleteRow = (index) => {
    setProducts((prev) =>
      prev
        .filter((_, idx) => idx !== index - 1)
        .map((product, newIndex) => ({ ...product, position: newIndex + 1 }))
    );
  };

  const handleAddRow = () => {
    setProducts((prev) => [
      ...prev,
      {
        id: `product-${idCounter}`,
        position: prev.length + 1,
        type: "item",
        name: "",
        description: "",
        reference_id: null,
        price_per_item: 0,
        variant: null,
        tax_rate: 19,
        // unit: "piece",
        discount: 0,
        quantity: 1,
      },
    ]);
    setIdCounter(idCounter + 1);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setProducts((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);

        // Update positions
        return newItems.map((item: any, idx) => ({
          ...item,
          position: idx + 1,
        }));
      });
    }
  };

  // b) Shipping cost input changes
  const handleShippingCostChange = (cost: number) => {
    setShippingData((prev) => ({
      ...prev,
      cost: cost || 0,
    }));
  };

  const handleShippingDiscountChange = (discount: number) => {
    setShippingData((prev) => ({
      ...prev,
      discount: discount || 0,
    }));
  };

  // -----------------------------
  // 5) Calculation
  // -----------------------------
  const calculateTotalsAsync = async () => {
    // 1. Calculate product subtotals
    const subtotal19 = products
      .filter((product) => product.tax_rate === 19)
      .reduce(
        (sum, product) =>
          sum +
          product.quantity *
            product.price_per_item *
            (1 - product.discount / 100),
        0
      );
    const subtotal7 = products
      .filter((product) => product.tax_rate === 7)
      .reduce(
        (sum, product) =>
          sum +
          product.quantity *
            product.price_per_item *
            (1 - product.discount / 100),
        0
      );
    const subtotal0 = products
      .filter((product) => product.tax_rate === 0)
      .reduce(
        (sum, product) =>
          sum +
          product.quantity *
            product.price_per_item *
            (1 - product.discount / 100),
        0
      );

    // Tax amounts for products
    const tax19 = roundToTwoDecimals(subtotal19 * 0.19);
    const tax7 = roundToTwoDecimals(subtotal7 * 0.07);
    const tax0 = 0;

    // 2. Calculate shipping totals by fetching fresh shipping rates for line items
    let shippingNet19 = 0;
    let shippingNet7 = 0;
    let shippingNet0 = 0;

    // Calculate shipping costs from line items
    for (const product of products) {
      if (product.shipping_tables) {
        // Fetch the fresh shipping cost for the product
        const shippingCost = await calculateShippingCostForLineItem(product);
        // Group shipping costs based on the product's tax rate
        if (product.tax_rate === 19) {
          shippingNet19 += shippingCost;
        } else if (product.tax_rate === 7) {
          shippingNet7 += shippingCost;
        } else {
          shippingNet0 += shippingCost;
        }
      }
    }

    // 3. Handle the general shipping cost and apply appropriate tax rate
    let generalShippingCost = 0;
    if (shippingData?.cost > 0) {
      // Apply discount to general shipping cost
      generalShippingCost =
        shippingData.cost * (1 - shippingData.discount / 100);

      // Determine which tax rate to apply based on product totals
      const subtotals = [
        { rate: 19, amount: subtotal19 },
        { rate: 7, amount: subtotal7 },
        { rate: 0, amount: subtotal0 },
      ];

      // Sort by amount (descending) to find the tax rate with the highest total
      subtotals.sort((a, b) => b.amount - a.amount);

      // Apply general shipping to the tax rate with highest total
      if (subtotals[0].amount > 0) {
        const highestTaxRate = subtotals[0].rate;
        if (highestTaxRate === 19) {
          shippingNet19 += generalShippingCost;
        } else if (highestTaxRate === 7) {
          shippingNet7 += generalShippingCost;
        } else {
          shippingNet0 += generalShippingCost;
        }
      } else {
        // If no products, default to 19%
        shippingNet19 += generalShippingCost;
      }
    }

    // Calculate shipping taxes for each group
    const shippingTax19 = roundToTwoDecimals(shippingNet19 * 0.19);
    const shippingTax7 = roundToTwoDecimals(shippingNet7 * 0.07);
    const shippingTax0 = 0;

    // Total shipping net and tax
    const totalShippingNet = shippingNet19 + shippingNet7 + shippingNet0;
    const totalShippingTax = shippingTax19 + shippingTax7 + shippingTax0;
    const shippingGross = totalShippingNet + totalShippingTax;

    // 4. Determine the primary shipping tax rate for display/reference
    let shippingTaxRate = 0;
    if (shippingNet19 > 0 && shippingNet7 === 0 && shippingNet0 === 0) {
      shippingTaxRate = 19;
    } else if (shippingNet7 > 0 && shippingNet19 === 0 && shippingNet0 === 0) {
      shippingTaxRate = 7;
    } else if (shippingNet0 > 0 && shippingNet19 === 0 && shippingNet7 === 0) {
      shippingTaxRate = 0;
    } else if (shippingNet19 > 0 || shippingNet7 > 0 || shippingNet0 > 0) {
      // Find highest shipping net value
      const shippingAmounts = [
        { rate: 19, amount: shippingNet19 },
        { rate: 7, amount: shippingNet7 },
        { rate: 0, amount: shippingNet0 },
      ];
      shippingAmounts.sort((a, b) => b.amount - a.amount);
      shippingTaxRate = shippingAmounts[0].rate;
    }

    // 5. Combine totals for products and shipping
    const netTotal = subtotal19 + subtotal7 + subtotal0 + totalShippingNet;
    const taxTotal = tax19 + tax7 + tax0 + totalShippingTax;
    const grandTotal =
      roundToTwoDecimals(netTotal) + roundToTwoDecimals(taxTotal);

    return {
      subtotal19: roundToTwoDecimals(subtotal19),
      subtotal7: roundToTwoDecimals(subtotal7),
      subtotal0: roundToTwoDecimals(subtotal0),
      tax19: roundToTwoDecimals(tax19),
      tax7: roundToTwoDecimals(tax7),
      tax0: roundToTwoDecimals(tax0),
      // Shipping breakdown
      shipping_net: roundToTwoDecimals(totalShippingNet),
      shipping_tax: roundToTwoDecimals(totalShippingTax),
      shipping_breakdown: {
        "19%": {
          net: roundToTwoDecimals(shippingNet19),
          tax: roundToTwoDecimals(shippingTax19),
        },
        "7%": {
          net: roundToTwoDecimals(shippingNet7),
          tax: roundToTwoDecimals(shippingTax7),
        },
        "0%": {
          net: roundToTwoDecimals(shippingNet0),
          tax: roundToTwoDecimals(shippingTax0),
        },
      },
      shipping_gross: roundToTwoDecimals(shippingGross),
      // Added shipping_tax_rate property
      shipping_tax_rate: shippingTaxRate,
      // Grand total
      total: grandTotal,
    };
  };

  // -----------------------------
  // 6) Rendering
  // -----------------------------
  return (
    <div
      style={{
        width: "100%",
        border: "1px solid #ddd",
        borderRadius: 5,
        padding: 12,
      }}
    >
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={products.map((product) => product.id)}
          strategy={verticalListSortingStrategy}
        >
          <table className="invoice-table">
            <thead>
              <tr>
                <th>{translate("invoice_table.pos")}</th>
                <th>{translate("invoice_table.product")}</th>
                <th>{translate("invoice_table.quantity")}</th>
                {/* <th>{translate("invoice_table.unit")}</th> */}
                <th>{translate("invoice_table.price")}</th>
                {!vat_exempt && <th>{translate("invoice_table.tax")}</th>}
                <th>{translate("invoice_table.discount")}</th>
                <th>{translate("invoice_table.total")}</th>
                <th></th>
              </tr>
            </thead>

            {products.map((product, index) => (
              <InvoiceProductRow
                key={product.id}
                id={product.id ?? Math.random()}
                data={product}
                isEditing={true}
                onSave={(val) => handleSaveRow(val, index)}
                onDelete={handleDeleteRow}
                subscriptionProduct={!subscriptionProduct && index === 0}
                order={index + 1}
                contactId={contactId}
                vat_exempt={vat_exempt}
              />
            ))}
          </table>
        </SortableContext>
      </DndContext>

      <Flex justify="space-between">
        <Space direction="vertical" size="large">
          <Button
            type="dashed"
            onClick={handleAddRow}
            icon={<PlusOutlined />}
            className="add-row-button"
          >
            {translate("invoice_table.new_position")}
          </Button>
        </Space>

        <div>
          <Space style={{ paddingTop: "10px" }}>
            <Form.Item label={translate("invoice_table.shipping_cost")}>
              <InputNumber
                value={shippingData?.cost}
                decimalSeparator={translate("global.decimal_seperator")}
                precision={2}
                min={0}
                onChange={(cost) => {
                  handleShippingCostChange(cost ?? 0); // Default to 0 when cleared
                }}
                parser={(valueStr: any) => {
                  const currentSeparator = translate(
                    "global.decimal_seperator"
                  );
                  let sanitized = valueStr.replace(/[^\d.,-]/g, "");

                  if (currentSeparator === ".") {
                    sanitized = sanitized.replace(",", ".");
                  } else {
                    sanitized = sanitized.replace(",", ".");
                  }

                  return sanitized ? parseFloat(sanitized) : 0; // Default to 0 when empty
                }}
              />
            </Form.Item>

            {/* Shipping cost discount (%) */}
            <Form.Item label={translate("invoice_table.shipping_discount")}>
              <InputNumber
                min={0}
                max={100}
                value={shippingData?.discount}
                decimalSeparator={translate("global.decimal_seperator")}
                onChange={(discount: number | null) =>
                  handleShippingDiscountChange(discount ?? 0)
                }
              />
            </Form.Item>
            {/* Shipping cost */}
          </Space>

          {/* Totals Breakdown */}
          <div className="totals">
            {totals?.subtotal19 != 0 && (
              <div>
                <span>{translate("invoice_table.subtotal")} (19%): </span>
                <span>{formatNumber(totals?.subtotal19)} €</span>
              </div>
            )}
            {totals?.subtotal7 != 0 && (
              <div>
                <span>{translate("invoice_table.subtotal")} (7%): </span>
                <span>{formatNumber(totals?.subtotal7)} €</span>
              </div>
            )}
            {totals?.subtotal0 != 0 && (
              <div>
                <span>{translate("invoice_table.subtotal")} (0%): </span>
                <span>{formatNumber(totals?.subtotal0)} €</span>
              </div>
            )}
            {totals?.tax19 != 0 && (
              <div>
                <span>{translate("invoice_table.taxes")} (19%): </span>
                <span>{formatNumber(totals?.tax19)} €</span>
              </div>
            )}
            {totals?.tax7 != 0 && (
              <div>
                <span>{translate("invoice_table.taxes")} (7%): </span>
                <span>{formatNumber(totals?.tax7)} €</span>
              </div>
            )}

            {/* Show shipping breakdown by tax rate */}
            {totals?.shipping_breakdown &&
              totals?.shipping_breakdown["19%"].net > 0 && (
                <div>
                  <span>Shipping 19% (net): </span>
                  <span>
                    {formatNumber(totals?.shipping_breakdown["19%"].net)} €
                  </span>
                </div>
              )}
            {totals?.shipping_breakdown &&
              totals?.shipping_breakdown["19%"].tax > 0 && (
                <div>
                  <span>Shipping tax (19%): </span>
                  <span>
                    {formatNumber(totals?.shipping_breakdown["19%"].tax)} €
                  </span>
                </div>
              )}

            {totals?.shipping_breakdown &&
              totals?.shipping_breakdown["7%"].net > 0 && (
                <div>
                  <span>Shipping 7% (net): </span>
                  <span>
                    {formatNumber(totals?.shipping_breakdown["7%"].net)} €
                  </span>
                </div>
              )}
            {totals?.shipping_breakdown &&
              totals?.shipping_breakdown["7%"].tax > 0 && (
                <div>
                  <span>Shipping tax (7%): </span>
                  <span>
                    {formatNumber(totals?.shipping_breakdown["7%"].tax)} €
                  </span>
                </div>
              )}

            {totals?.shipping_breakdown &&
              totals?.shipping_breakdown["0%"].net > 0 && (
                <div>
                  <span>Shipping 0% (net): </span>
                  <span>
                    {formatNumber(totals?.shipping_breakdown["0%"].net)} €
                  </span>
                </div>
              )}

            {/* Grand total */}
            <div className="total-amount">
              <span>{translate("invoice_table.total_amount")}: </span>
              <span>{formatNumber(totals?.total)} €</span>
            </div>
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default InvoiceTable;

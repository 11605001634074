import { useModalForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Button, Col, Form, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import useTeam from "../../hooks/useTeam";
import { getContactFormattedName } from "../../utility/contactName";
import AddressAutoselect from "../common/AddressAutoselect";
import ContactAutoSelect from "../contacts/ContactAutoSelect";
import InvoiceTable from "../shop/product-items";

export default function CreateOrEditInvoice({
  open,
  invoice,
  onClose,
  contact,
}: {
  open?: boolean;
  invoice?: any;
  onClose: () => void;
  contact?: any;
}) {
  const translate = useTranslate();
  const { currentTeam } = useTeam();

  const { formProps, modalProps, form } = useModalForm({
    resource: "invoices",
    action: invoice?.id ? "edit" : "create",
    id: invoice?.id,
    onMutationSuccess: () => {
      onClose();
    },
    redirect: contact ? false : "list",
  });
  const [lineItems, setLineItems] = useState<any>();
  const [total, setTotal] = useState<any>();
  const [shippingTotal, setShippingTotal] = useState<any>();
  const [selectedContactId, setSelectedContactId] = useState(
    contact?.id || null
  );
  const [selectedContact, setSelectedContact] = useState<any>(contact || null);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [selectedAddressId, setSelectedAddressId] = useState<
    string | number | null
  >(null);
  const handleCreateInvoice = async () => {
    await form?.validateFields();
    formProps.onFinish?.({
      account_address: selectedAddress,
      account_country: "DE",
      account_name: currentTeam?.name,
      account_tax_id: "123",
      line_items: lineItems,
      amount: total?.total,
      amount_paid: 0,
      amount_shipping: 0,
      account: currentTeam?.account_id,
      contact_email: selectedContact?.email,
      contact_name: getContactFormattedName(selectedContact),
      date: new Date(),
      //status: "finished",
      contact: selectedContact?.id,
      contact_address: selectedAddress,
      shipping: shippingTotal,
    });
  };

  const handleSaveAsDraft = async () => {
    await form?.validateFields();
    const values: any = form?.getFieldsValue();
    await formProps.onFinish?.({
      ...values,
      account_address: selectedAddress,
      account_country: "DE",
      account_name: currentTeam?.name,
      account_tax_id: "123",
      line_items: lineItems,
      amount: total?.total,
      amount_paid: 0,
      amount_shipping: 0,
      account: currentTeam?.account_id,
      contact_email: selectedContact?.email,
      contact_name: getContactFormattedName(selectedContact),
      date: new Date(),
      status: "unfinished",
      contact: selectedContact?.id,
      contact_address: selectedAddress,
      shipping: shippingTotal,
    });
  };
  useEffect(() => {
    if (invoice) {
      form.resetFields();
      form.setFieldsValue({
        contact: invoice.contact_name,
        contact_address: invoice.contact_address
          ? `${invoice.contact_address.street} ${invoice.contact_address.zip} ${invoice.contact_address.city}`
          : "No address provided",
        amount: invoice.amount,
      });
    }
  }, [invoice, form, formProps.initialValues]);
  return (
    <Modal
      {...modalProps}
      open={open}
      onCancel={onClose}
      width={1050}
      title={
        invoice?.id
          ? translate("settings.invoices.edit_invoice", "Edit Invoice")
          : translate("settings.invoices.create_invoice", "Create Invoice")
      }
      footer={[
        <Button key="cancel" onClick={onClose}>
          {translate("buttons.cancel", "Cancel")}
        </Button>,
        !invoice?.id && (
          <Button
            key="save-as-draft"
            onClick={handleSaveAsDraft}
            type="default"
          >
            {translate("buttons.save_as_draft", "Save as Draft")}
          </Button>
        ),
        <Button
          key="create-invoice"
          onClick={handleCreateInvoice}
          type="primary"
        >
          {invoice?.id
            ? translate("buttons.save")
            : translate("settings.invoices.create_invoice")}
        </Button>,
      ]}
    >
      <Form {...formProps} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            {contact ? (
              <>
                <Form.Item name="contact">
                  <ContactAutoSelect
                    label={translate("settings.invoices.fields.enter_customer")}
                    required={true}
                    name="contact"
                    onSelect={(value) => {
                      setSelectedContactId(value);
                      form.setFieldValue("contact_address", null);
                    }}
                    getContact={(value) => setSelectedContact(value)}
                  />
                </Form.Item>
                <Form.Item>
                  <AddressAutoselect
                    contactId={selectedContactId}
                    name="contact_address"
                    label={translate("contacts.address")}
                    onSelect={(value) => {
                      setSelectedAddressId(value);
                    }}
                    required={false}
                    getAddress={(value) => setSelectedAddress(value)}
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item name="contact">
                  <ContactAutoSelect
                    label={translate("settings.invoices.fields.enter_customer")}
                    required={true}
                    name="contact"
                    onSelect={(value) => {
                      setSelectedContactId(value);
                      setSelectedAddressId(null);
                    }}
                    getContact={(value) => setSelectedContact(value)}
                  />
                </Form.Item>
                {selectedContactId && (
                  <Form.Item>
                    <AddressAutoselect
                      contactId={selectedContactId}
                      name="contact_address"
                      label={translate("contacts.address")}
                      onSelect={(value) => {
                        setSelectedAddressId(value);
                      }}
                      getAddress={(value) => {
                        if (
                          !value.country ||
                          ~[2, 3].includes(value.country.length)
                        ) {
                          formProps.form?.setFields([
                            {
                              name: "contact_address",
                              errors: [
                                "Please set or reset the country on the address.",
                              ],
                            },
                          ]);
                        }
                        setSelectedAddress(value);
                      }}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </Col>
          <InvoiceTable
            initialValues={invoice?.line_items || []} // Pass existing line items
            onProductsChange={(value) => setLineItems(value)}
            onTotalChange={(value) => {
              setTotal(value);
            }}
            onShippingCost={(value) => {
              setShippingTotal(value);
            }}
            initialShipping={invoice?.shipping}
          />
        </Row>
      </Form>
    </Modal>
  );
}

import { useList, useTranslate } from "@refinedev/core";
import { Form, Input, InputNumber, Select, Space } from "antd";
import React, { useMemo } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import { usePipelineContext } from "../../../contexts/PipelineProvider";
import { Category } from "../../../interfaces/shop.interface";
import { updateCategoryPathnames } from "../../../utility/shop";
import CategoryAutoselect from "../CategoryAutoselect";
import TieredPriceInput from "../TieredPriceInput";

interface ProductInfoFieldsProps {
  form: any;
  prefix?: string;
  namePrefix?: string;
  onPricesChange?: (value: any) => void;
  initialPrices?: any;
  pricePerItem?: number;
  shippingTableProps: any;
  shippingTableLoading?: boolean;
  isVariant?: boolean;
  disabled?: boolean;
  intialCategories?: any;
  hideTitle?: boolean;
}

const ProductInfoFields: React.FC<ProductInfoFieldsProps> = ({
  form,
  prefix = "",
  namePrefix = "",
  onPricesChange,
  initialPrices,
  pricePerItem,
  shippingTableProps,
  shippingTableLoading,
  isVariant = false,
  disabled = false,
  intialCategories,
  hideTitle = false,
}) => {
  const translate = useTranslate();
  const { canAccess } = usePipelineContext();
  const { currentTeam } = useUser();

  // Build field names with prefix if provided.
  const fieldName = (name: string) =>
    namePrefix ? `${namePrefix}_${name}` : name;

  const itemsCategory = useMemo(
    () => intialCategories?.map((item) => item.id),
    [intialCategories]
  );
  const { data: categoryList } = useList<Category>({
    resource: "product_categories",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const categoriesWithPath = useMemo(
    () =>
      updateCategoryPathnames(categoryList?.data ?? []).filter((item) =>
        itemsCategory?.includes(item.id)
      ),
    [categoryList?.data, itemsCategory]
  );
  return (
    <>
      {!hideTitle && (
        <Form.Item
          name={"title"}
          label={translate("product_items.fields.title")}
        >
          <Input />
        </Form.Item>
      )}
      {(isVariant || !disabled) && (
        <>
          <Form.Item
            name={fieldName("description")}
            label={translate("product_items.fields.description")}
          >
            <Input.TextArea autoSize style={{ maxHeight: "20rem" }} />
          </Form.Item>

          <Form.Item
            name={fieldName("ean")}
            label={translate("product_items.fields.ean", "EAN")}
          >
            <Input />
          </Form.Item>
          {/* Hidden field to register price_object */}
          <Form.Item
            name={fieldName("price_object")}
            initialValue={initialPrices}
            style={{ display: "none" }}
          >
            <Input hidden />
          </Form.Item>
          <Space align="center" style={{ width: "100%" }}>
            <TieredPriceInput
              onPricesChange={(value) => {
                // Update the hidden field so its value is captured in the form
                form.setFieldsValue({ [fieldName("price_object")]: value });
                if (onPricesChange) {
                  onPricesChange(value);
                }
              }}
              initialPrices={initialPrices}
              price_per_item={pricePerItem}
            />
            {currentTeam?.metadata?.module?.shop?.vat_exempt ? null : (
              <Form.Item
                name={fieldName("sales_tax")}
                label={translate("product_items.fields.sales_tax")}
              >
                <Select popupMatchSelectWidth={false}>
                  <Select.Option value={19}>19%</Select.Option>
                  <Select.Option value={7}>7%</Select.Option>
                  <Select.Option value={0}>0%</Select.Option>
                </Select>
              </Form.Item>
            )}
          </Space>

          <Space align="center" style={{ width: "100%" }}>
            <Form.Item
              name={fieldName("weight")}
              label={translate("product_items.fields.weight")}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name={fieldName("shipping_table")}
              label={translate("product_items.fields.shipping_table")}
            >
              <Select
                {...shippingTableProps}
                loading={shippingTableLoading}
                allowClear
                style={{ minWidth: 200 }}
              />
            </Form.Item>
          </Space>
          {canAccess?.personal_modules?.shop?.ecommerce && (
            <CategoryAutoselect
              multiple
              itemProps={{
                initialValue: categoriesWithPath?.map((category) =>
                  Number(category.id)
                ),
                label: translate("product_categories.belongs_to_categories"),
              }}
              name={fieldName("categories")}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProductInfoFields;

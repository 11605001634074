import { useNotification, useTranslate } from "@refinedev/core";
import { Button, Space, Typography } from "antd";
import { useUser } from "../../contexts/ContextProvider";
import { setNestedValue } from "../settings/components/ObjectUtils";
import { usePipelineContext } from "../../contexts/PipelineProvider";

import MailingInfo from "../info/MailingInfo";

const { Title, Paragraph } = Typography;

type Props = {
  title?: string;
  module: string;
  description?: string;
};

// Type for passing props to sub-components
type ModuleComponentProps = {
  onActivate: () => void;
  title?: string;
};

const componentMap: Record<string, React.FC<ModuleComponentProps> | undefined> = {
  mailing: MailingInfo,
};
export default function CanAccessFallback({
                                            title,
                                            module,
                                            description,
                                          }: Props) {
  const translate = useTranslate();
  const { currentTeam, updateTeam, refetchTeams } = useUser();
  const { canAccess, setCanAccess } = usePipelineContext();
  const { open } = useNotification();

  const updateModuleStatus = async (path: string, value: any) => {
    if (!currentTeam) return;

    const [module, ...restPathParts] = path.split(".");
    const nestedPath = restPathParts.join(".");
    const updatedModules = { ...currentTeam.metadata?.module };

    setNestedValue(
      updatedModules,
      `${module}${nestedPath ? `.${nestedPath}` : ""}`,
      value
    );

    try {
      if (currentTeam.account_id) {
        await updateTeam(currentTeam.account_id, {
          public_metadata: {
            ...currentTeam.metadata,
            module: updatedModules,
          },
        });
      }

      setCanAccess(() => ({ ...canAccess, personal_modules: updatedModules }));
      refetchTeams();

      open?.({
        description: translate("notifications.success"),
        message: translate("notifications.editSuccess"),
        type: "success",
      });
    } catch (error) {
      open?.({
        description: translate("notifications.error"),
        message: translate("notifications.editFailed"),
        type: "error",
      });
    }
  };

  const ModuleComponent = componentMap[module];

  if (ModuleComponent) {
    return (
      <ModuleComponent
        onActivate={() => updateModuleStatus(`${module}`, true)}
        title={title}
      />
    );
  }

  return (
    <Space
      direction="vertical"
      style={{ width: "100%", marginTop: 50 }}
      align="center"
    >
      <Title level={3}>
        {translate(title || "settings.module_deactive_title")}
      </Title>

      <Space direction="vertical">
        <Paragraph>
          {translate(description ?? "settings.module_deactive_description")}
        </Paragraph>
      </Space>

      <Button
        type="primary"
        onClick={() => {
          updateModuleStatus(`${module}`, true);
          refetchTeams();
        }}
      >
        {translate("settings.activate")}
      </Button>
    </Space>
  );
}

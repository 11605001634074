import { SettingOutlined } from "@ant-design/icons";
import {
  useCreateMany,
  useList,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { usePipelineContext } from "../../../contexts/PipelineProvider";
import { handleCategoriesUpdate } from "../../../utility/handleCategoriesUpdate";
import CategoryAutoselect from "../CategoryAutoselect";
import TieredPriceInput from "../TieredPriceInput";
import ProductInfoFields from "./ProductInfoFields";

interface VariantInfoTableProps {
  productId: string | number;
  currentTeam: any;
  shippingTableProps: any;
  shippingTableQuery: any;
  mainProductData: any;
  refetchData: () => void;
}

const VariantInfoTable: React.FC<VariantInfoTableProps> = ({
  productId,
  currentTeam,
  shippingTableProps,
  shippingTableQuery,
  mainProductData,
  refetchData,
}) => {
  const translate = useTranslate();
  const { mutateAsync: updateAsync } = useUpdate();
  const { mutateAsync: createMany } = useCreateMany();
  const { canAccess } = usePipelineContext();

  const {
    data: variantsData,
    isLoading: variantsLoading,
    refetch: refetchVariants,
  } = useList({
    resource: "product_items",
    meta: { select: "*, product_categories(*)" },
    filters: [{ field: "parent_product", operator: "eq", value: productId }],
    pagination: { pageSize: 100 },
  });

  const [modifiedVariants, setModifiedVariants] = useState<{
    [key: string]: any;
  }>({});
  const [variantPriceObjects, setVariantPriceObjects] = useState<{
    [key: string]: any;
  }>({});
  const [disabledSave, setDisabledSave] = useState(true);
  const [editingVariant, setEditingVariant] = useState<any>(null);
  const [modalMode, setModalMode] = useState<"single" | "mass" | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editForm] = Form.useForm();
  const [massUpdateForm] = Form.useForm();

  const [enabledFields, setEnabledFields] = useState({
    description: false,
    ean: false,
    netto_price: false,
    sales_tax: false,
    weight: false,
    shipping_table: false,
    price_object: false,
    categories: false,
  });
  const [massUpdatePriceObject, setMassUpdatePriceObject] = useState([
    { price: 0, quantity: 1 },
  ]);

  const removePrefix = (variantId: string, data: any) => {
    const prefix = variantId + "_";
    const result: any = {};
    Object.keys(data).forEach((key) => {
      if (key.startsWith(prefix)) {
        result[key.slice(prefix.length)] = data[key];
      } else {
        result[key] = data[key];
      }
    });
    return result;
  };

  useEffect(() => {
    if (variantsData?.data) {
      const priceObjects = {};
      variantsData.data.forEach((variant: any) => {
        const id = variant.id.toString();
        priceObjects[id] = variant.price_object || [
          { price: variant.netto_price || 0, quantity: 1 },
        ];
      });
      setVariantPriceObjects((prev) =>
        JSON.stringify(prev) !== JSON.stringify(priceObjects)
          ? priceObjects
          : prev
      );
    }
  }, [JSON.stringify(variantsData?.data)]);

  const openEditModal = (variant: any) => {
    setModalMode("single");
    setEditingVariant(variant);
    const variantId = variant.id.toString();
    const existing = modifiedVariants[variantId] || {};
    editForm.setFieldsValue({
      [`${variantId}_description`]:
        existing[`${variantId}_description`] ?? variant.description,
      [`${variantId}_ean`]: existing[`${variantId}_ean`] ?? variant.ean,
      [`${variantId}_netto_price`]:
        existing[`${variantId}_netto_price`] ?? variant.netto_price,
      [`${variantId}_sales_tax`]:
        existing[`${variantId}_sales_tax`] ?? variant.sales_tax,
      [`${variantId}_weight`]:
        existing[`${variantId}_weight`] ?? variant.weight,
      [`${variantId}_shipping_table`]:
        existing[`${variantId}_shipping_table`] ?? variant.shipping_table,
      [`${variantId}_price_object`]:
        existing[`${variantId}_price_object`] ?? variantPriceObjects[variantId],
      [`${variantId}_categories`]:
        existing[`${variantId}_categories`] ??
        (variant.product_categories?.map((cat: any) => cat.id) || []),
    });
    setIsModalVisible(true);
  };

  // Update handler for single variant (without internal Modal.confirm)
  const handleModalSave = async () => {
    try {
      const values = await editForm.validateFields();
      const variantId = editingVariant.id.toString();
      const updateValues = removePrefix(variantId, values);
      if ("categories" in updateValues) {
        handleCategoriesUpdate(
          { categories: updateValues.categories || [] },
          editingVariant.product_categories,
          { id: editingVariant.id },
          currentTeam,
          createMany
        );
        delete updateValues.categories;
      }
      await updateAsync({
        resource: "product_items",
        id: editingVariant.id,
        values: updateValues,
        successNotification: false,
        errorNotification: false,
      });
      message.success(translate("Variant updated successfully"));
      setModifiedVariants((prev) => {
        const newState = { ...prev };
        delete newState[variantId];
        return newState;
      });
      refetchVariants();
      refetchData();
      setIsModalVisible(false);
      setEditingVariant(null);
      setModalMode(null);
      editForm.resetFields();
      massUpdateForm.resetFields();
    } catch (error) {
      console.error("Error processing single update:", error);
      message.error(translate("Error processing changes"));
    }
  };

  // --- MASS UPDATE MODE ---
  const openMassUpdateModal = () => {
    setModalMode("mass");
    massUpdateForm.resetFields();
    setEnabledFields({
      description: false,
      ean: false,
      netto_price: false,
      sales_tax: false,
      weight: false,
      shipping_table: false,
      price_object: false,
      categories: false,
    });
    if (mainProductData) {
      const mainPriceObj = mainProductData.price_object || [
        { price: mainProductData.netto_price || 0, quantity: 1 },
      ];
      setMassUpdatePriceObject(JSON.parse(JSON.stringify(mainPriceObj)));
    }
    setIsModalVisible(true);
  };

  const handleMassUpdateSave = async () => {
    try {
      const massUpdateValues = await massUpdateForm.validateFields();
      const updatePromises: Promise<any>[] = [];
      variantsData?.data.forEach((variant: any) => {
        const updateObj: any = {};
        if (enabledFields.description)
          updateObj.description = massUpdateValues.description;
        if (enabledFields.ean) updateObj.ean = massUpdateValues.ean;
        if (enabledFields.netto_price)
          updateObj.netto_price = massUpdateValues.netto_price;
        if (enabledFields.sales_tax)
          updateObj.sales_tax = massUpdateValues.sales_tax;
        if (enabledFields.weight) updateObj.weight = massUpdateValues.weight;
        if (enabledFields.shipping_table)
          updateObj.shipping_table = massUpdateValues.shipping_table;
        if (enabledFields.price_object)
          updateObj.price_object = massUpdatePriceObject;
        updatePromises.push(
          updateAsync({
            resource: "product_items",
            id: variant.id,
            values: updateObj,
            successNotification: false,
            errorNotification: false,
          })
        );
        if (enabledFields.categories) {
          handleCategoriesUpdate(
            { categories: massUpdateValues.categories || [] },
            variant.product_categories,
            { id: variant.id },
            currentTeam,
            createMany
          );
        }
      });
      if (updatePromises.length === 0) {
        message.info(translate("No changes detected"));
        return;
      }
      await Promise.all(updatePromises);
      message.success(translate("Variants updated successfully"));
      refetchVariants();
      refetchData();
      setIsModalVisible(false);
      setModalMode(null);
    } catch (error) {
      console.error("Error processing mass update:", error);
      message.error(translate("Error processing mass update"));
    }
  };

  // Unified onConfirm handler for the Popconfirm in the footer
  const handleModalOk = async () => {
    if (modalMode === "single") {
      await handleModalSave();
    } else if (modalMode === "mass") {
      await handleMassUpdateSave();
    }
  };
  const handleFormChange = () => {
    const hasErrors = editForm
      .getFieldsError()
      .some(({ errors }) => errors.length);
    const hasErrorsMass = massUpdateForm
      .getFieldsError()
      .some(({ errors }) => errors.length);
    setDisabledSave(hasErrors || hasErrorsMass);
  };
  const renderModalContent = () => {
    if (modalMode === "single" && editingVariant) {
      const variantId = editingVariant.id.toString();
      return (
        <Form
          form={editForm}
          layout="vertical"
          onFieldsChange={handleFormChange}
        >
          <ProductInfoFields
            form={editForm}
            namePrefix={variantId}
            initialPrices={variantPriceObjects[variantId]}
            pricePerItem={editingVariant.netto_price}
            shippingTableProps={shippingTableProps}
            shippingTableLoading={shippingTableQuery.isLoading}
            isVariant={true}
            intialCategories={editingVariant.product_categories}
            hideTitle={true}
            onPricesChange={() => setDisabledSave(false)}
          />
        </Form>
      );
    } else if (modalMode === "mass") {
      return (
        <Form
          form={massUpdateForm}
          layout="vertical"
          onFieldsChange={handleFormChange}
        >
          {/* Your mass update form fields here */}
          <Row>
            {/* Example: Description Field */}
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Checkbox
                    checked={enabledFields.description}
                    onChange={(e) =>
                      setEnabledFields({
                        ...enabledFields,
                        description: e.target.checked,
                      })
                    }
                  />
                </Col>
                <Col flex={1}>
                  <Form.Item
                    name="description"
                    label={translate("product_items.fields.description")}
                  >
                    <Input.TextArea
                      autoSize
                      disabled={!enabledFields.description}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* EAN Field */}
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Checkbox
                    checked={enabledFields.ean}
                    onChange={(e) =>
                      setEnabledFields({
                        ...enabledFields,
                        ean: e.target.checked,
                      })
                    }
                  />
                </Col>
                <Col flex={1}>
                  <Form.Item
                    name="ean"
                    label={translate("product_items.fields.ean", "EAN")}
                  >
                    <Input disabled={!enabledFields.ean} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* TieredPriceInput and Sales Tax */}
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Checkbox
                    checked={enabledFields.price_object}
                    onChange={(e) =>
                      setEnabledFields({
                        ...enabledFields,
                        price_object: e.target.checked,
                      })
                    }
                  />
                </Col>
                <Col flex={1}>
                  <TieredPriceInput
                    onPricesChange={(value: any) => {
                      setDisabledSave(false);
                      setMassUpdatePriceObject(value);
                    }}
                    initialPrices={massUpdatePriceObject}
                    price_per_item={massUpdateForm.getFieldValue("netto_price")}
                    disabled={!enabledFields.price_object}
                  />
                </Col>
                <Col>
                  <Checkbox
                    checked={enabledFields.sales_tax}
                    onChange={(e) =>
                      setEnabledFields({
                        ...enabledFields,
                        sales_tax: e.target.checked,
                      })
                    }
                  />
                </Col>
                <Col flex={1}>
                  <Form.Item
                    name="sales_tax"
                    label={translate("product_items.fields.sales_tax")}
                  >
                    <Select disabled={!enabledFields.sales_tax}>
                      <Select.Option value={19}>19%</Select.Option>
                      <Select.Option value={7}>7%</Select.Option>
                      <Select.Option value={0}>0%</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* Weight and Shipping Table */}
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Checkbox
                    checked={enabledFields.weight}
                    onChange={(e) =>
                      setEnabledFields({
                        ...enabledFields,
                        weight: e.target.checked,
                      })
                    }
                  />
                </Col>
                <Col flex={1}>
                  <Form.Item
                    name="weight"
                    label={translate("product_items.fields.weight")}
                  >
                    <Input disabled={!enabledFields.weight} />
                  </Form.Item>
                </Col>
                <Col>
                  <Checkbox
                    checked={enabledFields.shipping_table}
                    onChange={(e) =>
                      setEnabledFields({
                        ...enabledFields,
                        shipping_table: e.target.checked,
                      })
                    }
                  />
                </Col>
                <Col flex={1}>
                  <Form.Item
                    name="shipping_table"
                    label={translate("product_items.fields.shipping_table")}
                  >
                    <Select
                      {...shippingTableProps}
                      loading={shippingTableQuery.isLoading}
                      allowClear
                      style={{ minWidth: 200 }}
                      disabled={!enabledFields.shipping_table}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {canAccess?.personal_modules?.shop?.ecommerce && (
              <Col span={24}>
                <Row align="middle" gutter={10}>
                  <Col>
                    <Checkbox
                      checked={enabledFields.categories}
                      onChange={(e) =>
                        setEnabledFields({
                          ...enabledFields,
                          categories: e.target.checked,
                        })
                      }
                    />
                  </Col>
                  <Col flex={1}>
                    <CategoryAutoselect
                      multiple
                      itemProps={{
                        label: translate(
                          "product_categories.belongs_to_categories"
                        ),
                      }}
                      selectProps={{
                        disabled: !enabledFields.categories,
                      }}
                      name={"categories"}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Form>
      );
    }
    return null;
  };

  const columns = [
    {
      title: translate("product_items.fields.title", "Variant"),
      dataIndex: "title",
      key: "title",
      render: (text: any) => (
        <Space>
          <Typography.Text>{text || "Variant"}</Typography.Text>
        </Space>
      ),
    },
    {
      title: translate("product_items.fields.price", "Price"),
      dataIndex: "price",
      key: "price",
      render: (_: any, record: any) => {
        const variantId = record.id.toString();
        return (
          <TieredPriceInput
            initialPrices={variantPriceObjects[variantId]}
            price_per_item={record.netto_price}
            disableLabel={true}
            invoiceView={true}
            displayText
          />
        );
      },
    },
    {
      key: "action",
      width: 60,
      render: (_: any, record: any) => (
        <Button
          type="link"
          onClick={() => {
            setModalMode("single");
            openEditModal(record);
          }}
          icon={<SettingOutlined />}
        />
      ),
    },
  ];

  if (variantsLoading) {
    return (
      <div style={{ textAlign: "center", padding: "30px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Table
        dataSource={(variantsData?.data || []).map((variant: any) => ({
          ...variant,
          key: variant.id,
        }))}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            setModalMode("single");
            openEditModal(record);
          },
        })}
        rowClassName={"clickableRow"}
      />
      <Button style={{ marginTop: 20 }} onClick={() => openMassUpdateModal()}>
        {translate("product_items.variants.mass_update", "Mass Update")}
      </Button>
      <Modal
        title={
          modalMode === "mass"
            ? translate("product_items.variants.mass_update", "Mass Update")
            : translate("product_items.variants.edit_title", "Edit Variant")
        }
        maskClosable={false}
        open={isModalVisible}
        onCancel={() => {
          if (!disabledSave) {
            if (confirm(translate("warnWhenUnsavedChanges"))) {
              setIsModalVisible(false);
              setEditingVariant(null);
              setModalMode(null);
              setDisabledSave(true);
            }
          } else {
            setIsModalVisible(false);
            setEditingVariant(null);
            setModalMode(null);
            setDisabledSave(true);
          }
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              if (!disabledSave) {
                if (confirm(translate("warnWhenUnsavedChanges"))) {
                  setIsModalVisible(false);
                  setEditingVariant(null);
                  setModalMode(null);
                  setDisabledSave(true);
                }
              } else {
                setIsModalVisible(false);
                setEditingVariant(null);
                setModalMode(null);
                setDisabledSave(true);
              }
            }}
          >
            {translate("buttons.cancel")}
          </Button>,

          <Button
            onClick={handleModalOk}
            type="primary"
            disabled={disabledSave}
          >
            {translate("buttons.save")}
          </Button>,
        ]}
        destroyOnClose={true}
      >
        {renderModalContent()}
      </Modal>
    </>
  );
};

export default VariantInfoTable;

import { EuroOutlined, ExportOutlined } from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import { useList, useNavigation, useTranslate } from "@refinedev/core";
import { Button, Drawer, MenuProps, Space, Table } from "antd";
import dayjs from "dayjs";
import { PropsWithChildren, useState } from "react";
import { usePaymentsColumns } from "../../../components/columns/payments";
import { TellieTable } from "../../../components/common/TellieTable";
import { useUser } from "../../../contexts/ContextProvider";
import i18n from "../../../i18n";
import { handleExportCSV } from "../../../utility/csvExport";
export default function PaymentsList({ children }: PropsWithChildren) {
  const { currentTeam } = useUser();
  const translate = useTranslate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { tableProps, tableQuery, filters, setFilters } = useTable({
    resource: "payments",
    filters: {
      permanent: [
        { field: "account", operator: "eq", value: currentTeam?.account_id },
      ],
    },
    sorters: {
      initial: [
        {
          field: "date",
          order: "desc",
        },
      ],
    },
    meta: { select: "*, invoice(*)" },
  });
  const { push } = useNavigation();
  const columns = usePaymentsColumns(setFilters);

  const language = i18n.language || "en";

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);
  const { show } = useNavigation();

  const handleInvoiceClick = (invoice: any) => {
    setSelectedInvoice(invoice);
    setIsDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
    setSelectedInvoice(null);
  };

  const breadcrumbItems = [
    {
      icon: <EuroOutlined />,
      title: translate("commerce.payments"),
    },
  ];

  const handleSendInvoice = () => {
    if (!selectedInvoice) return;

    try {
      show("invoices", selectedInvoice.id);
    } catch (error) {
      console.log("Error navigating to invoice:", error);
    }
  };
  const handleRowClick = (event: any, id: any) => {
    if (event.target.className !== "ant-checkbox-input") push("show/" + id);
  };
  const { tableProps: paymentsOfInvoice } = useTable({
    resource: "payments",
    filters: {
      permanent: [
        { field: "account", operator: "eq", value: currentTeam?.account_id },
        { field: "invoice", operator: "eq", value: selectedInvoice?.id },
      ],
    },
    meta: { select: "*, invoice(*)" },
  });

  const { data: paymentsList } = useList({
    resource: "payments",
    pagination: {
      mode: "off",
    },
    filters: [
      {
        field: "account",
        value: currentTeam?.account_id,
        operator: "eq",
      },
    ],
    meta: {
      select: "*,invoice(*)",
    },
  });

  const handleExport = () =>
    handleExportCSV("payments", paymentsList, language, columns);

  const contextMenu: MenuProps["items"] = [
    {
      key: "1",

      label: (
        <a onClick={() => handleExport()}>
          <Space>
            <ExportOutlined />
            {translate("buttons.export_payments")}
          </Space>
        </a>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      <TellieTable
        columns={columns}
        resource="payments"
        tableProps={{
          ...tableProps,
          showSorterTooltip: false,
          rowKey: (record) => record.id ?? "",
          rowClassName: "clickableRow",
          loading: tableQuery?.isFetching,
          tableLayout: "fixed",
          onRow: (record) => ({
            onClick: () => handleInvoiceClick(record.invoice),
          }),
          scroll: { x: "100%" },
          ...(tableQuery?.data?.total && tableQuery.data.total > 100
            ? {
                pagination: {
                  ...tableProps.pagination,
                  showSizeChanger: true,
                  hideOnSinglePage: true,
                },
              }
            : {}),
          rowSelection: rowSelection,
        }}
      />
      <Drawer
        title={translate("settings.invoices.invoice_details")}
        width={700}
        onClose={handleCloseDrawer}
        open={isDrawerVisible}
      >
        {selectedInvoice ? (
          <div>
            <p>
              <strong>
                {translate("settings.invoices.fields.invoice_number")}:
              </strong>{" "}
              {selectedInvoice.invoice_number}
            </p>
            <p>
              <strong>{translate("settings.contact")}:</strong>{" "}
              {selectedInvoice?.contact_name || "No contact provided"}
            </p>
            <p>
              <strong>{translate("settings.invoices.fields.amount")}:</strong>{" "}
              {selectedInvoice.amount
                ? `${Number(selectedInvoice.amount.toFixed(2)).toLocaleString(
                    i18n.language ?? "de",
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  )} €`
                : ""}
            </p>

            <p>
              <strong>
                {translate("settings.invoices.fields.payments_of_this_invoice")}
                :
              </strong>
            </p>
            <Table
              {...paymentsOfInvoice}
              rowKey="id"
              dataSource={paymentsOfInvoice?.dataSource || []}
              pagination={false}
              columns={[
                {
                  title: translate("payments.date"),
                  dataIndex: "date",
                  sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
                  defaultSortOrder: "descend",
                  render: (value) =>
                    value
                      ? dayjs(value).format(
                          translate("page.settings.date_format")
                        )
                      : null,
                },
                {
                  title: translate("payments.amount"),
                  dataIndex: "amount",
                },
              ]}
            />
            <Button
              type="primary"
              onClick={handleSendInvoice}
              style={{ marginTop: 20 }}
            >
              {translate("settings.invoices.fields.go_to_invoice_button")}
            </Button>
          </div>
        ) : (
          <p>{translate("global.loading")}</p>
        )}
      </Drawer>
    </>
  );
}

import dayjs from "dayjs";
import { BillingType } from "../interfaces/phase.interface";
import { Category } from "../interfaces/shop.interface";
import { numberToCurrency } from "./currencyFunctions";

export const formatBillingInterval = (billingInterval, translate) => {
  if (!billingInterval) {
    return "";
  }
  const { interval, interval_count } = billingInterval;
  if (interval_count === 1) {
    return translate(`subscriptions.list.${interval}`);
  } else {
    return `${translate(
      "subscriptions.list.every"
    )} ${interval_count} ${translate(`subscriptions.list.${interval}s`)}`;
  }
};

export const currentPhase = (components) => {
  const phase = !Array.isArray(components)
    ? components
    : (() => {
        const today = new Date();
        return (
          components.find((phase) => {
            const startDate = new Date(phase.start_date);
            const endDate = new Date(phase.end_date);
            return today >= startDate && today <= endDate;
          }) || []
        );
      })();

  if (!phase || !Array.isArray(phase.products)) {
    return phase ? { ...phase, total: "0.00" } : null;
  }

  const total = numberToCurrency(
    phase.products.reduce((sum, product) => sum + calculatePrice(product), 0)
  );

  return {
    ...phase,
    total,
  };
};

export const showProductsJson = (phases, translate) => {
  if (!phases?.products) return [];

  // Helper function to calculate price with tax and discount
  const calculatePrice = (product) => {
    const discountFactor = product.discount ? 1 - product.discount / 100 : 1;
    const netPrice = product.price_per_item * product.quantity * discountFactor;
    const grossPrice = netPrice * (1 + product.tax_rate / 100);
    return Math.round(grossPrice * 100) / 100; // Round to two decimal places
  };

  // Map products to desired format
  return phases.products.map((product, index) => {
    const quantityPrefix = product.quantity > 1 ? `${product.quantity} x ` : "";
    const discountedName = product.discount
      ? `${quantityPrefix}${product.name} (-${product.discount}%)`
      : `${quantityPrefix}${product.name}`;

    return {
      key: `${index + 1}`,
      name: discountedName,
      price: numberToCurrency(calculatePrice(product)),
      billing_interval: formatBillingInterval(
        phases.billing_interval,
        translate
      ),
    };
  });
};

// Helper function to calculate price with tax and discount
const calculatePrice = (product) => {
  const discountFactor = product.discount ? 1 - product.discount / 100 : 1;
  const netPrice = product.price_per_item * product.quantity * discountFactor;
  const grossPrice = netPrice * (1 + product.tax_rate / 100);
  return Math.round(grossPrice * 100) / 100; // Round to two decimal places
};

export function getProductsFrom(phase): {
  total: string | undefined;
  products: any[];
} {
  if (!phase?.products) {
    return { total: undefined, products: [] };
  }

  // Map products to desired format
  return {
    total: numberToCurrency(
      phase.products.reduce((total, product) => {
        return total + calculatePrice(product);
      }, 0)
    ),
    products: phase.products.map((product, index) => {
      const quantityPrefix =
        product.quantity > 1 ? `${product.quantity} x ` : "";
      const discountedName = product.discount
        ? `${quantityPrefix}${product.name} (-${product.discount}%)`
        : `${quantityPrefix}${product.name}`;

      return {
        key: `${index + 1}`,
        name: discountedName,
        price: numberToCurrency(calculatePrice(product)),
      };
    }),
  };
}

export const getNextPhase = (components) => {
  if (!Array.isArray(components) || components?.length === 0) {
    return null;
  }

  const today = new Date();
  const futurePhases = components.filter((phase) => {
    const startDate = new Date(phase.start_date);
    return startDate > today;
  });

  return futurePhases?.length > 0 ? futurePhases[0] : components[0];
};

function parseDate(dateStr: string | Date): Date {
  return new Date(dateStr);
}

// export function calculatePhaseSum(phases: IPhase<BillingType>[]): number {
export function calculatePhaseSum(phases: any[]): number {
  let totalPrice = 0;

  const now = new Date();

  phases?.forEach((phase) => {
    let fromDate: Date;
    let toDate: Date;

    if (typeof phase.duration?.from === "string") {
      fromDate = parseDate(phase.duration.from);
    } else {
      throw Error("From Date is not a date!");
    }

    if (typeof phase.duration.to === "string") {
      toDate = parseDate(phase.duration.to);
    } else {
      throw Error("To Date is not a date!");
    }

    if (fromDate <= now && toDate >= now) {
      const activeDays =
        Math.ceil(
          (now.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24)
        ) + 1;

      // Calculate the total sum for product items based on their billing frequency
      phase.products.forEach((item) => {
        let itemTotal = 0;

        switch (phase.invoice.unit) {
          case BillingType.Day:
            itemTotal = item.netto_price * (item.quantity || 1);
            break;
          case BillingType.Week:
            itemTotal = item.netto_price * (item.quantity || 1);
            break;
          case BillingType.Month:
            itemTotal = item.netto_price * (item.quantity || 1);
            //   Math.floor(activeDays / (item.billing_frequency.value * 30));
            break;
          case BillingType.Year:
            itemTotal =
              item.netto_price *
              (item.quantity || 1) *
              Math.floor(activeDays / (phase.invoice.value * 365));
            break;
          default:
            break;
        }
        // Add itemTotal to totalPrice
        totalPrice += itemTotal;
      });
    }
  });
  return totalPrice;
}

// export function getFirstAndLastDates(data: IPhase[]): { firstDate; lastDate } {
export function getFirstAndLastDates(data: any[]): { firstDate; lastDate } {
  let firstDate: string | Date | null = null;
  let lastDate: string | Date | null = null;

  data?.forEach((entry) => {
    const fromDateString = entry.duration?.from as string;
    const toDateString = entry.duration?.to as string;

    // Convert 'forever' to a large future date

    // Convert to Date objects
    const fromDate = new Date(fromDateString);
    const toDate = new Date(toDateString);

    // Update first and last dates
    if (!firstDate || fromDate < firstDate) {
      firstDate = fromDate;
    }
    if (toDateString == "forever") {
      lastDate = toDateString;
    }
    if (!lastDate || toDate > lastDate) {
      lastDate = toDate;
    }
  });
  return { firstDate, lastDate };
}

export function calculateStartandEndDate({
  unit,
  value,
  obj,
  startDate,
  translate,
}: {
  unit: BillingType;
  value: number;
  obj: any;
  startDate: dayjs.Dayjs;
  translate: (key: string) => string;
}): string {
  if (unit == BillingType.Start) {
    obj = startDate.format(translate("global.date_format"));
  } else if (unit === BillingType.Day) {
    obj = startDate.add(value, "days").format(translate("global.date_format"));
  } else if (unit === BillingType.Week) {
    obj = startDate.add(value, "weeks").format(translate("global.date_format"));
  } else if (unit === BillingType.Month) {
    obj = startDate
      .add(value, "months")
      .format(translate("global.date_format"));
  } else if (unit === BillingType.Year) {
    obj = startDate.add(value, "years").format(translate("global.date_format"));
  } else if (unit === BillingType.OneTime) {
    obj = startDate.format(translate("global.date_format"));
  } else if (unit === BillingType.Month) {
    return BillingType.Month;
  }

  return obj;
}

export function buildCategoryTree(categories: Category[]): Category[] {
  const categoryMap = new Map<number, Category>();

  // Step 1: Initialize a map with all categories
  categories.forEach((category) => {
    category.children = undefined;
    categoryMap.set(category.id, category);
  });

  const rootCategories: Category[] = [];

  // Step 2: Build the tree by assigning children to their parent
  categories.forEach((category) => {
    if (category.parent === null) {
      // If category has no parent, it's a root category
      rootCategories.push(category);
    } else {
      // If category has a parent, find the parent and assign the category as its child
      const parentCategory = categoryMap.get(category.parent);
      if (parentCategory) {
        parentCategory.children = [
          ...(parentCategory.children ?? []),
          category,
        ];
        // parentCategory.children?.push(category);
      } else {
        rootCategories.push(category);
      }
    }
  });

  return rootCategories;
}

export function updateCategoryPathnames(categories: Category[]): Category[] {
  const categoryMap = new Map<number, Category>();

  // Step 1: Initialize the map with all categories and default pathnames
  categories.forEach((category) => {
    category.pathname = category.name ?? ""; // Initialize pathname with the category name
    categoryMap.set(category.id, category);
  });

  // Step 2: Update the pathname for each category based on its parent
  categories.forEach((category) => {
    if (category.parent !== null) {
      // Find the parent and update the pathname accordingly
      const parentCategory = categoryMap.get(category.parent);
      if (parentCategory) {
        category.pathname = `${parentCategory.pathname}/${category.name}`;
      }
    }
  });

  // Return the modified flat list of categories
  return categories;
}

export function hasProductWithShippingTable(phase: any): boolean {
  if (!phase || !Array.isArray(phase.products)) {
    return false;
  }
  return phase.products.some(
    (product) =>
      // Checks if the key exists and has a truthy value
      product.shipping_tables !== undefined &&
      product.shipping_tables !== null &&
      product.shipping_tables !== ""
  );
}

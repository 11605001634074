import { useForm } from "@refinedev/antd";
import { useInvalidate, useNavigation, useTranslate } from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import { useUser } from "../../contexts/ContextProvider";

type Props = {
  open?: any;
  onClose?: () => void;
  goToStages?: boolean;
  goToPipeline?: boolean;
  onSuccess?: () => void;
};
export default function PipelineCreateModal({
  open,
  onClose,
  goToStages,
  goToPipeline,
  onSuccess,
}: Props) {
  const translate = useTranslate();
  const invalidate = useInvalidate();
  const { currentTeam } = useUser();

  const { list, push } = useNavigation();
  const { saveButtonProps, formProps } = useForm({
    action: "create",
    resource: "pipelines",
    mutationMode: "pessimistic",
    invalidates: ["all", "resourceAll"],
    redirect: false,
    onMutationSuccess: (data) => {
      onSuccess?.();
      // Ensure pipeline invalidation
      invalidate({
        resource: "pipelines",
        invalidates: ["all"],
      });

      // Navigate to the correct pipeline
      const newPipelineId = data?.data?.id; // Ensure the ID is fetched correctly
      if (goToPipeline == true) {
        push(`/${currentTeam?.slug}/pipelines/show/${newPipelineId}`);
      } else {
        if (newPipelineId) {
          goToStages
            ? push(
                `/${currentTeam?.slug}/pipelines/show/${newPipelineId}/settings`
              )
            : list("pipelines", "replace");
        } else {
          console.error("Pipeline ID not found in response data");
        }
      }
    },
  });
  return (
    <>
      <Modal
        open={open}
        maskClosable={false}
        title={translate("pipelines.titles.create")}
        onCancel={() => {
          onClose?.();
        }}
        okText={translate("buttons.save")}
        okButtonProps={{
          ...saveButtonProps,
        }}
        width={760}
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={(values) =>
            formProps.onFinish &&
            formProps?.onFinish({
              ...values,
              account: currentTeam?.account_id,
            })
          }
        >
          <Form.Item label={translate("pipelines.name")} name={["name"]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

import {CrudFilter, useList, useOne, useTranslate} from "@refinedev/core";
import { AutoComplete, Button, Form, Spin, Tag } from "antd";
import debounce from "lodash/debounce";
import React, { useEffect, useMemo, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import CreateProductItem from "../../pages/shop/product-items/create";

interface ProductItem {
  configured_variants(configured_variants: any): unknown;
  id: number;
  name: string;
  title: string;
  variant?: any;
  description?: string;
  price_per_item?: number;
  type?: string;
}

interface Option {
  value: string;
  label: React.ReactNode;
  isManual?: boolean;
}

interface ProductItemAutoSelectProps {
  initialValue?: number | ProductItem; // Accept either an ID or a full ProductItem object
  onSelect?: (product: ProductItem | undefined) => void;
  label?: string;
  required?: boolean;
  subscriptionProduct?: boolean;
}

const ProductItemAutoSelect: React.FC<ProductItemAutoSelectProps> = ({
  initialValue,
  onSelect,
  label,
  required = true,
  subscriptionProduct,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);
  const [productMap, setProductMap] = useState<{ [key: string]: ProductItem }>(
    {}
  );
  const [creatingNew, setCreatingNew] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const { currentTeam } = useUser();

  const translate = useTranslate();

  // Determine if initialValue is an ID or a full ProductItem
  const isInitialValueId = typeof initialValue === "number";

  // Fetch the initial product if initialValue is an ID
  const {
    data: initialProductData,
    isLoading: isInitialProductLoading,
    isError: isInitialProductError,
  } = useOne<ProductItem>({
    resource: "product_items",
    id: isInitialValueId ? initialValue : undefined,
    queryOptions: {
      enabled: isInitialValueId, // Only fetch if initialValue is an ID
    },
  });

  // Fetch list of products based on inputValue
  const filters = useMemo(() => {
    const baseFilters: CrudFilter[] = [];

    if (currentTeam?.account_id) {
      baseFilters.push({
        field: "account",
        operator: "eq",
        value: currentTeam.account_id,
      });
    }

    if (inputValue && inputValue.trim()) {
      baseFilters.push({
        operator: "or",
        value: [
          { field: "title", operator: "contains", value: inputValue },
          { field: "ean", operator: "contains", value: inputValue },
        ],
      });
    }
    return baseFilters;
  }, [inputValue, currentTeam?.account_id]);

  const isListEnabled = !!currentTeam?.account_id;

  const { data: productData, isLoading: isProductListLoading } =
    useList<ProductItem>({
      resource: "product_items",
      filters: [
        ...filters,
        {
          operator: "or",
          value: [
            { operator: "null", field: "configured_variants", value: null },
            { operator: "eq", field: "configured_variants", value: "[]" },
            { operator: "eq", field: "configured_variants", value: "[{}]" },
          ],
        },
      ],
      queryOptions: {
        enabled: isListEnabled,
      },
      pagination: {
        pageSize: 40,
      },
    });

  const isLoading =
    (isInitialValueId && isInitialProductLoading) ||
    (isListEnabled && isProductListLoading) ||
    initialLoading;

  useEffect(() => {
    const initialize = () => {
      if (initialValue) {
        if (isInitialValueId) {
          setInitialLoading(true);
          if (initialProductData?.data) {
            const product = initialProductData.data;
            const fullLabel = getProductLabel(product);
            if (inputValue !== fullLabel) {
              setInputValue(fullLabel);
              setOptions([
                {
                  value: `${product.id}`,
                  label: fullLabel,
                  isManual: false,
                },
              ]);
              setProductMap({ [`${product.id}`]: product });
            }
          }
          setInitialLoading(false);
        } else {
          // If initialValue is a ProductItem object
          const product: ProductItem = initialValue as ProductItem;
          const fullLabel = getProductLabel(product);
          if (inputValue !== fullLabel) {
            setInputValue(fullLabel);
            setOptions([
              {
                value: `${product.id}`,
                label: fullLabel,
                isManual: false,
              },
            ]);
            setProductMap({ [`${product.id}`]: product });
          }
        }
      } else {
        // If initialValue is null/undefined
        if (inputValue !== "") {
          setInputValue("");
          setOptions([]);
          setProductMap({});
        }
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, initialProductData, isInitialValueId]);

  // Update options and productMap based on fetched productData and inputValue
  const getProductLabel = (item: ProductItem) => {
    // const variantString =
    //   item.variant && Array.isArray(item.variant)
    //     ? " - " + item.variant.map((v) => v.value).join(", ")
    //     : "";
    return `${item.title}`;
  };
  useEffect(() => {
    let newOptions: Option[] = [];
    let newProductMap: { [key: string]: ProductItem } = {};

    if (productData && productData.data) {
      newOptions = productData.data.map((item) => ({
        value: `${item.id}`,
        label: `${item.title}`,
        isManual: false,
      }));

      newProductMap = productData.data.reduce(
        (map: { [key: string]: ProductItem }, item) => {
          map[`${item.id}`] = item;
          return map;
        },
        {}
      );
    }

    const isManual =
      inputValue &&
      inputValue.trim() &&
      !productData?.data?.some((p) => {
        const fullLabel = getProductLabel(p).toLowerCase();
        return fullLabel === inputValue.toLowerCase();
      });
    if (isManual && !subscriptionProduct) {
      newOptions.push({
        value: `manual-${inputValue}`,
        label: (
          <div>
            {inputValue} <Tag color="blue">Manual</Tag>
          </div>
        ),
        isManual: true,
      });
    }

    setOptions(newOptions);
    setProductMap(newProductMap);
  }, [productData, inputValue]);

  // Debounced search handler
  const debouncedHandleSearch = useMemo(
    () =>
      debounce((searchTerm: string) => {
        setInputValue(searchTerm);
      }, 300),
    []
  );

  const handleSearch = (searchTerm: string) => {
    debouncedHandleSearch(searchTerm);
  };

  const handleSelect = (value: string, option: Option) => {
    if (option.isManual) {
      const manualName = value.replace(/^manual-/, "");
      setInputValue(manualName);
      onSelect?.({
        id: -1,
        name: manualName,
        title: manualName,
      } as ProductItem);
    } else {
      const selectedProduct = productMap[value];
      if (selectedProduct) {
        const fullLabel = getProductLabel(selectedProduct);
        setInputValue(fullLabel);
        onSelect?.(selectedProduct);
      }
    }
  };

  const handleChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const handleCreateNew = (newProduct: ProductItem) => {
    if (newProduct && (newProduct.name || newProduct.title)) {
      const newOption: Option = {
        value: `${newProduct.id}`,
        label: newProduct.title,
        isManual: false,
      };
      setOptions((prev) => [...prev, newOption]);
      setProductMap((prev) => ({
        ...prev,
        [`${newProduct.id}`]: newProduct,
      }));
      setCreatingNew(false);
      setInputValue(newProduct.title);
      if (onSelect) {
        onSelect(newProduct);
      }
    }
  };
  useEffect(() => {
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [debouncedHandleSearch]);
  return (
    <Form.Item label={label} required={required} style={{ marginBottom: 0 }}>
      {creatingNew && (
        <CreateProductItem
          disableRedirect
          changeView={() => setCreatingNew(false)}
          onSuccess={handleCreateNew}
        />
      )}
      <Spin tip="Loading..." spinning={isLoading}>
        <AutoComplete
          value={inputValue}
          options={options}
          onSearch={handleSearch}
          onSelect={handleSelect}
          onChange={handleChange}
          popupMatchSelectWidth={600}
          allowClear
          placeholder={translate('product_items.select_product')}
          notFoundContent={isLoading ? "Loading..." : "No products found"}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Button
                type="link"
                onClick={() => setCreatingNew(true)}
                style={{ width: "100%", textAlign: "left" }}
              >
                + Neues Produkt erstellen
              </Button>
            </>
          )}
        />
      </Spin>
      {/* Handle error state if initial product fails to load */}
      {isInitialProductError && (
        <div style={{ color: "red", marginTop: "8px" }}>
          Failed to load the initial product.
        </div>
      )}
    </Form.Item>
  );
};

export default ProductItemAutoSelect;

import { useModalForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Button, Col, Form, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import useTeam from "../../hooks/useTeam";
import { getContactFormattedName } from "../../utility/contactName";
import AddressAutoselect from "../common/AddressAutoselect";
import ContactAutoSelect from "../contacts/ContactAutoSelect";
import InvoiceTable from "../shop/product-items";

export default function EditInvoice({
  open,
  invoice,
  onClose,
}: {
  open?: boolean;
  invoice: any; // invoice must be provided since we're only editing
  onClose: () => void;
}) {
  const translate = useTranslate();
  const { currentTeam } = useTeam();

  const { formProps, modalProps, form } = useModalForm({
    resource: "invoices",
    action: "edit", // always in edit mode
    id: invoice?.id,
    onMutationSuccess: () => {
      onClose();
    },
    redirect: false,
  });

  const [lineItems, setLineItems] = useState<any>();
  const [total, setTotal] = useState<any>();
  const [shippingTotal, setShippingTotal] = useState<any>();
  const [selectedContact, setSelectedContact] = useState<any>(
    invoice?.contact || null
  );
  const [selectedAddress, setSelectedAddress] = useState<any>(
    invoice?.contact_address || null
  );

  // Default address to ensure the not-null constraint is met
  const defaultAddress = {
    city: "",
    line1: "",
    state: "",
    country: "DE",
    postal_code: "",
  };

  const handleEditInvoice = async () => {
    await form?.validateFields();
    // Use the selected address if available, otherwise fallback to defaultAddress
    const addressToSave = selectedAddress || defaultAddress;
    formProps.onFinish?.({
      line_items: lineItems,
      amount: total?.total,
      amount_paid: 0,
      amount_shipping: 0,
      account: currentTeam?.account_id,
      contact_email: selectedContact?.email,
      contact_name: getContactFormattedName(selectedContact),
      date: new Date(),
      contact: selectedContact?.id,
      contact_address: addressToSave,
      shipping: shippingTotal,
    });
  };

  // Set initial form values when the invoice is loaded.
  useEffect(() => {
    if (invoice) {
      // Reset and set values in the form
      form.resetFields();
      form.setFieldsValue({
        contact:
          invoice?.contact && typeof invoice.contact === "object"
            ? invoice.contact.id
            : invoice?.contact,
        contact_address: invoice.contact_address.id
          ? String(invoice.contact_address.id ?? "")
          : undefined,
        amount: invoice.amount,
      });
      setSelectedAddress(invoice.contact_address || null);
      setSelectedContact(invoice.contact || null);
    }
  }, [invoice, form]);

  // Optionally, if you want to set the initialValues on the Form component:
  const initialFormValues = {
    contact:
      invoice?.contact && typeof invoice.contact === "object"
        ? invoice.contact.id
        : invoice?.contact,
    contact_address: invoice?.contact_address?.id
      ? String(invoice?.contact_address?.id || "")
      : undefined,
    amount: invoice?.amount,
  };
  console.log("selectedContact", selectedContact);
  return (
    <Modal
      {...modalProps}
      open={open}
      onCancel={onClose}
      width={1050}
      title={translate("settings.invoices.edit_invoice", "Edit Invoice")}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {translate("buttons.cancel", "Cancel")}
        </Button>,
        <Button key="save" onClick={handleEditInvoice} type="primary">
          {translate("buttons.save", "Save")}
        </Button>,
      ]}
    >
      <Form {...formProps} layout="vertical" initialValues={initialFormValues}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="contact"
              initialValue={
                invoice?.contact && typeof invoice.contact === "object"
                  ? invoice.contact.id
                  : invoice?.contact
              }
            >
              <ContactAutoSelect
                label={translate("settings.invoices.fields.enter_customer")}
                required={true}
                name="contact"
                onSelect={(val) => console.log("val", val)}
                getContact={(value) => {
                  console.log("value", value);
                  setSelectedContact(value);
                  form.setFieldValue("contact_address", null);
                }}
              />
            </Form.Item>
            {selectedContact && (
              <AddressAutoselect
                contactId={selectedContact?.id}
                name="contact_address"
                label={translate("contacts.address")}
                required={false}
                getAddress={(value) => {
                  if (!value) {
                    setSelectedAddress(null);
                    return;
                  }
                  const { contacts, ...rest } = value;
                  setSelectedAddress(rest || null);
                }}
              />
            )}
          </Col>
          <InvoiceTable
            initialValues={invoice?.line_items || []}
            onProductsChange={(value) => setLineItems(value)}
            onTotalChange={(value) => setTotal(value)}
            onShippingCost={(value) => setShippingTotal(value)}
            initialShipping={invoice?.shipping}
          />
        </Row>
      </Form>
    </Modal>
  );
}
